import React from 'react'
import PropTypes from 'prop-types'

import Empty from '../component/empty/empty'

const Conditionnal = (conditionName, Component, OrComponent) => {
  function ConditionnalElement({ [conditionName]: condition, children, value, ...props }) {
    const Element = condition ? Component : OrComponent

    return (
      <Element {...props}>{children ?? value}</Element>
    )
  }

  ConditionnalElement.propTypes = {
    [conditionName]: PropTypes.bool.isRequired,
    children: PropTypes.node,
    value: PropTypes.node
  }

  return ConditionnalElement
}

export const Either = (conditionName, Component, OrComponent) => Conditionnal(conditionName, Component, OrComponent)
export const Option = Component => Conditionnal('condition', Component, Empty)
