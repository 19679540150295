import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

export const BaseTitle = ({ labelId }) => <Typography variant='h1'><Translate id={labelId} /></Typography>

BaseTitle.propTypes = {
  labelId: PropTypes.string.isRequired
}

const Title = ({ mb, labelId, children }) => (
  <Box mb={mb} display='flex' alignItems='baseline'>
    <BaseTitle labelId={labelId} />
    <Box ml={2}>
      {children}
    </Box>
  </Box>
)

Title.propTypes = {
  mb: PropTypes.number,
  labelId: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default Title
