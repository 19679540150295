import { withStyles, Box } from '@material-ui/core'

export const Subtitle1 = withStyles(theme => ({ root: theme.typography.subtitle1 }))(Box)
export const Subtitle2 = withStyles(theme => ({ root: theme.typography.subtitle2 }))(Box)
export const SubTitle3 = withStyles(theme => ({ root: theme.typography.subtitle3 }))(Box)
export const SubTitle4 = withStyles(theme => ({ root: theme.typography.subtitle4 }))(Box)

export const SubTitle3Alert = withStyles(theme => ({
  root: {
    color: '#D8292F',
    fontSize: theme.typography.subtitle3.fontSize,
    fontStyle: theme.typography.subtitle3.fontStyle
  }
}))(Box)
