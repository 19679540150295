import React from 'react'
import PropTypes from 'prop-types'

import { SingleSelect, newSelectItemByKey } from '../../../component/Select'

import { connect } from 'react-redux'
import store from '../../../store'
import { modifyUser } from '../../../store/actions/newUser'
import { newUserSelector } from '../../../store/selectors'

import { isEmpty } from '../../../businessRules/string'

const setUser = data => store.dispatch(modifyUser(data))

const mapStateToProps = state => {
  const { newUsers } = newUserSelector(state)
  return ({ newUsers: newUsers.map(newSelectItemByKey) })
}

const Email = ({ newUsers, email, isSubmitClicked }) =>
  <SingleSelect
    fullWidth
    error={isSubmitClicked && isEmpty(email)}
    elements={newUsers}
    required
    titleId='selectorTitle'
    labelId='view.users.email'
    updateValue={email => setUser({ email })}
    value={email}
  />

Email.propTypes = {
  newUsers: PropTypes.array.isRequired,
  email: PropTypes.string.isRequired,
  isSubmitClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Email)
