import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { newUserSelector } from '../../store/selectors'

import DataType from '../../component/table/tableBody/datatype'
import Regions from './components/regions'
import Role from './components/role'
import Access from './components/access'

const regionsComponent = row => <Regions regions={row.regions} />
const roleComponent = row => <Role role={row.user_role} />
const accessComponent = row => <Access role={row.role} />
const other = column => row => <DataType column={column} row={row} />

const Edits = {
  formattedRegions: regionsComponent,
  formattedRole: roleComponent,
  formattedAccess: accessComponent
}

const getColumn = column => Edits[column.id] ?? other(column)

const mapStateToProps = state => ({ user: newUserSelector(state).user })

const EditRow = ({ user, column, row }) =>
  row.email === user.email ? getColumn(column)(user) : other(column)(row)

EditRow.propTypes = {
  user: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(EditRow)
