import React from 'react'
import PropTypes from 'prop-types'

import { Translate } from 'react-localize-redux'
import TextButton from '../buttons/textButton'
import { withStyles } from '@material-ui/core'

const CustomTextButton = withStyles(_theme => ({ root: { minWidth: 'initial' }, label: { fill: 'currentColor' } }))(TextButton)

const ButtonLink = ({ labelId, onClick, endIcon, disabled }) => (
  <CustomTextButton size='small' onClick={onClick} endIcon={endIcon} disabled={disabled}>
    <Translate id={labelId} />
  </CustomTextButton>
)

ButtonLink.propTypes = {
  labelId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  endIcon: PropTypes.element,
  disabled: PropTypes.bool
}

export default ButtonLink
