import { demandeForecastSelector } from '../../selectors'

export const newFilter = (id, value) => ({ id, value })

export const UpdateSelectors = 'UpdateSelectors'
export const updateSelectors = selectors => ({ type: UpdateSelectors, selectors })

export const ApplySelectorToFilter = 'applySelectorToFilter'
export const applySelectorToFilter = () => ({ type: ApplySelectorToFilter })

export const UpdateFilterValue = 'updateFilterValue'
export const updateFilterValue = newFilterValue => ({ type: UpdateFilterValue, newFilterValue })

export const StartNewFilterValue = 'startNewFilterValue'
export const startNewFilterValue = newFilterValue => ({ type: StartNewFilterValue, newFilterValue })

export const startNewFilterValues = () => (dispatch, getState) => {
  const { allFilterValues } = demandeForecastSelector(getState())
  return dispatch(startNewFilterValue(allFilterValues.map(filter => newFilter(filter.key, filter.elements))))
}

export const UndoFilter = 'undoFilter'
export const undoFilter = () => ({ type: UndoFilter })

export const RedoFilter = 'redoFilter'
export const redoFilter = () => ({ type: RedoFilter })
