import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const defaultStyle = theme => ({
  root: {
    borderRadius: 0,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: 'fit-content'
  },
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
})

const paddingStyle = (t, r, b, l) => (theme, buttonStyle) => {
  buttonStyle.label = {
    paddingTop: t ? theme.spacing(t) : undefined,
    paddingRight: r ? theme.spacing(r) : undefined,
    paddingBottom: b ? theme.spacing(b) : undefined,
    paddingLeft: l ? theme.spacing(l) : undefined
  }
}

const minWidthStyle = minWidth => (_theme, buttonStyle) => {
  buttonStyle.root.minWidth = minWidth
}

const fullHeightStyle = (_theme, buttonStyle) => {
  buttonStyle.root.height = '100%'
}

const colored = colors => (theme, buttonStyle) => {
  const { color, hoverColor } = colors(theme)

  buttonStyle.root.backgroundColor = theme.palette.background.paper
  buttonStyle.root.color = color
  buttonStyle.root['&:hover'] = { color: hoverColor }
  buttonStyle.contained['&:hover'].backgroundColor = theme.palette.background.paper
}

const combine = styles => theme => {
  const buttonStyle = defaultStyle(theme)

  styles.map(style => style(theme, buttonStyle))

  return buttonStyle
}

export default withStyles(defaultStyle)(Button)
export const createColorFullHeightDefaultButton = colors => withStyles(combine([fullHeightStyle, colored(colors)]))(Button)
export const FullHeightDefaultButton = withStyles(combine([fullHeightStyle]))(Button)
export const createNewFullPaddedButton = ({ t, r, b, l }, minWidth) =>
  withStyles(combine([
    fullHeightStyle,
    paddingStyle(t, r, b, l),
    minWidthStyle(minWidth)
  ]))(Button)
