import {
  ChangeOrder, ChangeOrderBy, ChangeColumnHidden, ChangeRowsPerPage, ChangeTableColumnOrder,
  optimizations, pendingInfluences, main, commercialInfluences, users, ChangePage, ChangeOrdering
} from '../../actions/tableState/tableState'

import { columns, defaultOrder, defaultOrderBy, defaultRowsPerPage } from '../../../views/forecasting/review/settingsForecasting'
import { columns as columnsPendingInfluences } from '../../../views/forecasting/influence/settingPendingInfluence'
import { columns as columnsOptimizations } from '../../../views/forecasting/optimization/settingOptimization'
import { columns as columnsCommercialInfluences } from '../../../views/commercialInfluence/settingCommercialInfluence'
import { columns as columnsUsers } from '../../../views/users/tableSettings'

import { orderArray, empty, existIn } from '../../../businessRules/array'

const columnsSetting = {
  main: columns,
  pendingInfluences: columnsPendingInfluences,
  optimizations: columnsOptimizations,
  commercialInfluences: columnsCommercialInfluences,
  users: columnsUsers
}

const getColumnsHidden = view => (name, settingMapping) => {
  const localStorageData = localStorage.getItem(name)

  return localStorageData ? localStorageData.split(',') : [...columnsSetting[view].map(settingMapping)]
}

const colSpan = columns => (columns ?? []).length + 1

const getColumnsOrdered = view => (tableColumnOrder, columnHidden) =>
  tableColumnOrder.map(t => columnsSetting[view].find(c => c.order === t)).filter(empty).filter(column => existIn(columnHidden)(column.id))

const tableInitialState = view => {
  const columnHidden = getColumnsHidden(view)(`${view}.columnHidden`, c => c.id)
  const tableColumnOrder = getColumnsHidden(view)(`${view}.tableColumnOrder`, c => c.order).map(v => parseInt(v))

  return {
    order: localStorage.getItem(`${view}.order`) ?? defaultOrder,
    orderBy: localStorage.getItem(`${view}.orderBy`) ?? defaultOrderBy,
    selected: [],
    columnHidden,
    colSpan: colSpan(columnHidden),
    page: 0,
    rowsPerPage: parseInt(localStorage.getItem(`${view}.rowsPerPage`) ?? defaultRowsPerPage),
    tableColumnOrder: tableColumnOrder,
    columnsOrdered: getColumnsOrdered(view)(tableColumnOrder, columnHidden)
  }
}

const tableState = (state = tableInitialState, action, view) => {
  switch (action.type) {
    case ChangeOrder:
      localStorage.setItem(`${view}.order`, action.data)
      return Object.assign({}, state, { order: action.data })
    case ChangeOrderBy:
      localStorage.setItem(`${view}.orderBy`, action.data)
      return Object.assign({}, state, { orderBy: action.data })
    case ChangeOrdering: {
      const { order, orderBy } = action
      localStorage.setItem(`${view}.order`, order)
      localStorage.setItem(`${view}.orderBy`, orderBy)
      return Object.assign({}, state, { order, orderBy })
    }
    case ChangeColumnHidden: {
      const columnHidden = action.data
      localStorage.setItem(`${view}.columnHidden`, columnHidden)

      return Object.assign({}, state, {
        columnHidden: columnHidden,
        colSpan: colSpan(columnHidden) + 1,
        columnsOrdered: getColumnsOrdered(view)(state.tableColumnOrder, columnHidden)
      })
    }
    case ChangePage:
      return Object.assign({}, state, { page: action.page })
    case ChangeRowsPerPage:
      localStorage.setItem(`${view}.rowsPerPage`, action.data)
      return Object.assign({}, state, { rowsPerPage: action.data })
    case ChangeTableColumnOrder: {
      const newtableColumnOrder = orderArray(state.tableColumnOrder)(action.sourceIndex, action.destinationIndex, state.columnsOrdered)
      localStorage.setItem(`${view}.tableColumnOrder`, newtableColumnOrder)
      return Object.assign({}, state, {
        tableColumnOrder: newtableColumnOrder,
        columnsOrdered: getColumnsOrdered(view)(newtableColumnOrder, state.columnHidden)
      })
    }
    default:
      return state
  }
}

const initialState = {
  main: tableInitialState(main),
  pendingInfluences: tableInitialState(pendingInfluences),
  optimizations: tableInitialState(optimizations),
  commercialInfluences: tableInitialState(commercialInfluences),
  users: tableInitialState(users)
}

export default (state = initialState, action) => {
  const { view } = action

  switch (view) {
    case main:
    case pendingInfluences:
    case optimizations:
    case commercialInfluences:
    case users:
      return Object.assign({}, state, {
        [view]: tableState(state[view], action, view)
      })
    default:
      return state
  }
}
