import React from 'react'
import PropTypes from 'prop-types'

import { TableRow } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import CustomTableCell from '../customTableCell'
import { Translate } from 'react-localize-redux'

const TableRowData = ({ children, colSpan }) => (
  <TableRow>
    <CustomTableCell colSpan={colSpan}>
      {children}
    </CustomTableCell>
  </TableRow>
)

TableRowData.propTypes = {
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number.isRequired
}

export default TableRowData

export const DataEmpty = ({ colSpan }) => <TableRowData colSpan={colSpan}><Translate id='table.message.noData' /></TableRowData>
DataEmpty.propTypes = { colSpan: PropTypes.number.isRequired }

export const DataInvalid = ({ colSpan }) => <TableRowData colSpan={colSpan}><Translate id='table.message.error' /></TableRowData>
DataInvalid.propTypes = { colSpan: PropTypes.number.isRequired }

export const DataLoading = ({ colSpan }) => <TableRowData colSpan={colSpan}><LinearProgress color='primary' /></TableRowData>
DataLoading.propTypes = { colSpan: PropTypes.number.isRequired }
