import React from 'react'
import PropTypes from 'prop-types'

import { TableSortLabel, Box } from '@material-ui/core'

import DraggableComponent from '../DraggableComponent/DraggableComponent'
import { Close } from '@material-ui/icons'

import { StyledButton } from '../buttons/tableHeaderButton'
import CustomTableCell from './customTableCell'

import { Translate } from 'react-localize-redux'

function TableHeadCell({ index, column, order, orderBy, changeOrdering, deleteColumn }) {
  const createSortHandler = property => _event => {
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    const newOrderBy = property

    changeOrdering(newOrder, newOrderBy)
  }

  const onClickDelete = (e, id) => {
    deleteColumn(id)
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  return (
    <CustomTableCell
      component={DraggableComponent(column.id, index)}
      align='left'
      sortDirection={orderBy === column.id ? order : false}
    >
      <TableSortLabel
        active={orderBy === column.id}
        direction={order}
        onClick={createSortHandler(column.id)}
      >
        <Box component='span' fontWeight='fontWeightBold'>{column.labelId ? <Translate id={column.labelId} /> : column.label}</Box>
        <StyledButton className='MuiTableSortLabel-icon' onClick={e => onClickDelete(e, column.id)}>
          <Close fontSize='small' />
        </StyledButton>
      </TableSortLabel>
    </CustomTableCell>
  )
}

TableHeadCell.propTypes = {
  index: PropTypes.number.isRequired,
  column: PropTypes.object.isRequired,
  order: PropTypes.oneOf(['asc', 'desc', '']),
  orderBy: PropTypes.string,
  changeOrdering: PropTypes.func.isRequired,
  deleteColumn: PropTypes.func.isRequired
}

export default TableHeadCell
