import React from 'react'
import PropTypes from 'prop-types'

import { NumberOfDayForRedAlert } from '../appSetting'

import { connect } from 'react-redux'
import { postdateSelector } from '../store/selectors'

import { Box } from '@material-ui/core'
import Empty from '../component/empty/empty'
import { SubTitle3, SubTitle3Alert } from '../component/title/subtitle'
import { formatDate, utcToMoment, dateToMoment } from '../businessRules/date'

import { Translate } from 'react-localize-redux'

const mapStateToProps = state => ({ postdate: postdateSelector(state).postdate })

const PostDateSubTitle = ({ postdate }) => {
  const postdateMoment = utcToMoment(postdate)
  const formattedPostdate = postdate ? formatDate(postdateMoment) : '?'
  const today = dateToMoment(new Date())

  const diff = today.diff(postdateMoment, 'days')

  const DelayComponent = diff > NumberOfDayForRedAlert ? SubTitle3Alert : SubTitle3

  return (
    <Box display='flex'>
      <SubTitle3><Translate id='postdate.postdateTag' />&nbsp;{formattedPostdate}&nbsp;&nbsp;&nbsp;</SubTitle3>
      {diff <= 1 ? <Empty /> : <DelayComponent>{diff} <Translate id='postdate.dayBefore' /></DelayComponent>}
    </Box>
  )
}

PostDateSubTitle.propTypes = { postdate: PropTypes.number.isRequired }

export default connect(mapStateToProps)(PostDateSubTitle)
