import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { commercialInfluenceDone, modifyCommercialInfluence } from '../../store/actions/newCommercialInfluence'
import { userSettingsSelector, configurationSelector, newCommercialInfluenceSelector } from '../../store/selectors'

import _ from 'lodash'
import { Grid, withStyles, Divider, Box, Fade } from '@material-ui/core'
import MiddleContainer from './middleContainer'
import { StringInput, NumberInput, DateRange } from '../../component/input'
import { createNewFullPaddedButton } from '../../component/buttons/defaultButton'
import { SingleSelect, MultiSelect, newSelectItemByKey } from '../../component/Select'
import CustomSubtitle from '../../component/title/customSubtitle'
import ArrowUpDown from '../../component/ArrowUpDown'

import { joinList, joinListWithFormat } from '../../businessRules/array'
import { formatToD, formatDoW } from '../../businessRules/date'

import { Translate } from 'react-localize-redux'
import { newCommercialInfluenceSaga } from '../../store/saga/newCommercialInfluence'
import { toSeatClass } from '../../businessRules/object'
import { isEmpty } from '../../businessRules/string'
import { DefaultInfluence } from '../../appSetting'

const PaddedButton = createNewFullPaddedButton({ r: 4, l: 4 })
const DoublePaddedButton = createNewFullPaddedButton({ r: 8, l: 8 })

const FlexItemGrid = withStyles(_theme => ({ root: { display: 'flex' } }))(Grid)

const mapStateToProps = state => {
  const { newInfluence: influence, defaultCommercialInfluence, nextYearRange } = newCommercialInfluenceSelector(state)
  const { ODs } = userSettingsSelector(state)
  const { pathTypesSelectItem, dayOfWeekSelectItem, timeOfDaySelectItem, cabinClassGroup, classGroupSelectItem, decaysSelectItem } = configurationSelector(state)
  return ({ influence, defaultCommercialInfluence, nextYearRange, ODs, pathTypesSelectItem, dayOfWeekSelectItem, timeOfDaySelectItem, cabinClassGroup, classGroupSelectItem, decaysSelectItem })
}

const spacing = 2
const min = 0.01
const step = min

function AddSection(props) {
  const {
    influence,
    defaultCommercialInfluence,
    nextYearRange,
    ODs,
    pathTypesSelectItem,
    dayOfWeekSelectItem,
    timeOfDaySelectItem,
    cabinClassGroup: allCabinClassGroup,
    classGroupSelectItem: classGroupSelectItems,
    decaysSelectItem
  } = props

  const [isSubmitClicked, setSubmitClicked] = React.useState(false)
  const [isOptionnalOpen, setOptionnalState] = React.useState(false)

  const removeDecayRange = ({ decay, decayRange, ...influence }) => ({ decay, decayRange: decay === 'None' ? undefined : decayRange, ...influence })
  const cancel = () => store.dispatch(commercialInfluenceDone())
  const add = () => {
    setSubmitClicked(true)
    if (!(isEmpty(influence.origin) || isEmpty(influence.destination) || isEmpty(influence.pos))) {
      store.dispatch(newCommercialInfluenceSaga(removeDecayRange(influence)))
      setSubmitClicked(false)
    }
  }
  const setCommercialInfluence = data => store.dispatch(modifyCommercialInfluence(data))

  const byIncludedCabinIn = cabins => ({ cabin }) => cabins.includes(cabin)
  const byIncludedCabinIn2 = cabins => cabin => cabins.includes(cabin)

  const filterAllCabinClassGroupBy = cabins => allCabinClassGroup.filter(byIncludedCabinIn(cabins))

  const bySelectedCabin = filteredCabinClassGroup => value =>
    filteredCabinClassGroup.length === 0 ? allCabinClassGroup.map(toSeatClass) : filteredCabinClassGroup.map(toSeatClass).includes(value)

  const filterClassGroupSelectItem = cabins => classGroupSelectItems.filter(({ value }) => bySelectedCabin(filterAllCabinClassGroupBy(cabins))(value))
  const filterDefaultClassGroup = cabins => defaultCommercialInfluence.seatClass.filter(bySelectedCabin(filterAllCabinClassGroupBy(cabins)))

  const newDateRange = ({ startDate }, { endDate }) => ({ startDate, endDate })

  const cabins = _.chain(ODs).filter(OD => OD.origin === influence.origin && OD.destination === influence.destination && OD.POS === influence.pos).map(OD => OD.cabin).uniq().value()

  return (
    <>
      <Grid container spacing={spacing}>
        <FlexItemGrid item xs={6} lg={4}>
          <CustomSubtitle labelId='view.commercialInfluence.requiredParameter' />
        </FlexItemGrid>
        <FlexItemGrid item xs={3} lg={4}>
          <MiddleContainer>
            <CustomSubtitle labelId='view.commercialInfluence.duration' />
          </MiddleContainer>
        </FlexItemGrid>
        <FlexItemGrid item xs={3} lg={4}>
          <CustomSubtitle labelId='view.commercialInfluence.optional' onClick={() => setOptionnalState(!isOptionnalOpen)}>
            <ArrowUpDown open={isOptionnalOpen} />
          </CustomSubtitle>
        </FlexItemGrid>
      </Grid>
      <Grid container spacing={spacing}>
        <Grid item xs={3} lg={2}>
          <SingleSelect
            fullWidth
            error={isSubmitClicked && isEmpty(influence.origin)}
            elements={_.chain(ODs).map(OD => OD.origin).uniq().sortBy().map(newSelectItemByKey).value()}
            required
            titleId='selectorTitle'
            labelId='view.commercialInfluence.origin'
            updateValue={origin => setCommercialInfluence({ origin, destination: undefined, pos: undefined })}
            value={influence.origin}
          />
        </Grid>
        <Grid item xs={3} lg={2}>
          <SingleSelect
            fullWidth
            error={isSubmitClicked && isEmpty(influence.destination)}
            elements={_.chain(ODs).filter(OD => OD.origin === influence.origin).map(OD => OD.destination).uniq().sortBy().map(newSelectItemByKey).value()}
            required
            titleId='selectorTitle'
            labelId='view.commercialInfluence.destination'
            updateValue={destination => setCommercialInfluence({ destination, pos: undefined })}
            value={influence.destination}
          />
        </Grid>
        <Grid item xs={3} lg={4}>
          <MiddleContainer>
            <DateRange
              labelId='view.commercialInfluence.effectiveDateRange'
              titleId='selectorTitle'
              numberOfMonths={2}
              changeDateRange={effectiveDateRange => setCommercialInfluence({ effectiveDateRange, decayRange: effectiveDateRange })}
              limitDateRange={newDateRange(nextYearRange, influence.flightDateRange)}
              dateRangeValue={influence.effectiveDateRange}
            />
          </MiddleContainer>
        </Grid>
        <Grid item xs={3} lg={2}>
          <Fade in={isOptionnalOpen}>
            <div>
              <MultiSelect
                required
                fullWidth
                elements={dayOfWeekSelectItem}
                titleId='selectorTitle'
                labelId='view.commercialInfluence.dow'
                updateValues={dow => setCommercialInfluence({ dow })}
                values={influence.dow}
                renderValue={joinListWithFormat(formatDoW)}
              />
            </div>
          </Fade>
        </Grid>
      </Grid>
      <Grid container spacing={spacing}>
        <Grid item xs={3} lg={2}>
          <SingleSelect
            fullWidth
            error={isSubmitClicked && isEmpty(influence.pos)}
            elements={_.chain(ODs).filter(OD => OD.origin === influence.origin && OD.destination === influence.destination).map(OD => OD.POS).uniq().map(newSelectItemByKey).value()}
            required
            titleId='selectorTitle'
            labelId='view.commercialInfluence.pos'
            updateValue={pos => setCommercialInfluence({ pos })}
            value={influence.pos}
          />
        </Grid>
        <Grid item xs={3} lg={2}>
          <DateRange
            labelId='view.commercialInfluence.flightDateRange'
            titleId='selectorTitle'
            numberOfMonths={2}
            changeDateRange={flightDateRange => setCommercialInfluence({
              flightDateRange,
              effectiveDateRange: newDateRange(nextYearRange, flightDateRange),
              decayRange: newDateRange(nextYearRange, flightDateRange)
            })}
            limitDateRange={nextYearRange}
            dateRangeValue={influence.flightDateRange}
          />
        </Grid>
        <Grid item xs={3} lg={4}>
          <MiddleContainer>
            <SingleSelect
              fullWidth
              elements={decaysSelectItem}
              titleId='selectorTitle'
              labelId='view.commercialInfluence.decay'
              updateValue={decay => setCommercialInfluence({ decay })}
              value={influence.decay}
            />
          </MiddleContainer>
        </Grid>
        <Grid item xs={3} lg={2}>
          <Fade in={isOptionnalOpen}>
            <div>
              <MultiSelect
                required
                fullWidth
                elements={timeOfDaySelectItem}
                titleId='selectorTitle'
                labelId='view.commercialInfluence.tod'
                updateValues={tod => setCommercialInfluence({ tod })}
                values={influence.tod}
                renderValue={joinListWithFormat(formatToD)}
              />
            </div>
          </Fade>
        </Grid>
      </Grid>
      <Grid container spacing={spacing}>
        <Grid item xs={3} lg={2}>
          <MultiSelect
            required
            fullWidth
            elements={cabins.filter(byIncludedCabinIn2(defaultCommercialInfluence.cabin)).map(newSelectItemByKey)}
            titleId='selectorTitle'
            labelId='view.commercialInfluence.cabin'
            updateValues={cabin => setCommercialInfluence({ cabin, seatClass: filterDefaultClassGroup(cabin) })}
            values={influence.cabin}
            renderValue={joinList}
          />
        </Grid>
        <Grid item xs={3} lg={2}>
          <MultiSelect
            required
            fullWidth
            elements={filterClassGroupSelectItem(influence.cabin)}
            titleId='selectorTitle'
            labelId='view.commercialInfluence.classes'
            updateValues={seatClass => setCommercialInfluence({ seatClass })}
            values={influence.seatClass}
            renderValue={joinList}
          />
        </Grid>
        <Grid item xs={3} lg={4}>
          <MiddleContainer>
            <Fade in={influence.decay !== 'None'}>
              <div>
                <DateRange
                  labelId='view.commercialInfluence.decayRange'
                  titleId='selectorTitle'
                  numberOfMonths={2}
                  changeDateRange={decayRange => setCommercialInfluence({ decayRange })}
                  limitDateRange={influence.effectiveDateRange}
                  dateRangeValue={influence.decayRange}
                />
              </div>
            </Fade>
          </MiddleContainer>
        </Grid>
        <Grid item xs={3} lg={2}>
          <Fade in={isOptionnalOpen}>
            <div>
              <MultiSelect
                required
                fullWidth
                elements={pathTypesSelectItem}
                titleId='selectorTitle'
                labelId='view.commercialInfluence.pathGroup'
                updateValues={pathType => setCommercialInfluence({ pathType })}
                values={influence.pathType}
                renderValue={joinList}
              />
            </div>
          </Fade>
        </Grid>
      </Grid>
      <Box mt={3} mb={3}><Divider /></Box>
      <Grid container spacing={spacing}>
        <Grid item xs={3} lg={2}>
          <NumberInput
            labelId='view.commercialInfluence.influence'
            value={influence.influence}
            onChange={value => setCommercialInfluence({ influence: parseFloat(value) })}
            defaultValue={DefaultInfluence}
            step={step}
            min={min}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <StringInput
            labelId='view.commercialInfluence.description'
            value={influence.description}
            onChange={description => setCommercialInfluence({ description })}
            defaultValue=''
          />
        </Grid>
        <FlexItemGrid xs={3} item lg={4}>
          <Box mr={1}>
            <DoublePaddedButton variant='contained' color='primary' onClick={add}>
              <Translate id='view.commercialInfluence.apply' />
            </DoublePaddedButton>
          </Box>
          <PaddedButton variant='contained' onClick={cancel}>
            <Translate id='view.commercialInfluence.cancel' />
          </PaddedButton>
        </FlexItemGrid>
      </Grid>
    </>
  )
}

AddSection.propTypes = {
  influence: PropTypes.object.isRequired,
  defaultCommercialInfluence: PropTypes.object.isRequired,
  nextYearRange: PropTypes.object.isRequired,
  ODs: PropTypes.array.isRequired,
  pathTypesSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  dayOfWeekSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeOfDaySelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  cabinClassGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  classGroupSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  decaysSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default connect(mapStateToProps)(AddSection)
