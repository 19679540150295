import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Table } from '@material-ui/core'

const style = theme => ({
  root: {
    marginTop: theme.spacing(5)
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

class EnhancedTable extends Component {
  render() {
    const { children, tableRef, classes } = this.props

    return (
      <div className={classes.tableWrapper}>
        <Table ref={tableRef} tabIndex='0' aria-labelledby='tableTitle' size='small'>
          {children}
        </Table>
      </div>
    )
  }
}

EnhancedTable.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  tableRef: PropTypes.object,
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(EnhancedTable)
