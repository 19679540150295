import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'

const style = _theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

const StyledCircularProgress = withStyles(style)(CircularProgress)

export const LoadingButton = Button => {
  const LoadingButton = ({ children, isLoading, ...props }) => (
    <Button disabled={isLoading} {...props}>
      {children}
      {isLoading && <StyledCircularProgress size={24} />}
    </Button>
  )

  LoadingButton.propTypes = {
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool.isRequired
  }

  return LoadingButton
}
