import { api } from '../../../appSetting'
import { fetch } from '../fetch'
import { newError } from '../error'
import { resultHandlers } from '..'
import { newConfiguration } from '../newCommercialInfluence'
import { executeAll } from '../../../businessRules/saga'

export const getConfigurations = () => dispatch => {
  dispatch(requestedConfigurations())

  const fetcher = fetch(`${api.configurations}`, {})
  const onError = newError('configurations.error.get', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandlers(res => [receivedConfigurations(res), newConfiguration(res)], onError))
    .then(executeAll(dispatch))
    .catch(error => dispatch(onError(error)))
}

export const RequestedConfigurations = 'RequestedConfigurations'
export const requestedConfigurations = params => ({ type: RequestedConfigurations, params })

export const ReceivedConfigurations = 'ReceivedConfigurations'
export const receivedConfigurations = configurations => ({ type: ReceivedConfigurations, configurations })
