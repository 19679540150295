import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { SmallIconButton, SmallNumberButton } from '../buttons/smallIconButton'
import { Option } from '../../util/conditionnalComponent'

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

const ConditionnalSmallTextButton = Option(SmallNumberButton)
const ConditionnalText = Option(Typography)

const BoldSmallNumberButton = withStyles(theme => ({ textNumber: { fontWeight: theme.typography.fontWeightBold } }))(SmallNumberButton)

const style = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary
  }
})

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage, classes } = props

  const numberOfPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1)

  const onClickFirstPage = event => onChangePage(event, 0)
  const onClickPreviousPage = event => onChangePage(event, page - 1)
  const onClickNextPage = event => onChangePage(event, page + 1)
  const onClickLastPage = event => onChangePage(event, numberOfPage)

  return (
    <div className={classes.root}>
      <SmallIconButton onClick={onClickFirstPage} disabled={page === 0} aria-label='first page'>
        <FirstPage fontSize='small' />
      </SmallIconButton>
      <SmallIconButton onClick={onClickPreviousPage} disabled={page === 0} aria-label='previous page'>
        <KeyboardArrowLeft fontSize='small' />
      </SmallIconButton>

      <ConditionnalSmallTextButton condition={page > 1} value={1} onClick={onClickFirstPage} />
      <ConditionnalText condition={page > 1} component='span' variant='caption' value='...' />
      <ConditionnalSmallTextButton condition={page > 0} value={page} onClick={onClickPreviousPage} />

      <BoldSmallNumberButton value={page + 1} />

      <ConditionnalSmallTextButton condition={page < numberOfPage} value={page + 2} onClick={onClickNextPage} />
      <ConditionnalText condition={page < numberOfPage - 1} component='span' variant='caption' value='...' />
      <ConditionnalSmallTextButton condition={page < numberOfPage - 1} value={numberOfPage + 1} onClick={onClickLastPage} />

      <SmallIconButton onClick={onClickNextPage} disabled={page >= numberOfPage} aria-label='next page'>
        <KeyboardArrowRight fontSize='small' />
      </SmallIconButton>
      <SmallIconButton onClick={onClickLastPage} disabled={page >= numberOfPage} aria-label='last page'>
        <LastPage fontSize='small' />
      </SmallIconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(TablePaginationActions)
