import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'

import Plot from 'react-plotly.js'

import { connect } from 'react-redux'
import store from '../../store'
import { updateFilterSaga } from '../../store/saga/filters'
import { demandeForecastSelector, influenceSelector, heatmapSelector, rowsSelector, heatmapThresholdSelector } from '../../store/selectors'
import { newFilter } from '../../store/actions/filters'

import { getZValues, getDimensions, getAbsoluteMax } from '../../businessRules/heatmap'
import { getFilterValue } from '../../businessRules/filterValue'

import { columns, filtersX, filtersY, FilterIndexX, FilterIndexY } from '../../views/forecasting/review/settingsForecasting'
import { withLocalize } from 'react-localize-redux'
import { aggregateRow, aggregateRowWithFare } from '../../businessRules/rows'

import './plotly.css'
import { layout } from './layout'
import { updatemenus, onButtonClicked } from './menu'
import { getData } from './data'

import { CreateLoadingElement, loadingSizes } from '../loading/loading'

const style = _theme => ({ plot: { width: '98%' } })

const selectRange = (values, { from, to }) => values.slice(from, to + 1)
const pair = ([from, to]) => ({ from, to })
const normalize = value => Math.floor(Math.abs(value + 0.5))
const normalizer = values => values.map(normalize)
const selector = (values, range) => selectRange(values, pair(normalizer(range)))

const toFilter = (values, filter) => newFilter(filter.key, filter.groupBy(values))

const mapStateToProps = state => {
  const { pendingInfluences, liveInfluence } = influenceSelector(state)
  const { usingFare: isUsingFare } = heatmapSelector(state)
  const { rows, calculatedDemandeForecast2: calculatedDemandeForecast } = rowsSelector(state)
  const { negative, positive } = heatmapThresholdSelector(state)
  const thresholds = [negative, positive]

  return ({ rows, calculatedDemandeForecast, pendingInfluences, liveInfluence, isUsingFare, thresholds })
}

function HeatMap({ children, rows, calculatedDemandeForecast, pendingInfluences, liveInfluence, isUsingFare, thresholds, classes, translate, theme }) {
  const calculateDynamicValue = row => {
    columns.forEach(column => {
      if (column.id === 'proPredictionLiveInfluenced' && column.dynamicCalcul) row[column.id] = column.dynamicCalcul(row, pendingInfluences, liveInfluence)
    })

    return row
  }

  const rowsHeatmap = [...rows.map(row => ({ ...row }))].map(calculateDynamicValue)
  const calculatedDemandeForecast2 = [...calculatedDemandeForecast.map(row => ({ ...row }))].map(calculateDynamicValue)

  const [activeXfilter, setActiveXfilter] = React.useState(FilterIndexX.DepartureDate)
  const [activeYfilter, setActiveYfilter] = React.useState(FilterIndexY.Class)

  const xFilter = filtersX[activeXfilter]
  const yFilter = filtersY[activeYfilter]

  const xValues = getFilterValue(xFilter, rowsHeatmap)
  const yValues = getFilterValue(yFilter, rowsHeatmap)

  const aggregateOn = isUsingFare ? aggregateRowWithFare : aggregateRow
  const dimensions = getDimensions(rowsHeatmap, aggregateOn)(xFilter.refKey, yFilter.refKey)
  const dimensionsForMax = getAbsoluteMax(calculatedDemandeForecast2, aggregateOn)(xFilter.refKey, yFilter.refKey)

  const zValues = getZValues(dimensions, dimensionsForMax, thresholds)(xValues, yValues)

  const xValuesFormatted = xValues.map(xFilter.format)
  const yValuesFormatted = yValues.map(yFilter.format)

  const UpdateMenus = updatemenus(theme, translate)(activeXfilter, activeYfilter)

  const onSelected = event => {
    if (event) {
      const selectedX = selector(xValues, event.range.x)
      const selectedY = selector(yValues, event.range.y)

      const newFilterValue = []

      if (selectedX.length > 0) newFilterValue.push(toFilter(selectedX, xFilter))
      if (selectedY.length > 0) newFilterValue.push(toFilter(selectedY, yFilter))

      store.dispatch(updateFilterSaga(newFilterValue))
    }
  }

  const messageNoData = calculatedDemandeForecast.length === 0 ? 'heatmap.noData' : undefined
  const messageNoDataMatching = calculatedDemandeForecast.length > 0 && (xValues.length === 0 || yValues.length === 0) ? 'heatmap.noDataForFilter' : undefined
  const message = messageNoData || messageNoDataMatching ? translate(messageNoData || messageNoDataMatching) : undefined

  return (
    <Box position='relative' display='block'>
      {/* See CSS with rule to hack position when responsive */}
      <Plot
        data={getData(theme, translate)(xValuesFormatted, yValuesFormatted, zValues, dimensionsForMax)}
        className={classes.plot}
        layout={layout(UpdateMenus, message)}
        config={{ responsive: true, displaylogo: false, displayModeBar: false, showAxisDragHandles: false }}
        onButtonClicked={onButtonClicked(setActiveXfilter, setActiveYfilter)}
        onSelected={onSelected}
      />
      {children}
    </Box>
  )
}

HeatMap.propTypes = {
  children: PropTypes.node.isRequired,
  rows: PropTypes.array.isRequired,
  calculatedDemandeForecast: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  pendingInfluences: PropTypes.array.isRequired,
  liveInfluence: PropTypes.object.isRequired,
  isUsingFare: PropTypes.bool.isRequired,
  thresholds: PropTypes.array.isRequired
}

export const HeatMapBase = connect(mapStateToProps)(withLocalize(withTheme(withStyles(style)(HeatMap))))

const loadingMapStateToProps = state => ({ isLoading: demandeForecastSelector(state).isLoading })

export const HeatMapWithLoading = connect(loadingMapStateToProps)(CreateLoadingElement(HeatMapBase))

const HeatMapWithLoadingComponent = () => <HeatMapWithLoading size={loadingSizes.big} />

export default HeatMapWithLoadingComponent
