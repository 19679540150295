import React from 'react'
import PropTypes from 'prop-types'

import { DragDropContext, Droppable } from 'react-beautiful-dnd'

const DroppableComponent = (id, onDragEnd) => {
  function DroppableElement(props) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id} direction='horizontal'>
          {
            provided => {
              return (
                <tr ref={provided.innerRef} {...provided.droppableProps} {...props}>
                  {props.children}
                  {provided.placeholder}
                </tr>
              )
            }
          }
        </Droppable>
      </DragDropContext>
    )
  }

  DroppableElement.propTypes = {
    children: PropTypes.node
  }

  return DroppableElement
}

export default DroppableComponent
