import React from 'react'
import PropTypes from 'prop-types'

import CustomFormControl from '../customFormControl/customFormControl'
import { CustomInputLabel, FilledInput, FilledInputFixed } from './index'
import ClearButton from './clearButton'

import { Translate } from 'react-localize-redux'
import { isUndefinedOrNull } from '../../businessRules/compare'

const NumberInput = ({ labelId, value, onChange, defaultValue, step, min, fixedWidth }) => {
  const Input = fixedWidth ? FilledInputFixed : FilledInput

  return (
    <CustomFormControl fullWidth>
      <CustomInputLabel shrink={!isUndefinedOrNull(value)}><Translate id={labelId} /></CustomInputLabel>
      <Input
        fullWidth
        value={value}
        type='number'
        inputProps={{ step, min }}
        onChange={({ target: { value } }) => onChange(value)}
        endAdornment={<ClearButton onClick={() => onChange(defaultValue ?? 0)} />}
      />
    </CustomFormControl>
  )
}

NumberInput.propTypes = {
  labelId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  min: PropTypes.number,
  fixedWidth: PropTypes.bool,
  defaultValue: PropTypes.number
}

export default NumberInput
