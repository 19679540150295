import React from 'react'
import PropTypes from 'prop-types'

import { main, changeOrderingForecastingSaga } from '../../../store/actions/tableState/tableState'

import { connect } from 'react-redux'
import store from '../../../store'
import { tableMainStateSelector, forecastingSelectorForTableBody, rowsSelector } from '../../../store/selectors'

import createTableToolbar from '../../../component/tableToolbar/tableToolbar'
import EnhancedTable from '../../../component/table/table'
import createTableHead from '../../../event/tableHead'
import createTableHeadCell from '../../../event/tableHeadCell'
import createTableBody from '../../../event/tableBody'
import Empty from '../../../component/empty/empty'
import createDataValid from '../../../component/table/tableBody/dataValid'
import createTableFooter from '../../../event/tableFooter'

import { columns } from './settingsForecasting'

const EnhancedTableToolbar = createTableToolbar(tableMainStateSelector)

const onChangeOrdering = (newOrder, newOrderBy) => store.dispatch(changeOrderingForecastingSaga(newOrder, newOrderBy))

const TableHeadCell = createTableHeadCell(tableMainStateSelector, main, onChangeOrdering)
const EnhancedTableHead = createTableHead(tableMainStateSelector, main, TableHeadCell)
const DataValid = createDataValid(Empty)
const EnhancedTableBody = createTableBody(forecastingSelectorForTableBody, tableMainStateSelector, DataValid)
const EnhancedTableFooter = createTableFooter(tableMainStateSelector, main)

const mapStateToProps = state => ({ rows: rowsSelector(state).rows })

const TableForecasting = ({ rows }) =>
  <>
    <EnhancedTableToolbar columns={columns} view={main} />
    <EnhancedTable>
      <EnhancedTableHead />
      <EnhancedTableBody rows={rows} />
      <EnhancedTableFooter numberRows={rows.length} />
    </EnhancedTable>
  </>

TableForecasting.propTypes = { rows: PropTypes.array.isRequired }

export default connect(mapStateToProps)(TableForecasting)
