import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, Typography, ListItemIcon } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import { Translate, withLocalize } from 'react-localize-redux'
import { logout } from '../../authentication/authentication'

const ItemLogout = ({ translate }) => (
  <MenuItem onClick={logout} title={translate('project.logout')}>
    <ListItemIcon title={translate('project.logout')}>
      <ExitToApp fontSize='small' />
    </ListItemIcon>
    <Typography variant='inherit' noWrap><Translate id='project.logout' /></Typography>
  </MenuItem>
)

ItemLogout.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(ItemLogout)
