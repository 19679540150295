import { sortDescBy } from '../../../businessRules/array'

export const columns = [
  { order: 0, id: 'Info', labelId: 'view.optimization.statusTitle', type: 'info' },
  { order: 1, id: 'departureDateRangesFormatted', labelId: 'view.optimization.table.DepartureDateRange' },
  { order: 2, id: 'dowFormatted', labelId: 'view.optimization.table.DoW' },
  { order: 3, id: 'todFormatted', labelId: 'view.optimization.table.ToD' },
  { order: 4, id: 'classFormatted', labelId: 'view.optimization.table.Class' },
  { order: 5, id: 'influence', labelId: 'view.optimization.table.Influence' },
  { order: 6, id: 'volume', labelId: 'view.optimization.table.Volume', type: 'number', toFixed: 0 },
  { order: 7, id: 'Action', labelId: 'view.optimization.table.Action', type: 'action' }
]

export const sortByImportance = sortDescBy('volume')
