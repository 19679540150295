import { fetch, fetchPut } from '../fetch'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { toUIOpimization } from './map'

const optimizationsBaseUrl = api.optimizations
const optimizationBaseUrl = api.optimization

const resultPipeline = (dispatch, fetcher, onSucess, onError) =>
  dispatch(fetcher)
    .then(handleResults(onSucess, onError))
    .then(results => Promise.all(results).then(rs => rs.map(r => dispatch(r))))
    .catch(error => dispatch(onError(error)))

const handleResults = (onSucess, onError) => res =>
  res.status === 200 ? [res.json().then(onSucess)] : [onSucess([]), onError(res)]

export const getOptimizations = ({ Origin, Destination, POS, DateRange: { startDate, endDate }, Cabin, PathGroup }, postdate) => async dispatch => {
  dispatch(requestedOptimizations())

  const fetcher = fetch(`${optimizationsBaseUrl}/${Origin}/${Destination}/${POS}/${startDate}/${endDate}/${Cabin}/${PathGroup}/${postdate}`, 0)
  const onError = newError('view.optimization.error.getting', fetcher.correlationId)

  return resultPipeline(dispatch, fetcher, optimizations => receivedOptimizations(optimizations.map(toUIOpimization)), onError)
}

export const ReceivedOptimizations = 'ReceivedOptimizations'
export const receivedOptimizations = optimizations => ({ type: ReceivedOptimizations, optimizations })

export const RequestedOptimizations = 'RequestedOptimizations'
export const requestedOptimizations = () => ({ type: RequestedOptimizations })

export const setOptimizationStatus = row => async dispatch => {
  dispatch(optimizationUpdating())

  const fetcher = fetchPut(optimizationBaseUrl, row)
  const onError = newError('view.optimization.error.updating', fetcher.correlationId)

  return resultPipeline(dispatch, fetcher, optimizationUpdated(row.id, row.status), onError)
}

export const OptimizationUpdated = 'OptimizationUpdated'
export const optimizationUpdated = (id, status) => optimizations => ({ type: OptimizationUpdated, optimizations, id, status })

export const OptimizationUpdating = 'OptimizationUpdating'
export const optimizationUpdating = () => ({ type: OptimizationUpdating })
