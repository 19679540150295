import { ReceivedDemandeForecast, RequestedDemandeForcast, RecalculatedForecasting, UpdateToDFilter } from '../../actions/DemandeForecast'

import { filters } from '../../../views/forecasting/review/settingsForecasting'
import { getFiltersValue } from '../../../businessRules/filterValue'

const initialState = {
  demandeForecast: [],
  calculatedDemandeForecast: [],
  allFilterValues: getFiltersValue(filters.map(o => Object.assign({}, o)), [], 'toolbar-'),
  hseDates: [],
  isLoading: false,
  isReceived: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ReceivedDemandeForecast: {
      const demandeForecast = action.data ?? []
      const calculatedDemandeForecast = demandeForecast
      const allFilterValues = getFiltersValue(filters.map(o => Object.assign({}, o)), calculatedDemandeForecast, 'toolbar-')
      const hseDates = calculatedDemandeForecast.filter(row => row.isHSE).map(row => row.DepartureDate)

      return Object.assign({}, state, {
        demandeForecast,
        calculatedDemandeForecast,
        allFilterValues,
        hseDates,
        isLoading: false,
        isReceived: true
      })
    }
    case UpdateToDFilter: {
      const rowData = action.data || []
      const tod = rowData.ToD
      const updatedData = { ...state.allFilterValues[2] }
      updatedData.elements = tod
      const allFilterValues = [...state.allFilterValues]
      allFilterValues[2] = updatedData
      return { ...state, allFilterValues }
    }

    case RecalculatedForecasting: {
      const calculatedDemandeForecast = action.data ?? []
      const allFilterValues = getFiltersValue(filters.map(o => Object.assign({}, o)), calculatedDemandeForecast, 'toolbar-')
      const hseDates = calculatedDemandeForecast.filter(row => row.isHSE).map(row => row.DepartureDate)

      return Object.assign({}, state, {
        calculatedDemandeForecast,
        allFilterValues,
        hseDates
      })
    }
    case RequestedDemandeForcast:
      return Object.assign({}, state, {
        demandeForecast: [],
        isLoading: true,
        isReceived: false
      })
    default:
      return state
  }
}
