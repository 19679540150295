import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { mapStackTrace } from 'sourcemapped-stacktrace'
import uuidv4 from 'uuid/v4'
import { connect } from 'react-redux'

import { errorSelector } from '../store/selectors'
import { analytics } from '../logging/analytics'

import ApplicationError from './applicationError'
import HttpError from './httpError'
import { Translate } from 'react-localize-redux'
import Layout from '../views/layout'
import { sortAlphabeticalyDesc } from '../businessRules/array'

const mapStateToProps = state => {
  const { isError, errors } = errorSelector(state)
  return ({ isError, errors })
}

class ErrorLayout extends React.Component {
  state = {
    id: undefined,
    errorName: undefined,
    errorMessage: undefined,
    errorStack: undefined,
    hasError: false
  }

  mapError(errorId, error) {
    mapStackTrace(error.stack, result => {
      this.setState({
        id: errorId,
        errorName: error.name,
        errorMessage: error.message,
        errorStack: result.join('\n')
      })
    })
  }

  static getDerivedStateFromError(_error) {
    return { id: uuidv4(), hasError: true }
  }

  componentDidCatch(exception, _info) {
    const errorId = this.state.id ?? uuidv4()

    // will log application technical error
    analytics.trackException({ exception })
    this.mapError(errorId, exception)
  }

  componentDidUpdate(prevProps) {
    const exception = this.props.error

    if (exception && prevProps.error && prevProps.error.correlationId !== exception.correlationId) {
      // will log network error, server error, http error
      analytics.trackException({ exception })
    }
  }

  renderHttpError() {
    const { errors } = this.props

    const key = ({ correlationId, messageId }) => correlationId + messageId ?? ''

    const orderedErrors = errors.sort((a, b) => sortAlphabeticalyDesc(key(a), key(b)))

    return (
      <Layout>
        {orderedErrors.map(({ correlationId, url, status, statusText, customErrorText, messageId, errorName, errorMessage, errorStack }) => (
          <Box key={key({ correlationId, messageId })} mt={2}>
            <Box mb={2}>
              <Typography variant='h2'>{customErrorText ?? (messageId ? <Translate id={messageId} /> : <></>)}</Typography>
            </Box>
            {
              correlationId ? <div><span><b>Error Correlation-Id: </b></span> {correlationId}</div> : <div />
            }
            <HttpError url={url} status={status} statusText={statusText} />
            <ApplicationError errorName={errorName} errorMessage={errorMessage} errorStack={errorStack} />
          </Box>
        ))}
      </Layout>
    )
  }

  renderApplicationError() {
    const { id, errorName, errorMessage, errorStack } = this.state

    return (
      <Layout>
        <Box mt={2}>
          {/* <Box mb={2}>
            <Typography variant='h2'>{customErrorText}</Typography>
          </Box> */}
          {
            id ? <div><span><b>Error id: </b></span> {id}</div> : <div />
          }
          <ApplicationError errorName={errorName} errorMessage={errorMessage} errorStack={errorStack} />
        </Box>
      </Layout>
    )
  }

  render() {
    const { isError } = this.props
    const { hasError } = this.state

    if (isError) {
      return this.renderHttpError()
    } else if (hasError) {
      return this.renderApplicationError()
    } else {
      return this.props.children
    }
  }
}

ErrorLayout.propTypes = {
  isError: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  error: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default connect(mapStateToProps)(ErrorLayout)
