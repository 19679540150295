import { fetch, fetchDelete, fetchPost, fetchPut } from '../fetch'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { resultHandler, resultHandlers } from '..'
import { toUIUser, toUser } from './map'
import { configurationSelector } from '../../selectors'
import { getNewUsers } from '../newUser'

export const getUsers = () => (dispatch, getState) => {
  const { regions, roles, user_roles } = configurationSelector(getState())
  dispatch(requestedUsers())

  const fetcher = fetch(`${api.users}`, {})
  const onError = newError('view.users.error.get', fetcher.correlationId)
  const toResult = resultHandlers(users => receivedUsers(users.map(toUIUser(regions, roles, user_roles))), onError)

  return dispatch(fetcher)
    .then(toResult)
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}
export const RequestedUsers = 'RequestedUsers'
export const requestedUsers = params => ({ type: RequestedUsers, params })

export const ReceivedUsers = 'ReceivedUsers'
export const receivedUsers = users => ({ type: ReceivedUsers, users })

export const deleteUser = ({ id }) => dispatch => {
  dispatch(deletingUser({ id }))

  const fetcher = fetchDelete(`${api.user}`, id)
  const onError = newError('view.User.error.delete', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(() => deletedUser({ id }), onError))
    .then(results => dispatch(results))
    .then(() => dispatch(getNewUsers()))
    .catch(error => dispatch(onError(error)))
}

export const DeletingUser = 'DeletingUsers'
export const deletingUser = user => ({ type: DeletingUser, user })

export const DeletedUser = 'DeletedUsers'
export const deletedUser = user => ({ type: DeletedUser, user })

export const addUser = user => (dispatch, getState) => {
  const { regions, roles, user_roles } = configurationSelector(getState())
  dispatch(addingUser(user))

  const fetcher = fetchPost(`${api.user}`, toUser(user))
  const onError = newError('view.User.error.add', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(({ id, partitionKey }) => addedUser({ ...toUIUser(regions, roles, user_roles)(user), id, partitionKey }), onError))
    .then(results => dispatch(results))
    .then(() => dispatch(getNewUsers()))
    .catch(error => dispatch(onError(error)))
}

export const AddingUser = 'AddingUser'
export const addingUser = user => ({ type: AddingUser, user })

export const AddedUser = 'AddedUser'
export const addedUser = user => ({ type: AddedUser, user })

export const modifyUser = user => (dispatch, getState) => {
  const { regions, roles, user_roles } = configurationSelector(getState())
  dispatch(modifyingUser(user))

  const fetcher = fetchPut(`${api.user}`, toUser(user))
  const onError = newError('view.User.error.edit', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(({ id }) => modifiedUser({ ...toUIUser(regions, roles, user_roles)(user) }, id, user.id), onError))
    .then(results => dispatch(results))
    .catch(error => dispatch(onError(error)))
}

export const ModifyingUser = 'ModifyingUser'
export const modifyingUser = user => ({ type: ModifyingUser, user })

export const ModifiedUser = 'ModifiedUser'
export const modifiedUser = (user, newid, oldid) => ({ type: ModifiedUser, user, newid, oldid })
