import {
  RequestedUsers,
  ReceivedUsers,
  AddingUser,
  AddedUser,
  DeletingUser,
  DeletedUser,
  ModifyingUser,
  ModifiedUser
} from '../../actions/users'

const initialState = {
  users: [],
  isLoading: false
}

const newState = (users, isLoading) => ({ users, isLoading })

export default (state = initialState, action) => {
  const { type, users, user, newid, oldid } = action
  const assignState = newState => Object.assign({}, state, newState)

  const withoutCurrentUser = userid => state.users.filter(({ id }) => id !== userid)

  switch (type) {
    case RequestedUsers:
      return assignState(newState([], true))
    case ReceivedUsers:
      return assignState(newState(users, false))
    case AddingUser:
      return assignState(newState(state.users, true))
    case AddedUser:
      return assignState(newState([...state.users, user], false))
    case ModifyingUser:
      return assignState(newState(state.users, true))
    case ModifiedUser:
      return assignState(newState([...withoutCurrentUser(oldid), { ...user, id: newid }], false))
    case DeletingUser:
      return assignState(newState(state.users, true))
    case DeletedUser:
      return assignState(newState([...withoutCurrentUser(user.id)], false))
    default:
      return state
  }
}
