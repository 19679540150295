import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import uuidv4 from 'uuid/v4'

const defaultAnalytics = {
  trackException: () => {},
  trackEvent: () => {},
  trackPageView: () => {},
  loadAppInsights: () => {}
}

export const initAnalytics = () => {
  const instrumentationKey = process.env.REACT_APP_APPLICATION_INSIGHT_KEY

  const analytics = instrumentationKey ? new ApplicationInsights({ config: { instrumentationKey } }) : defaultAnalytics

  analytics.loadAppInsights()
  analytics.context.telemetryTrace.traceID = uuidv4()
  analytics.context.telemetryTrace.name = 'RMAIDF-WebApp-FrontEnd'

  return analytics
}

export const analytics = initAnalytics()

export const analyticsMiddleware = store => next => action => {
  if (action) analytics.trackEvent({ name: action.type }, { ...action })
  next(action)
}
