import React from 'react'
import PropTypes from 'prop-types'

import EnhancedTableBody from '../component/table/tableBody/tableBody'

import { connect } from 'react-redux'
import { stableSort, customSortBy, getSorter } from '../businessRules/array'

export default function createTableBody(dataSelector, tableStateSelector, DataValideComponent) {
  const mapStateToPropsTableBody = state => {
    const { isFetchFailed, isDataLoading } = dataSelector(state)
    const { colSpan, order, orderBy } = tableStateSelector(state)
    return ({ isFetchFailed, isDataLoading, colSpan, order, orderBy })
  }

  const mapStateToPropsDataValid = state => {
    const { page, rowsPerPage, columnsOrdered } = tableStateSelector(state)
    return ({ page, rowsPerPage, columnsOrdered })
  }

  const ConnectedDataValide = connect(mapStateToPropsDataValid)(DataValideComponent)

  const EnhancedTableBodyWrapper = ({ rows, isFetchFailed, isDataLoading, colSpan, order, orderBy }) => {
    const orderedRows = stableSort(rows, customSortBy(getSorter(order))(orderBy))
    return <EnhancedTableBody rows={orderedRows} isFetchFailed={isFetchFailed} isDataLoading={isDataLoading} colSpan={colSpan} DataValide={ConnectedDataValide} />
  }

  EnhancedTableBodyWrapper.propTypes = {
    rows: PropTypes.array.isRequired,
    isFetchFailed: PropTypes.bool.isRequired,
    isDataLoading: PropTypes.bool.isRequired,
    colSpan: PropTypes.number.isRequired,
    order: PropTypes.oneOf(['asc', 'desc', '']),
    orderBy: PropTypes.string
  }

  return connect(mapStateToPropsTableBody)(EnhancedTableBodyWrapper)
}
