import {
  RequestedConfigurations,
  ReceivedConfigurations
} from '../../actions/configuration'

import { newSelectItemByKey, newSelectItem } from '../../../component/Select'
import { formatDoW, formatToD, formatStringAsList } from '../../../businessRules/date'
import { joinList } from '../../../businessRules/array'
import { toSeatClass } from '../../../businessRules/object'
import _ from 'lodash'

const initialState = {
  pathTypes: [],
  pathTypesSelectItem: [],
  dayOfWeek: [],
  dayOfWeekSelectItem: [],
  timeOfDay: [],
  timeOfDaySelectItem: [],
  cabinClassGroup: [],
  classGroup: [],
  classGroupSelectItem: [],
  decays: [],
  decaysSelectItem: [],
  isLoading: false,
  roles: [],
  user_roles: [],
  regions: [],
  cabins: [],
  odDmMapping: [],
  odsByRegion: [],
  splitPeriod: {}
}

export default (state = initialState, action) => {
  const { type, configurations } = action
  const assignState = newState => Object.assign({}, state, newState)

  switch (type) {
    case RequestedConfigurations:
      return assignState(initialState)
    case ReceivedConfigurations: {
      const pathTypes = configurations.pathType ?? initialState.pathTypes
      const pathTypesSelectItem = pathTypes.map(newSelectItemByKey)

      const dayOfWeek = configurations.dayOfWeek ?? initialState.dayOfWeek
      const dayOfWeekSelectItem = dayOfWeek.map(v => newSelectItem(v, v, formatDoW(v)))

      const timeOfDay = configurations.timeOfDay ?? initialState.pathTypes
      const timeOfDaySelectItem = timeOfDay.map(v => newSelectItem(v, v, formatToD(v)))

      const cabinClassGroup = configurations.classGroup ?? initialState.cabinClassGroup

      const classGroup = cabinClassGroup.map(toSeatClass) ?? initialState.classGroup
      const classGroupSelectItem = classGroup.map(v => newSelectItem(v, v, formatStringAsList(joinList)(v)))

      const decays = configurations.decays ?? initialState.decays
      const decaysSelectItem = decays.map(newSelectItemByKey)

      const regions = configurations.regions ?? initialState.regions
      const regionsSelectItem = regions.map(({ id, description }) => newSelectItem(id, id, description))

      const roles = configurations.roles ?? initialState.roles
      const user_roles = configurations.user_roles ?? initialState.user_roles

      const rolesSelectItem = roles.map(({ id, description }) => newSelectItem(id, id, description))
      const userRolesSelectItem = user_roles.map(({ id, role }) => newSelectItem(id, id, role))

      const cabins = configurations.cabins ?? initialState.cabins

      const odDmMapping = configurations.odDmMapping ?? initialState.odDmMapping

      // direct mapping of od to region not available for now.
      // so, arranging that by this code
      const odsByRegion = configurations.odDmMapping
        ? _.chain(odDmMapping).groupBy('region')
          .map(r => ({
            region: _.first(r).region,
            ods: _.chain(r).map(re => ({
              od: `${re.origin}-${re.destination}`,
              origin: re.origin,
              destination: re.destination,
              region: re.region,
              originId: re.origin_id,
              destinationId: re.destination_id
            }))
              .uniqBy('od')
              .orderBy(['origin', 'destinition'], ['asc', 'asc']).value()
          })
          ).value()
        : initialState.odsByRegion

      const splitPeriod = configurations.splitPeriod ?? initialState.splitPeriod

      return assignState({
        pathTypes,
        pathTypesSelectItem,
        dayOfWeek,
        dayOfWeekSelectItem,
        timeOfDay,
        timeOfDaySelectItem,
        cabinClassGroup,
        classGroup,
        classGroupSelectItem,
        decays,
        decaysSelectItem,
        regions,
        regionsSelectItem,
        roles,
        user_roles,
        rolesSelectItem,
        userRolesSelectItem,
        cabins,
        odDmMapping,
        odsByRegion,
        splitPeriod
      })
    }
    default:
      return state
  }
}
