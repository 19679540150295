import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import Layout from './layout'
import PageHeader from '../component/PageHeader/pageHeader'
import WelcomeMessage from '../component/welcomeMessage/welcomeMessage'
import TableSelector from '../component/tableSelector/tableSelector'
import Title from '../component/title/title'

import Spacer from '../component/spacer/spacer'
import Empty from '../component/empty/empty'

import { connect } from 'react-redux'
import { userSettingsSelector } from '../store/selectors'
import { withLocalize } from 'react-localize-redux'

const mapStateToProps = state => {
  const { givenName } = userSettingsSelector(state)
  return ({ givenName })
}

class WelcomeView extends Component {
  render() {
    const { givenName, translate } = this.props

    return (
      <Box display='flex' alignItems='center' justifyContent='center' height='100%' width='100%' flexDirection='column' position='absolute'>
        <Spacer />
        <Box width='100%'>
          <Layout>
            <WelcomeMessage name={givenName ?? translate('welcomeMessage.defaultName')} />
          </Layout>
        </Box>
        <PageHeader pt={10} pb={10} width='100%'>
          <Layout>
            <Title labelId='tableSelector.title' mb={2} />
            <TableSelector CancelButton={Empty} flexDirection='row' />
          </Layout>
        </PageHeader>
        <Spacer />
      </Box>
    )
  }
}

WelcomeView.propTypes = { givenName: PropTypes.string, translate: PropTypes.func.isRequired }

export default connect(mapStateToProps)(withLocalize(WelcomeView))
