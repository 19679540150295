import { fetch, fetchPut } from '../fetch'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { toUIOpimization, toODUIOpimization } from './map'

const PortfolioBaseUrl = api.portfolio
const optimizationBaseUrl = api.optimization

const resultPipeline = (dispatch, fetcher, onSucess, onError) =>
  dispatch(fetcher)
    .then(handleResults(onSucess, onError))
    .then((results) => Promise.all(results).then((rs) => rs.map((r) => dispatch(r))))
    .catch((error) => dispatch(onError(error)))

const handleResults = (onSucess, onError) => (res) =>
  res.status === 200 ? [res.json().then(onSucess)] : [onSucess([]), onError(res)]
  // { DateRange: { startDate, endDate }, userId, regionIds }, postdate
/// http://localhost:3001/api/portfolio/singleD?userId=322&postdate=1617062400000&regionIds=4&startDate=1617667200000&endDate=1628035200000
export const getPortfolioSingleAndOD = ({ DateRange: { startDate, endDate }, email, cabinIds, regionIds }, postdate) => async(dispatch) => {
  dispatch(requestedPortfolioSingleAndOD())
  const fetcher = fetch(`${PortfolioBaseUrl}/singleAndOd`, { email, regionIds, startDate, endDate, cabinIds, postdate })
  const onError = newError('view.optimization.error.getting', fetcher.correlationId)
  return resultPipeline(dispatch, fetcher, (portfolio) => {
    if (!portfolio || portfolio.length === 0 || portfolio === undefined) {
      portfolio.portfolioSingles = []
      portfolio.portfolioODs = []
      return receivedPortfolioSingleAndOD(portfolio)
    }
    const portfolioSingleUpdatedUi = portfolio.portfolioSingles ? portfolio.portfolioSingles.map(toUIOpimization) : []
    portfolio.portfolioSingles = portfolioSingleUpdatedUi
    const portfolioODUpdatedUi = portfolio.portfolioODs ? portfolio.portfolioODs.map(toODUIOpimization) : []
    portfolio.portfolioODs = portfolioODUpdatedUi
    return receivedPortfolioSingleAndOD(portfolio)
  }, onError)
}
export const ReceivedPortfolioSingleAndOD = 'ReceivedPortfolioSingleAndOD'
export const receivedPortfolioSingleAndOD = (portfolios) => ({
  type: ReceivedPortfolioSingleAndOD,
  portfolios
})
export const RequestedPortfolioSingleAndOD = 'RequestedPortfolioSingleAndOD'
export const requestedPortfolioSingleAndOD = () => ({
  type: RequestedPortfolioSingleAndOD
})
export const ReceivedPortfolioSingle = 'ReceivedPortfolioSingle'
export const receivedPortfolioSingle = (portfolios) => ({
  type: ReceivedPortfolioSingle,
  portfolios
})

export const RequestedPortfolioSingle = 'RequestedPortfolioSingle'
export const requestedPortfolioSingle = () => ({
  type: RequestedPortfolioSingle
})

export const ReceivedPortfolioOD = 'ReceivedPortfolioOD'
export const receivedPortfolioOD = (portfolios) => ({
  type: ReceivedPortfolioOD,
  portfolios
})

export const RequestedPortfolioOD = 'RequestedPortfolioOD'
export const requestedPortfolioOD = () => ({ type: RequestedPortfolioOD })

// http://localhost:3001/api/Optimization/2848402
export const setSingleOptimizationStatus = row => async dispatch => {
  dispatch(singleOptimizationUpdating(row.id))

  const fetcher = fetchPut(optimizationBaseUrl, row)
  const onError = newError('view.optimization.error.updating', fetcher.correlationId)

  return resultPipeline(dispatch, fetcher, singleOptimizationUpdated(row.id, row.status), onError)
}

export const SingleOptimizationUpdated = 'SingleOptimizationUpdated'
export const singleOptimizationUpdated = (id, status) => (portfoliosOD) => ({
  type: SingleOptimizationUpdated, portfoliosOD, id, status
})

export const SingleOptimizationUpdating = 'SingleOptimizationUpdating'
export const singleOptimizationUpdating = (id) => ({ type: SingleOptimizationUpdating, id })

export const UpdatePortfolioSelectors = 'UpdatePortfolioSelectors'
export const updatePortfolioSelectors = (selectors) => ({
  type: UpdatePortfolioSelectors,
  selectors
})
// http://localhost:3001/api/portfolio/od/2848402
export const setODOptimizationStatus = row => async dispatch => {
  dispatch(odUpdating(row.id))
  const fetcher = fetchPut(`${PortfolioBaseUrl}/od`, row)
  const onError = newError('view.optimization.error.updating', fetcher.correlationId)

  return resultPipeline(dispatch, fetcher, odUpdated(row.id, row.status), onError)
}

export const ODUpdated = 'ODUpdated'
export const odUpdated = (id, status) => (portfoliosOD) => ({
  type: ODUpdated, portfoliosOD, id, status
})
export const ODupdating = 'ODupdating'
export const odUpdating = (id) => ({ type: ODupdating, id })
