import {
  ErrorInfluences,
  InfluenceSent,
  SendingInfluences,
  SendInfluencesDone,
  ChangeInfluenceDimension,
  ChangeInfluenceValue,
  ChangeInfluenceString,
  DeletingInfluence,
  ReceivedPendingInfluences,
  RequestedPendingInfluences,
  LoadInfluence,
  SetBaseDescription,
  InfluenceDeleted,
  UpdateAlertOpenFlag
} from '../../actions/Influences'

import { DefaultInfluence } from '../../../appSetting'

const initialState = {
  isError: false,
  error: { },
  isLoading: false,
  isDeleting: false,
  isSent: false,
  baseDescription: '',
  liveInfluence: {
    influence: DefaultInfluence,
    influenceString: `${DefaultInfluence}`,
    description: '',
    Class: [],
    ToD: [],
    DoW: [],
    DepartureDate: [],
    trip_Origin: undefined,
    trip_Destination: undefined,
    pos: undefined
  },
  pendingInfluences: [],
  isPendingInfluencesLoading: false,
  isOpenAlertFlag: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ErrorInfluences:
      return Object.assign({}, state, {
        isError: true,
        error: {
          customErrorText: action.message,
          statusText: action.error.statusText,
          status: action.error.status,
          url: action.error.url
        },
        isLoading: false,
        isSent: false
      })
    case InfluenceSent: {
      const pendingInfluences = [...state.pendingInfluences, action.data]
      const { influence, influenceString } = initialState.liveInfluence

      return Object.assign({}, state, {
        isError: false,
        isLoading: false,
        isSent: true,
        pendingInfluences: pendingInfluences,
        liveInfluence: { ...action.data, influence, influenceString, description: state.baseDescription }
      })
    }
    case InfluenceDeleted: {
      const pendingInfluences = [...state.pendingInfluences.filter(influence => influence.id !== action.id)]
      const liveInfluence = Object.assign({}, { ...state.liveInfluence }, { id: undefined })

      return Object.assign({}, state, {
        isDeleting: false,
        pendingInfluences: pendingInfluences,
        liveInfluence
      })
    }
    case DeletingInfluence:
      return Object.assign({}, state, { isDeleting: true })
    case SendingInfluences:
      return Object.assign({}, state, {
        isError: false,
        isLoading: true,
        isSent: false
      })
    case SendInfluencesDone:
      return Object.assign({}, state, { isError: false })
    case SetBaseDescription:
      return Object.assign({}, state, { baseDescription: action.description })
    case ChangeInfluenceDimension: {
      return Object.assign({}, state, {
        liveInfluence: {
          ...state.liveInfluence,
          ...action.data
        }
      })
    }
    case ChangeInfluenceValue: {
      return Object.assign({}, state, {
        liveInfluence: {
          ...state.liveInfluence,
          influence: action.influence,
          influenceString: `${action.influence}`
        }
      })
    }
    case ChangeInfluenceString: {
      return Object.assign({}, state, {
        liveInfluence: {
          ...state.liveInfluence,
          influence: notNaN(parseFloat(action.influenceString), DefaultInfluence),
          influenceString: action.influenceString
        }
      })
    }
    case LoadInfluence: {
      const liveInfluence = state.pendingInfluences.filter(pendingInfluence => pendingInfluence.id === action.id)[0]

      return Object.assign({}, state, { liveInfluence: { ...liveInfluence, influenceString: `${liveInfluence.influence}` } })
    }
    case ReceivedPendingInfluences:
      return Object.assign({}, state, {
        pendingInfluences: action.influences,
        isPendingInfluencesLoading: false
      })
    case RequestedPendingInfluences: {
      return Object.assign({}, state, {
        pendingInfluences: [],
        isPendingInfluencesLoading: true
      })
    }
    case UpdateAlertOpenFlag: {
      return Object.assign({}, state, {
        isOpenAlertFlag: action.flag
      })
    }
    default:
      return state
  }
}

const notFunction = check => (newValue, defaultValue) => check(newValue) ? defaultValue : newValue
const notNaN = notFunction(isNaN)
