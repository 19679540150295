import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemText, Select } from '@material-ui/core'

import CustomFormControl from '../customFormControl/customFormControl'
import { CustomInputLabel, FilledInput } from '../input'

import { FullHeightDefaultButton } from '../buttons/defaultButton'
import { EmptyClassComponent } from '../empty/empty' // Need to be an empty class component as React Select component expect to have class component
import ArrowUpDown from '../ArrowUpDown'
import { Translate, withLocalize } from 'react-localize-redux'
import { sentence } from '../../businessRules/string'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function SingleSelect(props) {
  const { elements, required, error, titleId, labelId, updateValue, value, renderValue, isLoading, fullWidth, translate } = props

  const [isMenuOpen, setMenuState] = React.useState(false)

  const filterBySearchValue = () => !isLoading
  const title = sentence(titleId, labelId)(translate)
  const unit = value => value

  return (
    <CustomFormControl error={error} fullWidth={fullWidth}>
      <CustomInputLabel required={required}><Translate id={labelId} /></CustomInputLabel>
      <Select
        fullWidth={fullWidth}
        title={title}
        value={value ?? ''}
        onChange={({ target: { value } }) => updateValue(value)}
        input={<FilledInput />}
        renderValue={renderValue ?? unit}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': translate(labelId) }}
        IconComponent={() => <EmptyClassComponent />}
        open={isMenuOpen}
        onOpen={() => setMenuState(true)}
        onClose={() => setMenuState(false)}
        endAdornment={
          <FullHeightDefaultButton title={title} color='default' aria-label='directions' variant='contained' size='small' onClick={() => setMenuState(true)}>
            <ArrowUpDown open={isMenuOpen} />
          </FullHeightDefaultButton>
        }
      >
        {
          elements.filter(filterBySearchValue).map(element => (
            <MenuItem title={element.formattedValue} key={element.key} value={element.value}>
              <ListItemText primary={element.formattedValue} />
            </MenuItem>
          ))
        }
      </Select>
    </CustomFormControl>
  )
}

SingleSelect.propTypes = {
  elements: PropTypes.array.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  titleId: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  renderValue: PropTypes.func,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export default withLocalize(SingleSelect)
