import { ReceivedPostdate, RequestedPostdate } from '../../actions/PostDate'
import { utcToMoment } from '../../../businessRules/date'

const initialState = {
  postdate: 0,
  postdateMoment: utcToMoment(0),
  optimizationInProgress: false,
  lowRange: {},
  mediumRange: {},
  highRange: {},
  isLoading: true
}

const endOfLowRange = today => today.clone().add(120, 'd')
const endOfMediumRange = today => today.clone().add(240, 'd')
const endOfHighRange = today => today.clone().add(360, 'd')

export const getLowRange = today => ({ startDate: today.clone(), endDate: endOfLowRange(today) })
export const getMediumRange = today => ({ startDate: endOfLowRange(today).add(1, 'd'), endDate: endOfMediumRange(today) })
export const getHighRange = today => ({ startDate: endOfMediumRange(today).add(1, 'd'), endDate: endOfHighRange(today) })

export default (state = initialState, action) => {
  const { type, postdate, optimizationInProgress } = action
  const postdateMoment = utcToMoment(postdate)
  switch (type) {
    case ReceivedPostdate:
      return Object.assign({}, state, {
        postdate,
        postdateMoment,
        optimizationInProgress: optimizationInProgress ?? initialState.optimizationInProgress,
        lowRange: getLowRange(postdateMoment),
        mediumRange: getMediumRange(postdateMoment),
        highRange: getHighRange(postdateMoment),
        isLoading: false
      })
    case RequestedPostdate:
      return Object.assign({}, state, {
        isLoading: true
      })
    default:
      return state
  }
}
