import Indicator from '../../../component/Indicator'

import { connect } from 'react-redux'
import { rowsSelector } from '../../../store/selectors'

const mapStateToProps = state => {
  const { rows } = rowsSelector(state)
  const show = rows.some(row => row.hasCommercialInfluences ?? false)

  return ({ show })
}

export default connect(mapStateToProps)(Indicator)
