import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { userDone } from '../../store/actions/newUser'
import { newUserSelector } from '../../store/selectors'

import { Grid, withStyles, Box } from '@material-ui/core'
import { createNewFullPaddedButton } from '../../component/buttons/defaultButton'
import CustomSubtitle from '../../component/title/customSubtitle'
import Email from './components/email'
import Regions from './components/regions'
import Role from './components/role'
import Access from './components/access'

import { Translate } from 'react-localize-redux'
import { isEmpty } from '../../businessRules/string'
import { isArrayEmpty } from '../../businessRules/array'
import { newUserSaga } from '../../store/saga/newUser'

const PaddedButton = createNewFullPaddedButton({ r: 4, l: 4 })
const DoublePaddedButton = createNewFullPaddedButton({ r: 8, l: 8 })

const FlexItemGrid = withStyles(_theme => ({ root: { display: 'flex' } }))(Grid)

const mapStateToProps = state => {
  const { user } = newUserSelector(state)
  return ({ user })
}

const spacing = 2

function AddSection({ user }) {
  const [isSubmitClicked, setSubmitClicked] = React.useState(false)

  const cancel = () => store.dispatch(userDone())
  const add = () => {
    setSubmitClicked(true)
    if (!(isEmpty(user.email) || isEmpty(user.role) || isArrayEmpty(user.regions))) {
      store.dispatch(newUserSaga(user))
      setSubmitClicked(false)
    }
  }

  return (
    <Box mt={1}>
      <Grid container spacing={spacing}>
        <FlexItemGrid item>
          <CustomSubtitle labelId='view.users.addNewuser' />
        </FlexItemGrid>
      </Grid>
      <Grid container spacing={spacing}>
        <Grid item xs={6} lg={3}>
          <Email email={user.email} isSubmitClicked={isSubmitClicked} />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Role role={user.user_role} isSubmitClicked={isSubmitClicked} />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Regions regions={user.regions} isSubmitClicked={isSubmitClicked} />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Access role={user.role} isSubmitClicked={isSubmitClicked} />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Grid container spacing={spacing}>
          <FlexItemGrid item xs={6} lg={4}>
            <Box mr={1}>
              <DoublePaddedButton variant='contained' color='primary' onClick={add}>
                <Translate id='view.users.addButton' />
              </DoublePaddedButton>
            </Box>
            <PaddedButton variant='contained' onClick={cancel}>
              <Translate id='view.users.cancel' />
            </PaddedButton>
          </FlexItemGrid>
        </Grid>
      </Box>
    </Box>
  )
}

AddSection.propTypes = {
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(AddSection)
