import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import Layout from './layout'
import { withRouter, Link } from 'react-router-dom'
import { routes } from '../appSetting'

class Page404 extends Component {
  render() {
    const { location: { pathname } } = this.props

    return (
      <Layout>
        <Box mt={2} mb={2}>
          <Typography variant='h1'><Translate id='view.page404.title' /></Typography>
        </Box>
        <Box><Translate id='view.page404.description' /></Box>
        <Box mt={2}>
          <Translate id='view.page404.page' />: <b>{pathname}</b>
        </Box>
        <Box mt={2}>
          <Translate id='view.page404.goto' />
          &nbsp;
          <Link to={routes.portfolio}>
            <Translate id='view.page404.homepage' />
          </Link>
        </Box>
      </Layout>
    )
  }
}

Page404.propTypes = {
  location: PropTypes.object.isRequired
}

export default withRouter(Page404)
