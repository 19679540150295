import { calculateRows } from '../rows'
import { saga } from '../../../businessRules/saga'

export const main = 'main'
export const pendingInfluences = 'pendingInfluences'
export const optimizations = 'optimizations'
export const commercialInfluences = 'commercialInfluences'
export const users = 'users'
export const portfolioOdOptimizations = 'portfolioOdOptimizations'

export const views = [main, pendingInfluences, optimizations, commercialInfluences, users]

export const ChangeOrder = 'ChangeOrder'
export const changeOrder = view => data => ({ type: ChangeOrder, view, data })
export const changeOrderForecastingSaga = data => saga([changeOrder(main)(data), calculateRows()])

export const ChangeOrderBy = 'ChangeOrderBy'
export const changeOrderBy = view => data => ({ type: ChangeOrderBy, view, data })
export const changeOrderByForecastingSaga = data => saga([changeOrderBy(main)(data), calculateRows()])

export const ChangeOrdering = 'ChangeOrdering'
export const changeOrdering = view => (order, orderBy) => ({ type: ChangeOrdering, view, order, orderBy })
export const changeOrderingForecastingSaga = (order, orderBy) => saga([changeOrdering(main)(order, orderBy), calculateRows()])

export const ChangeColumnHidden = 'ChangeColumnHidden'
export const changeColumnHidden = view => data => ({ type: ChangeColumnHidden, view, data })

export const ChangePage = 'ChangePage'
export const changePage = view => page => ({ type: ChangePage, view, page })

export const ChangeRowsPerPage = 'ChangeRowsPerPage'
export const changeRowsPerPage = view => data => ({ type: ChangeRowsPerPage, view, data })

export const ChangeTableColumnOrder = 'ChangeTableColumnOrder'
export const changeTableColumnOrder = view => (sourceIndex, destinationIndex) => ({
  type: ChangeTableColumnOrder,
  view,
  sourceIndex,
  destinationIndex
})
