import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import CommercialInfluenceIndicator from './commercialInfluenceIndicator'

import { connect } from 'react-redux'
import { heatmapSelector, filterSelector } from '../../../store/selectors'

import { SubTitle4 } from '../../../component/title/subtitle'
import { filters } from './settingsForecasting'

import { Translate } from 'react-localize-redux'

const mapStateToProps = state => ({
  isUsingFare: heatmapSelector(state).usingFare,
  filtersValue: filterSelector(state).filters.present
})

const HeatmapTool = ({ isUsingFare, filtersValue }) => {
  const isEmptyArray = filter => filtersValue[filter.key].length > 0
  const toSubtitle = filter => filter.formatList(filtersValue[filter.key].sort(filter.orderBy))
  const titleId = isUsingFare ? 'heatmap.title' : 'heatmap.titleNoFare'

  return (
    <Box mb={2}>
      <Box display='flex'>
        <Typography variant='h2'><Translate id={titleId} /></Typography>
        <CommercialInfluenceIndicator ml={3} labelId='heatmap.commercialInfluenceIndicator' />
      </Box>
      <SubTitle4>
        {filters.filter(isEmptyArray).map(toSubtitle).join(' | ')}
      </SubTitle4>
    </Box>
  )
}

HeatmapTool.propTypes = {
  isUsingFare: PropTypes.bool.isRequired,
  filtersValue: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(HeatmapTool)
