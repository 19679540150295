const statusCreated = 'Created'
const statusReady2Push = 'ReadyToPush'
const statusPushed = 'Pushed'

export const isDeleteDisabled = row => row.status !== statusCreated

export const showStatus = translate => value => {
  switch (value) {
    case statusCreated:
      return translate('view.influence.statusCreated')
    case statusReady2Push:
      return translate('view.influence.statusReady2Push')
    case statusPushed:
      return translate('view.influence.statusPushed')
    default:
      return value
  }
}
