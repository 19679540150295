import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { influenceSelector } from '../../../store/selectors'
import { NotAvailable } from '../../../appSetting'
import { withLocalize } from 'react-localize-redux'
import { formatDecimalNumber } from '../../../businessRules/number'

const mapStateToProps = state => {
  const { pendingInfluences, liveInfluence } = influenceSelector(state)
  return ({ pendingInfluences, liveInfluence })
}

function DataType({ column, row, pendingInfluences, liveInfluence, translate }) {
  const value = column.dynamicCalcul ? column.dynamicCalcul(row, pendingInfluences, liveInfluence) : row[column.id]

  switch (column.type) {
    case 'number':
      return value || value === 0 ? formatDecimalNumber(value, column.toFixed) : NotAvailable
    case 'translate':
      return value || value === 0 ? column.translate(translate)(value) : NotAvailable
    default:
      return (value || value === 0) ? value : NotAvailable
  }
}

DataType.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  pendingInfluences: PropTypes.array.isRequired,
  liveInfluence: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withLocalize(DataType))
