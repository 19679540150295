import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../store'
import { loadSettings } from '../store/actions/views'
import { viewsSelector } from '../store/selectors'

import { toUnix, toMoment } from '../businessRules/date'
import { isUndefinedOrNull } from '../businessRules/compare'

const mapStateToProps = state => {
  const { isAppLoading } = viewsSelector(state)
  return ({ isAppLoading })
}

class LoadSetting extends React.Component {
  componentDidMount() {
    const url = new URL(window.location)
    const postdate = url.searchParams.get('postdate')
    const unixPostdate = isUndefinedOrNull(postdate) ? undefined : toUnix(toMoment(postdate))

    store.dispatch(loadSettings(unixPostdate))
  }

  render() {
    return (
      <>
        {!this.props.isAppLoading ? this.props.children : null}
      </>
    )
  }
}

LoadSetting.propTypes = {
  isAppLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default connect(mapStateToProps)(LoadSetting)
