import { Box, withStyles } from '@material-ui/core'

export const topRight = {
  top: '7px',
  right: '-20px',
  transform: 'rotate(45deg)'
}

export const topLeft = {
  top: '7px',
  left: '-20px',
  transform: 'rotate(-45deg)'
}

const newTag = (text, position) => withStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    '&::after': {
      content: `"${text}"`,

      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.tiertiary.main,
      background: theme.palette.brand['05'],

      position: 'absolute',
      width: '80px',
      height: '25px',
      textAlign: 'center',
      textTransform: 'uppercase',
      lineHeight: '27px',

      ...position
    }
  }
}))(Box)

export default newTag
