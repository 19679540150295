import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AlertDialog from '../dialog/dialog'

import { connect } from 'react-redux'
import store from '../../store'
import { sendInfluencesDone } from '../../store/actions/Influences'
import { influenceSelector, viewsSelector } from '../../store/selectors'

import { withLocalize } from 'react-localize-redux'
import { Modal, CircularProgress, Box } from '@material-ui/core'

const mapStateToProps = state => {
  const influenceResult = influenceSelector(state)

  return ({
    isInfluenceError: influenceResult.isError,
    errorMessage: influenceResult.error.customErrorText,
    isChangingView: viewsSelector(state).isChangingView
  })
}

class PageContext extends Component {
  confirmInfluenceNotSaved() {
    store.dispatch(sendInfluencesDone())
  }

  render() {
    const { isInfluenceError, errorMessage, isChangingView, translate } = this.props

    return (
      <>
        <AlertDialog
          open={isInfluenceError}
          title={translate('alerts.errors.genericTitle')}
          text={errorMessage}
          isConfirm
          onOk={() => this.confirmInfluenceNotSaved()}
        />
        <Modal open={isChangingView} onClose={() => ({})}>
          <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
            <Box maxWidth='50%'><CircularProgress disableShrink /></Box>
          </Box>
        </Modal>
      </>
    )
  }
}

PageContext.propTypes = {
  isInfluenceError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isChangingView: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withLocalize(PageContext))
