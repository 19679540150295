import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Box } from '@material-ui/core'

const CustomInputLabel = ({ shrink, children, id, required, error }) => (
  <InputLabel id={id} htmlFor='inputProps' required={required} error={error} shrink={shrink}>
    <Box component='span' fontWeight='fontWeightBold'>
      {children}
    </Box>
  </InputLabel>
)

CustomInputLabel.propTypes = {
  shrink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool
}

export default CustomInputLabel
