import React, { Component } from 'react'

import Layout from './layout'
import { Box, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

import { Link } from 'react-router-dom'
import { routes } from '../appSetting'

class LogoutView extends Component {
  render() {
    return (
      <Layout>
        <Box mt={2} mb={2}>
          <Typography variant='h1'><Translate id='view.logout.title' /></Typography>
        </Box>
        <Box><Translate id='view.logout.description' /></Box>
        <Box mt={2}>
          <Translate id='view.page404.goto' />
          &nbsp;
          <Link to={routes.welcome}>
            <Translate id='view.page404.homepage' />
          </Link>
        </Box>
      </Layout>
    )
  }
}

export default LogoutView
