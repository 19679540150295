import React from 'react'
import PropTypes from 'prop-types'

import { SingleSelect, newSelectItemByName, toValue } from '../Select'

import { connect } from 'react-redux'
import store from '../../store'
import { updateSelectors } from '../../store/actions/filters'
import { postdateSelector } from '../../store/selectors'
import { withLocalize } from 'react-localize-redux'

export const dateRangeLabelIds = {
 SHORT: 'dateRangeSelector.shortTerm',
 MEDUIM: 'dateRangeSelector.mediumTerm',
 LONG: 'dateRangeSelector.longterm'
}

const getDateRangeValues = translate => [
  newSelectItemByName(0, translate(dateRangeLabelIds.SHORT)),
  newSelectItemByName(1, translate(dateRangeLabelIds.MEDUIM)),
  newSelectItemByName(2, translate(dateRangeLabelIds.LONG))
]

const mapStateToProps = state => {
  const { lowRange, mediumRange, highRange } = postdateSelector(state)
  return ({ lowRange, mediumRange, highRange })
}
const checkDateRangeIndex = (termForDates) => {
  let indexOFDateRange = ''
    switch (termForDates) {
      case 0:
        indexOFDateRange = 'short'
        break
      case 1:
        indexOFDateRange = 'medium'
        break
      case 2:
        indexOFDateRange = 'long'
        break
      default:
        indexOFDateRange = 'short'
        break
    }
    return indexOFDateRange
  }
function DateRangeComponent({ dateRangeIndex, lowRange, mediumRange, highRange, translate }) {
  const ranges = [lowRange, mediumRange, highRange]
  const dateRangeValues = getDateRangeValues(translate)

  const setDate = (value) => {
    const index = dateRangeValues.map(toValue).indexOf(value)
    store.dispatch(updateSelectors({ DateRange: ranges[index], DateRangeIndex: index, Term: checkDateRangeIndex(index) }))
  }

  return (
    <SingleSelect
      elements={dateRangeValues}
      required
      titleId='selectorTitle'
      labelId='dateRangeSelector.title'
      updateValue={setDate}
      value={toValue(dateRangeValues[dateRangeIndex])}
    />
  )
}

DateRangeComponent.propTypes = {
  dateRangeIndex: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  mediumRange: PropTypes.object.isRequired,
  highRange: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export const DateRange = connect(mapStateToProps)(withLocalize(DateRangeComponent))
