import React from 'react'
import PropTypes from 'prop-types'

export const NetworkError = ({ url, status, statusText }) => (
  <>
    {
      url ? <div><span><b>Endpoint request URL:</b> </span>{url}</div> : <div />
    }
    {
      status ? <div><span><b>HTTP error code:</b> </span>{status}</div> : <div />
    }
    {
      statusText ? <div><span><b>HTTP error message:</b> </span>{statusText}</div> : <div />
    }
  </>
)

NetworkError.propTypes = {
  url: PropTypes.string,
  status: PropTypes.number,
  statusText: PropTypes.string
}

export default NetworkError
