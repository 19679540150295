import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { connect } from 'react-redux'
import store from '../../store'
import { updateSelectors } from '../../store/actions/filters'
import { userSettingsSelector, filterSelector, configurationSelector } from '../../store/selectors'

import { SingleSelect, newSelectItemByKey } from '../Select'
import { DateRange } from './dateRange'
import SubmitButton from './submitButton'

import _ from 'lodash'

import { isEmpty } from '../../businessRules/string'
import { isUndefinedOrNull } from '../../businessRules/compare'

const mapStateToProps = state => {
  const { ODs } = userSettingsSelector(state)
  const { selectors } = filterSelector(state)
  const { pathTypesSelectItem } = configurationSelector(state)

  return ({ ODs, pathTypesSelectItem, selectors })
}

class TableSelector extends Component {
  state = {
    isSubmitClicked: false
  }

  render() {
    const { isSubmitClicked } = this.state
    const { ODs, pathTypesSelectItem, selectors, onClickSubmit, CancelButton, flexDirection } = this.props

    const setSelectors = value => store.dispatch(updateSelectors(value))
    const setSubmitClicked = () => this.setState({ isSubmitClicked: true })
    const onSubmitSucessed = () => { if (onClickSubmit) onClickSubmit() }

    const isSubmitValid = !(
      isEmpty(selectors.Origin) ||
      isEmpty(selectors.Destination) ||
      isEmpty(selectors.POS) ||
      isEmpty(selectors.Cabin) ||
      isEmpty(selectors.PathGroup) ||
      isUndefinedOrNull(selectors.DateRangeIndex)
    )

    return (
      <Box display='flex' flexDirection={flexDirection ?? 'column'}>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justify='flex-start'
          marginBottom={2}
        >
          <Box mr={1}>
            <SingleSelect
              elements={_.chain(ODs).map(OD => OD.origin).uniq().sortBy().map(newSelectItemByKey).value()}
              required
              error={isSubmitClicked && isEmpty(selectors.Origin)}
              titleId='selectorTitle'
              labelId='tableSelector.selectors.origin'
              updateValue={Origin => setSelectors({ Origin, Destination: undefined, POS: undefined })}
              value={selectors.Origin}
            />
          </Box>
          <Box mr={1}>
            <SingleSelect
              elements={_.chain(ODs).filter(OD => OD.origin === selectors.Origin).map(OD => OD.destination).uniq().sortBy().map(newSelectItemByKey).value()}
              required
              error={isSubmitClicked && isEmpty(selectors.Destination)}
              titleId='selectorTitle'
              labelId='tableSelector.selectors.destination'
              updateValue={Destination => setSelectors({ Destination, POS: undefined })}
              value={selectors.Destination}
            />
          </Box>
          <Box mr={1}>
            <SingleSelect
              elements={_.chain(ODs).filter(OD => OD.origin === selectors.Origin && OD.destination === selectors.Destination).map(OD => OD.POS).uniq().map(newSelectItemByKey).value()}
              required
              error={isSubmitClicked && isEmpty(selectors.POS)}
              titleId='selectorTitle'
              labelId='tableSelector.selectors.pos'
              updateValue={POS => setSelectors({ POS })}
              value={selectors.POS}
            />
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='stretch'
          justify='flex-start'
          marginBottom={2}
        >
          <Box mr={1}>
            <SingleSelect
              elements={(
                _.chain(ODs)
                  .filter(OD => OD.origin === selectors.Origin && OD.destination === selectors.Destination && OD.POS === selectors.POS)
                  .map(OD => OD.cabin)
                  .uniq()
                  .map(newSelectItemByKey)
                  .value()
              )}
              required
              error={isSubmitClicked && isEmpty(selectors.Cabin)}
              titleId='selectorTitle'
              labelId='tableSelector.selectors.cabin'
              updateValue={Cabin => setSelectors({ Cabin })}
              value={selectors.Cabin}
            />
          </Box>
          <Box mr={1}>
            <SingleSelect
              elements={pathTypesSelectItem}
              required
              error={isSubmitClicked && isEmpty(selectors.PathGroup)}
              titleId='selectorTitle'
              labelId='tableSelector.selectors.pathGroup'
              updateValue={PathGroup => setSelectors({ PathGroup })}
              value={selectors.PathGroup}
            />
          </Box>
          <Box mr={1}>
            <DateRange dateRangeIndex={selectors.DateRangeIndex} error={isSubmitClicked && isUndefinedOrNull(selectors.DateRangeIndex)} />
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='stretch'
          justify='flex-start'
          marginBottom={2}
        >
          <SubmitButton onClick={setSubmitClicked} onSucess={onSubmitSucessed} isValid={isSubmitValid} />
          <Box ml={1}><CancelButton /></Box>
        </Box>
      </Box>
    )
  }
}

TableSelector.propTypes = {
  ODs: PropTypes.array.isRequired,
  pathTypesSelectItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectors: PropTypes.object.isRequired,
  CancelButton: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func,
  flexDirection: PropTypes.oneOf(['column', 'row'])
}

export default connect(mapStateToProps)(TableSelector)
