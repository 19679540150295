import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'

const style = _theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

const StyledCircularProgress = withStyles(style)(CircularProgress)

export const loadingSizes = {
  small: 24,
  big: 50
}

export const CreateLoadingElement = Element => {
  function LoadingElement({ children, isLoading, size, ...props }) {
    return (
      <Element disabled={isLoading} {...props}>
        {children}
        {isLoading && <StyledCircularProgress size={size ?? loadingSizes.small} />}
      </Element>
    )
  }

  LoadingElement.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool.isRequired,
    size: PropTypes.oneOf([loadingSizes.small, loadingSizes.big])
  }

  return LoadingElement
}
