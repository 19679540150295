import { Collapse, Box, Fade } from '@material-ui/core'
import React from 'react'
import ButtonLink from '../../component/buttonLink'
import { Search, sizes } from '../../component/icons'
import PageHeader, { pageHeaderTopBottomPadding } from '../../component/PageHeader/pageHeader'
import Title from '../../component/title/title'
import Layout from '../layout'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { userSettingsSelector, portfolioSelector, configurationSelector, postdateSelector } from '../../store/selectors'
import { createNewFullPaddedButton } from '../../component/buttons/defaultButton'
import { LoadingButton } from '../../component/buttons/loadingButton'
import { Translate } from 'react-localize-redux'
import { SingleSelect, MultiSelect, newSelectItem } from '../../component/Select'
import _ from 'lodash'
import { isEmpty } from '../../businessRules/string'
import store from '../../store'
import { updatePortfolioSelectors, getPortfolioSingleAndOD } from '../../store/actions/portfolio'
import { joinList, isArrayEmpty } from '../../businessRules/array'

import { getUserName } from '../../authentication/authentication'
import { dateRangeDateToUnix } from '../../businessRules/date'

const CancelButton = createNewFullPaddedButton({ t: 0.75, r: 6, b: 0.75, l: 6 }, '190px')
const SearchButton = LoadingButton(createNewFullPaddedButton({ t: 0.75, r: 6, b: 0.75, l: 6 }, '190px'))

const mapStateToProps = state => ({
  demandManagersRegionsCabins: userSettingsSelector(state).demandManagersRegionsCabins,
  selectors: portfolioSelector(state).selectors,
  haveSelectors: portfolioSelector(state).haveSelectors,
  regions: configurationSelector(state).regions,
  cabins: configurationSelector(state).cabins,
  odIsLoading: portfolioSelector(state).odIsLoading,
  singleIsLoading: portfolioSelector(state).singleIsLoading,
  postdate: postdateSelector(state)
})

class Header extends React.Component {
  state = {
    isHeaderOpen: true,
    isSearchClicked: false
  }

  componentDidMount() {
    const { postdate, haveSelectors, demandManagersRegionsCabins } = this.props
    let newSelectors = {}

    if (!haveSelectors) {
      const username = getUserName().toUpperCase()
      let currentUser = _.chain(demandManagersRegionsCabins).filter(p => {
        return p.email.toUpperCase() === username
      }).first().value()
      if (!currentUser) {
        currentUser = _.chain(demandManagersRegionsCabins).first().value()
      }
      const regionIds = _.chain(currentUser.regions).map(r => r.regionId).value()
      const regionName = _.chain(currentUser.regions).map(r => r.regionDes).value()
      const cabinIds = _.chain(currentUser.cabins).map(r => r.cabinId).value()
      const cabinNames = _.chain(currentUser.cabins).map(r => r.cabinName).value()

      newSelectors = {
        DateRange: dateRangeDateToUnix(postdate.lowRange),
        userId: currentUser.userId,
        email: currentUser.email,
        regionIds,
        regionName,
        cabinIds,
        cabins: cabinNames
      }
      //  disable auto load for portfolio
      store.dispatch(updatePortfolioSelectors(newSelectors))
      // store.dispatch(getPortfolioSingleAndOD(haveSelectors ? selectors : newSelectors, postdate.postdate))
    }
  }

  setHeaderOpen() {
    this.setState({ isHeaderOpen: true })
  }

  setHeaderClose() {
    this.setState({ isHeaderOpen: false })
  }

  render() {
    const { isHeaderOpen, isSearchClicked } = this.state
    const { flexDirection, selectors, regions, cabins, odIsLoading, singleIsLoading, postdate, demandManagersRegionsCabins } = this.props

    const open = () => this.setHeaderOpen()
    const close = () => this.setHeaderClose()

    const setSelectors = value => store.dispatch(updatePortfolioSelectors(value))

    const setUserId = (email) => _.chain(demandManagersRegionsCabins).find(user => {
      return user.email === email
    }).value().userId

    const setRegionId = (region) => {
      const newRegion = _.chain(region).map(r => {
        const newR = _.find(regions, rs => {
          return rs.description.toUpperCase() === r.toUpperCase()
        })
        return newR.id
      }).value()
      return newRegion
    }
    const setCabinId = (cabin) => {
      const newCabin = _.chain(cabin).map(c => {
        const newC = _.find(cabins, cs => {
          return cs.value === c
        })
        return newC.id
      }).value()
      return newCabin
    }

    const isSearchValid = !(
      isEmpty(selectors.email) ||
      isEmpty(selectors.userId) ||
      isArrayEmpty(selectors.regionName) ||
      isArrayEmpty(selectors.regionIds) ||
      isArrayEmpty(selectors.cabins) ||
      isArrayEmpty(selectors.cabinIds)
    )

    const onSearch = () => {
      this.setState({ isSearchClicked: true })
      if (isSearchValid) {
        store.dispatch(getPortfolioSingleAndOD(selectors, postdate.postdate))
        this.setHeaderClose()
      }
    }

    return (
      <PageHeader pb={0}>
        <Layout>
          <Box display='flex'>
            <Box pb={pageHeaderTopBottomPadding}>
              <Title labelId='view.portfolio.title'>
                <Fade in={!isHeaderOpen}>
                  <div>
                    <ButtonLink labelId='view.portfolio.searchNewButton' onClick={open} endIcon={<Search size={sizes.small} />} />
                  </div>
                </Fade>
              </Title>
              <Collapse in={isHeaderOpen}>
                <Box display='flex' flexDirection={flexDirection ?? 'column'}>
                  <Box display='flex' flexDirection='row' alignItems='center' justify='flex-start' marginBottom={2}>
                    <Box ml={1}>
                      <SingleSelect
                        elements={_.chain(demandManagersRegionsCabins).map(p => newSelectItem(p.userId, p.email, p.email)).value()}
                        required
                        error={isSearchClicked && isEmpty(selectors.email)}
                        titleId='selectorTitle'
                        labelId='tableSelector.selectors.portfolio'
                        updateValue={email => {
                          const newEmail = setSelectors({ email, userId: setUserId(email), regionIds: [], regionName: [], cabinIds: [], cabins: [] })
                          return newEmail
                        }}
                        value={selectors.email}
                      />
                    </Box>
                    <Box ml={1}>
                      <MultiSelect
                        elements={_.chain(demandManagersRegionsCabins)
                          .filter(p => p.email === selectors.email)
                          .first().get('regions')
                          .map(r => {
                            const newRegionName = newSelectItem(r.regionId, r.regionDes, r.regionDes)
                            return newRegionName
                          }
                          ).value()}
                        required
                        fullWidth
                        error={isSearchClicked && isArrayEmpty(selectors.regionName)}
                        titleId='selectorTitle'
                        labelId='tableSelector.selectors.region'
                        updateValues={regionName => {
                          const newRegionName = setSelectors({ regionName, regionIds: setRegionId(regionName), cabinIds: [], cabins: [] })
                          return newRegionName
                        }}
                        values={selectors.regionName}
                        renderValue={joinList}
                      />
                    </Box>
                    <Box ml={1}>
                      <MultiSelect
                        elements={
                          _.chain(demandManagersRegionsCabins).filter(p => p.email === selectors.email).first().get('cabins').map(c => newSelectItem(c.cabinId, c.cabinName, c.cabinName)).value()
                        }
                        required fullWidth error={isSearchClicked && isArrayEmpty(selectors.cabins)} titleId='selectorTitle' labelId='tableSelector.selectors.cabin'
                        updateValues={cabins => setSelectors({ cabins, cabinIds: setCabinId(cabins) })} values={selectors.cabins} renderValue={joinList}
                      />
                    </Box>
                    <Box ml={1}>
                      <SearchButton variant='contained' color='primary' onClick={onSearch} isLoading={odIsLoading || singleIsLoading}>
                        <Translate id='view.portfolio.search' />
                      </SearchButton>
                    </Box>
                    <Box ml={1}>
                      <CancelButton variant='contained' onClick={close}>
                        <Translate id='view.portfolio.cancel' />
                      </CancelButton>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Layout>
      </PageHeader>
    )
  }
}

Header.propTypes = {
  demandManagersRegionsCabins: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  haveSelectors: PropTypes.bool.isRequired,
  regions: PropTypes.array.isRequired,
  cabins: PropTypes.array.isRequired,
  odIsLoading: PropTypes.bool.isRequired,
  singleIsLoading: PropTypes.bool.isRequired,
  postdate: PropTypes.object,
  flexDirection: PropTypes.any
}

export default connect(mapStateToProps)(withRouter(Header))
