import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../store'
import { changeColumnHidden } from '../store/actions/tableState/tableState'
import TableHeadCell from '../component/table/tableHeadCell'
import checkboxList from '../util/checkboxList'

export default function createTableHeadCell(tableStateSelector, view, onChangeOrdering) {
  const mapStateToProps = state => {
    const { order, orderBy, columnHidden } = tableStateSelector(state)
    return ({ order, orderBy, columnHidden })
  }

  const TableHeadCellWrapper = ({ index, column, order, orderBy, columnHidden }) => {
    const columnCheckboxList = checkboxList(columnHidden, v => store.dispatch(changeColumnHidden(view)(v)))
    return <TableHeadCell index={index} column={column} order={order} orderBy={orderBy} changeOrdering={onChangeOrdering} deleteColumn={id => columnCheckboxList.handleClick(id)} />
  }

  TableHeadCellWrapper.propTypes = {
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    order: PropTypes.oneOf(['asc', 'desc', '']),
    orderBy: PropTypes.string,
    columnHidden: PropTypes.array.isRequired
  }

  return connect(mapStateToProps)(TableHeadCellWrapper)
}
