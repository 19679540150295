export const columns = [
  { order: 1, id: 'origin', labelId: 'view.commercialInfluence.origin' },
  { order: 2, id: 'destination', labelId: 'view.commercialInfluence.destination' },
  { order: 3, id: 'pos', labelId: 'view.commercialInfluence.pos' },
  { order: 4, id: 'flightDateRangeFormatted', labelId: 'view.commercialInfluence.flightDateRange' },
  { order: 5, id: 'cabinFormatted', labelId: 'view.commercialInfluence.cabin' },
  { order: 6, id: 'seatClassFormatted', labelId: 'view.commercialInfluence.classes' },
  { order: 7, id: 'effectiveDateRangeFormatted', labelId: 'view.commercialInfluence.effectiveDateRange' },
  { order: 8, id: 'decay', labelId: 'view.commercialInfluence.decay' },
  { order: 9, id: 'decayRangeFormatted', labelId: 'view.commercialInfluence.decayRange' },
  { order: 10, id: 'dowOrderedFormatted', labelId: 'view.commercialInfluence.dow' },
  { order: 11, id: 'todOrderedFormatted', labelId: 'view.commercialInfluence.tod' },
  { order: 12, id: 'pathTypeFormatted', labelId: 'view.commercialInfluence.pathGroup' },
  { order: 13, id: 'influence', labelId: 'view.commercialInfluence.influence' },
  { order: 14, id: 'description', labelId: 'view.commercialInfluence.description' },
  { order: 15, id: 'Action', labelId: 'view.commercialInfluence.action', type: 'action' }
]
