import React from 'react'
import Header from './header'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { portfolioSelector } from '../../store/selectors'
import Layout from '../layout'
import ODOptimizationTable from './odOptimization'
import SingleOptimizationTable from './singleOptimization'
import { Grid } from '@material-ui/core'
import { saga } from '../../businessRules/saga'
import { changeView, changingView } from '../../store/actions/views'
import { viewSelectorEnum } from '../../appSetting'
import store from '../../store'
import './index.css'

const mapStateToProps = state => ({
  portfolio: portfolioSelector(state)
})

class Portfolio extends React.Component {
  componentDidMount() {
    // set the DemandForcast tab view to Optimizer
    store.dispatch(saga([changingView(true), changeView(viewSelectorEnum.Priority), changingView(false)]))
  }

  render() {
    const { portfoliosOD } = this.props.portfolio
    const { portfoliosSingle } = this.props.portfolio
    return (
      <>
        <Header />
        <Layout mt={1}>
          <Grid className='portfolio-tables-styles' container spacing={3}>
            <Grid item xs={5}>
              <ODOptimizationTable rows={portfoliosOD} />
            </Grid>
            <Grid item xs={7}>
              <SingleOptimizationTable rows={portfoliosSingle} />
            </Grid>
          </Grid>
        </Layout>
      </>
    )
  }
}

Portfolio.propTypes = {
  portfolio: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(Portfolio)
