import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import {
  usersSelector,
  tableUsersSelector,
  usersSelectorForTableBody
} from '../../store/selectors'

import TableTitle from '../../component/table/tableTitle'
import EnhancedTable from '../../component/table/table'
import { users as usersView, changeOrdering } from '../../store/actions/tableState/tableState'
import { ActionsWithRouter } from './tableActions'
import createTableHeadCell from '../../event/tableHeadCell'
import createTableHead from '../../event/tableHead'
import createTableBody from '../../event/tableBody'
import createTableFooter from '../../event/tableFooter'
import createDataValid from '../../component/table/tableBody/dataValid'
import createTableToolbar from '../../component/tableToolbar/tableToolbar'

import { columns } from './tableSettings'
import EditRow from './editRow'

const EnhancedTableToolbar = createTableToolbar(tableUsersSelector)

const onChangeOrdering = (newOrder, newOrderBy) => store.dispatch(changeOrdering(usersView)(newOrder, newOrderBy))

const TableHeadCell = createTableHeadCell(tableUsersSelector, usersView, onChangeOrdering)
const EnhancedTableHead = createTableHead(tableUsersSelector, usersView, TableHeadCell)
const DataValid = createDataValid(ActionsWithRouter, undefined, undefined, EditRow)
const EnhancedTableBody = createTableBody(usersSelectorForTableBody, tableUsersSelector, DataValid)
const EnhancedTableFooter = createTableFooter(tableUsersSelector, usersView)

const mapStateToProps = state => ({ users: usersSelector(state).users })

const Table = ({ users }) => (
  <>
    <TableTitle labelId='view.users.subtitle' />
    <EnhancedTableToolbar columns={columns} view={usersView} />
    <EnhancedTable>
      <EnhancedTableHead />
      <EnhancedTableBody rows={users} />
      <EnhancedTableFooter numberRows={users.length} />
    </EnhancedTable>
  </>
)

Table.propTypes = { users: PropTypes.array.isRequired }

export default connect(mapStateToProps)(Table)
