const getColumnBy = refKey => row => row[refKey]

export const getFiltersValue = (filters, rows) => {
  filters.forEach(filter => {
    filter.elements = filter.groupBy(getFilterValue(filter, rows))
  })

  return filters
}

export const getFilterValue = ({ refKey, orderBy, distinct }, rows) => distinct(rows.map(getColumnBy(refKey))).sort(orderBy)
