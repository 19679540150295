import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import reducer from './reducers'
import fetchMiddleware from './fetchMiddleware'
import localStorageMiddleware from './localStorageMiddleware'
import { analyticsMiddleware } from '../logging/analytics'

const store = createStore(
  reducer,
  applyMiddleware(
    thunkMiddleware,
    fetchMiddleware(fetch),
    localStorageMiddleware,
    analyticsMiddleware,
    createLogger() // logger must be the last middleware in chain
  )
)

export default store
