import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

import { Translate } from 'react-localize-redux'
import { Link } from 'react-router-dom'

const style = theme => ({
  link: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold
    }
  }
})

const HeaderLink = ({ route, labelId, classes }) =>
  <Link to={route} className={classes.link}>
    <Translate id={labelId} />
  </Link>

HeaderLink.propTypes = {
  route: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(HeaderLink)
