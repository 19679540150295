import { DepartureDateId } from '../views/forecasting/review/settingsForecasting'
import { empty, isFiltered, existIn } from './array'
import { isInDateRange, numberOfDayBetween } from './date'
import { isUndefinedOrNull } from './compare'

export const filterRows = filtersValue => row => {
  const filterKeys = Object.keys(filtersValue)

  const isDateInRange = key => isInDateRange(filtersValue[key][0], row[DepartureDateId])
  const isRowContainedInFilters = key => existIn(filtersValue[key])(row[key])

  return filterKeys.every(key => (key === 'DateRange' && isDateInRange(key)) || (key !== 'DateRange' && isRowContainedInFilters(key)))
}

export const executeCommercialBias = (commercialInfluences, postdate) => row => {
  const { rmPrediction, hasCommercialInfluences } = evaluateAIWithCommercialInfluences(row, commercialInfluences, postdate)
  row.rmPrediction = rmPrediction
  row.hasCommercialInfluences = hasCommercialInfluences

  return row
}

export const aggregateRow = ({ rmPrediction, proPredictionLiveInfluenced }) => (rmPrediction - proPredictionLiveInfluenced)
export const aggregateRowWithFare = ({ rmPrediction, proPredictionLiveInfluenced, fare }) => (rmPrediction - proPredictionLiveInfluenced) * fare

const sameAsPendingRow = row => influence =>
  influence.trip_Origin === row.origin &&
  influence.trip_Destination === row.destination &&
  influence.pos === row.POS &&
  isFiltered(influence.Class, row.Class) &&
  isFiltered(influence.ToD, row.ToD) &&
  isFiltered(influence.DoW, row.DoW) &&
  influence.DepartureDate.some(departureDate => isInDateRange(departureDate, row.DepartureDate))

const sameAsPendingRow2 = row => influence =>
  influence.origin === row.origin &&
  influence.destination === row.destination &&
  influence.pos === row.POS &&
  existIn(influence.seatClass)(row.Class) &&
  existIn(influence.tod)(row.ToD) &&
  existIn(influence.dow)(row.DoW) &&
  isInDateRange(influence.flightDateRange, row.DepartureDate)

const calculateDecayRatio = (date, { startDate, endDate }) => numberOfDayBetween(date, endDate) / numberOfDayBetween(startDate, endDate)
const effectiveDecay = (date, influence) => isInDateRange(influence.effectiveDateRange, date) ? influence.influence : 1

const calculateInfluenceValueWithDecay = date => influence =>
  (influence.decay !== 'None' && isInDateRange(influence.decayRange, date)) ? (calculateDecayRatio(date, influence.decayRange) * (influence.influence - 1)) + 1 : effectiveDecay(date, influence)

const calculateRunningInfluence = (sameAsPendingRow, toInfluenceValue, row, influences) => influences
  .filter(empty)
  .filter(sameAsPendingRow(row))
  .map(toInfluenceValue)
  .reduce((total, influence) => influence * total, 1)

const toInfluenceValue = influence => influence.influence

export function evaluateHSEWithPendingInfluences(row, pendingInfluences, _liveInfluence) {
  const influences = calculateRunningInfluence(sameAsPendingRow, toInfluenceValue, row, pendingInfluences)

  const result = row.proPredictionInfluenced * influences

  return (isNaN(result) || !result) ? row.proPredictionInfluenced : result
}

export function evaluateHSEWithLiveInfluences(row, _pendingInfluences, liveInfluence) {
  const influences = calculateRunningInfluence(sameAsPendingRow, toInfluenceValue, row, [liveInfluence])

  const result = row.proPredictionPendingInfluenced * influences

  return (isNaN(result) || !result) ? row.proPredictionPendingInfluenced : result
}

export function evaluateAIWithCommercialInfluences(row, commercialInfluences, postdate) {
  const influences = calculateRunningInfluence(sameAsPendingRow2, calculateInfluenceValueWithDecay(postdate), row, commercialInfluences)

  const result = row.rmPrediction * influences
  const hasCommercialInfluences = influences !== 1 && !isNaN(influences) && !isUndefinedOrNull(result)
  const rmPrediction = hasCommercialInfluences ? result : row.rmPrediction

  return ({ rmPrediction, hasCommercialInfluences })
}
