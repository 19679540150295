import {
  OpenCommercialInfluence,
  CommercialInfluenceDone,
  ModifyCommercialInfluence,
  NewConfiguration,
  NewPostDate
} from '../../actions/newCommercialInfluence'

import { DefaultInfluence, DefaultDecay } from '../../../appSetting'

import moment from 'moment'
import { distinct } from '../../../businessRules/array'
import { toSeatClass } from '../../../businessRules/object'
import { utcToMoment } from '../../../businessRules/date'

const nextYearRange = () => ({ startDate: moment(), endDate: moment().add(1, 'y') })

const DefaultCommercialInfluence = {
  influence: DefaultInfluence,
  description: '',
  origin: '',
  destination: '',
  pos: '',
  cabin: [],
  seatClass: [],
  effectiveDateRange: nextYearRange(),
  decay: DefaultDecay,
  decayRange: nextYearRange(),
  flightDateRange: nextYearRange(),
  dow: [],
  tod: [],
  pathType: []
}

const initialState = {
  nextYearRange: nextYearRange(),
  defaultCommercialInfluence: DefaultCommercialInfluence,
  newInfluence: DefaultCommercialInfluence,
  isNewCommercialInfluenceOpen: false
}

export default (state = initialState, action) => {
  const { type, data } = action
  const assignState = newState => Object.assign({}, state, newState)

  switch (type) {
    case OpenCommercialInfluence:
      return assignState({ isNewCommercialInfluenceOpen: true })
    case CommercialInfluenceDone:
      return assignState({ newInfluence: state.defaultCommercialInfluence, isNewCommercialInfluenceOpen: false })
    case ModifyCommercialInfluence:
      return assignState({ newInfluence: Object.assign({}, state.newInfluence, { ...data }) })
    case NewConfiguration: {
      const cabin = distinct(action.classGroup.map(x => x.cabin))
      const seatClass = action.classGroup.map(toSeatClass)

      const newConfig = {
        cabin: cabin ?? DefaultCommercialInfluence.cabin,
        seatClass: seatClass ?? DefaultCommercialInfluence.seatClass,
        dow: action.dow ?? DefaultCommercialInfluence.dow,
        tod: action.tod ?? DefaultCommercialInfluence.tod,
        pathType: action.pathType ?? DefaultCommercialInfluence.pathType
      }

      return assignState({
        defaultCommercialInfluence: Object.assign({}, state.defaultCommercialInfluence, newConfig),
        newInfluence: Object.assign({}, state.newInfluence, newConfig)
      })
    }
    case NewPostDate: {
      const { postdate } = action.data
      const nextYearRange = { startDate: utcToMoment(postdate), endDate: utcToMoment(postdate).add(1, 'y') }

      return assignState({
        nextYearRange,
        defaultCommercialInfluence: Object.assign({}, state.defaultCommercialInfluence, { flightDateRange: nextYearRange, decayRange: nextYearRange, effectiveDateRange: nextYearRange }),
        newInfluence: Object.assign({}, state.newInfluence, { flightDateRange: nextYearRange, decayRange: nextYearRange, effectiveDateRange: nextYearRange })
      })
    }
    default:
      return state
  }
}
