const canUndo = function() {
  return this.past.length > 0
}

const canRedo = function() {
  return this.future.length > 0
}

const undo = function() {
  const past = this.canUndo() ? [...this.past.slice(1)] : []
  const future = this.canUndo() ? [this.present, ...this.future] : this.future

  return ({
    add,
    undo,
    redo,
    canUndo,
    canRedo,
    past,
    present: this.past[0] ?? this.present,
    future
  })
}

const redo = function() {
  const past = this.canRedo() ? [this.present, ...this.past] : this.past
  const future = this.canRedo() ? [...this.future.slice(1)] : []

  return ({
    add,
    undo,
    redo,
    canUndo,
    canRedo,
    past,
    present: this.future[0] ?? this.present,
    future
  })
}

const add = function(value) {
  return ({
    add,
    undo,
    redo,
    canUndo,
    canRedo,
    past: [this.present, ...this.past],
    present: value,
    future: []
  })
}

export const createUndoRedo = currentState => ({
  add,
  undo,
  redo,
  canUndo,
  canRedo,
  past: [],
  present: currentState,
  future: []
})
