import { UpdateRows, CalculatingRows } from '../../actions/rows'

const initialState = {
  rows: [],
  calculatedDemandeForecast2: [],
  isCalculating: false
}

export default (state = initialState, action) => {
  const { type, rows, calculatedDemandeForecast2, isCalculating } = action

  switch (type) {
    case UpdateRows: {
      return Object.assign({}, state, { rows, calculatedDemandeForecast2, isCalculating })
    }
    case CalculatingRows: {
      return Object.assign({}, state, { isCalculating })
    }
    default:
      return state
  }
}
