import { createTheme } from '@material-ui/core/styles'

const black = '#2a2a2a'
const grey = '#97999C'
const lightGrey = '#d8d8d8'
// check
export default createTheme({
  palette: {
    primary: {
      main: '#00778F'
    },
    secondary: {
      main: '#424242'
    },
    tiertiary: {
      main: '#FFFFFF'
    },
    buttons: {
      light: '#E3E3E3',
      main: '#d8d8d8',
      dark: '#C2C2C2',
      constrastText: '#4A4F55'
    },
    bgColor: {
      'color-neutral-95': '#F9F9F9',
      'color-white': '#FFFFFF'
    },
    background: {
      paper: '#fff',
      default: '#FFFFFF'
    },
    brand: {
      '01': '#D8292F',
      '02': '#000000',
      '03': '#4A4F55',
      '04': '#BAA88D',
      '05': '#00778F'
    },
    backgroundelement: '#d8d8d8',
    borderElement: '#d8d8d8'
  },
  grey,
  icon: {
    base: grey,
    baseOnWhite: lightGrey,
    hover: black,
    info: '#00778F'
  },
  typography: {
    fontFamily: 'Open Sans', // For changing plotly font-family for the heatmap you need to change ploty.css
    fontWeightBold: 600,
    h1: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '32px',
      color: black
    },
    h2: {
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: '24px',
      color: black
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      color: grey,
      fontSize: '18px'
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontSize: '24px',
      color: '#747985'
    },
    subtitle3: {
      color: grey,
      fontSize: '12px',
      fontStyle: 'italic'
    },
    subtitle4: {
      fontFamily: 'Open Sans',
      color: grey,
      fontSize: '14px'
    },
    link: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      color: '#FFFFFF'
    }
  }
})
