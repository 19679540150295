import { mergefirstLast, dateRangeUnixToMoment, formatDateRange, formatDoW, formatStringAsList, formatToD, dateRangeDateToUnix } from '../../../businessRules/date'
import { joinListWithFormat, joinList, sortDoW } from '../../../businessRules/array'

export const toUIInfluence = ({ DepartureDate, ...influence }) => {
  const departureDatesMoment = DepartureDate.map(dateRangeUnixToMoment)
  const dowOrdered = influence.DoW.sort(sortDoW)
  const todOrdered = influence.ToD.sort()
  const classOrdered = influence.Class.sort()

  return ({
    departureDateRangesFormatted: formatDateRange(mergefirstLast(departureDatesMoment)),
    dowFormatted: joinListWithFormat(formatDoW)(dowOrdered),
    todFormatted: joinListWithFormat(formatStringAsList(formatToD))(todOrdered),
    classFormatted: joinListWithFormat(formatStringAsList(joinList))(classOrdered),
    id: influence.id,
    status: influence.status,
    trip_Origin: influence.trip_Origin,
    trip_Destination: influence.trip_Destination,
    pos: influence.pos,
    cabin: influence.cabin,
    dateRangeIndex: influence.dateRangeIndex,
    dateRange: influence.dateRange,
    DepartureDate: departureDatesMoment,
    DoW: influence.DoW,
    ToD: influence.ToD,
    Class: influence.Class,
    influence: influence.influence,
    description: influence.description,
    todDesc: influence.todDesc,
    PathGroup: influence.PathGroup,
    influenceString: influence.influenceString
  })
}

export const toInfluence = ({ DepartureDate, ...influence }) => {
  const departureDate = DepartureDate.map(dateRangeDateToUnix)

  return {
    DepartureDate: departureDate,
    ...influence
  }
}
