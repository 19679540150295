import uuidv4 from 'uuid/v4'

export const Fetch = 'fetch'
export const fetch = (url, params) => ({
  type: Fetch,
  correlationId: uuidv4(),
  url,
  params
})

export const FetchPost = 'fetchPost'
export const fetchPost = (url, data) => ({
  type: FetchPost,
  correlationId: uuidv4(),
  url,
  data
})

export const FetchPut = 'fetchPut'
export const fetchPut = (url, data) => ({
  type: FetchPut,
  correlationId: uuidv4(),
  url: `${url}/${data.id}`,
  data
})

export const FetchDelete = 'fetchDelete'
export const fetchDelete = (url, id) => ({
  type: FetchDelete,
  correlationId: uuidv4(),
  url: `${url}/${id}`
})
