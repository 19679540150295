import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const TableTitle = ({ labelId, children }) =>
  <Box mt={4} display='flex'><Typography variant='h2'><Translate id={labelId} /></Typography>{children}</Box>

TableTitle.propTypes = { labelId: PropTypes.string.isRequired, children: PropTypes.node }

export default TableTitle
