import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { portfolioSelector, postdateSelector, userSettingsSelector } from '../../../store/selectors'
import { ActionsOptimizationWithRouter, onClickLoad } from './tableActions'
import { Translate } from 'react-localize-redux'
import store from './../../../../../ui/src/store/index'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import TableTitle from '../../../component/table/tableTitle'
import PostdateSubTitle from '../../postdateSubTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { formatDecimalNumber } from '../../../businessRules/number'
import { withRouter } from 'react-router-dom'
import { formatDoW, formatStringAsList, formatToD } from '../../../businessRules/date'
import { joinList, joinListWithFormat } from '../../../businessRules/array'
import { setSingleOptimizationStatus } from '../../../store/actions/portfolio'

const mapStateToProps = state => ({
  singleIsLoading: portfolioSelector(state).singleIsLoading,
  ODs: userSettingsSelector(state).ODs,
  postdate: postdateSelector(state).postdate,
  lowRange: postdateSelector(state).lowRange,
  mediumRange: postdateSelector(state).mediumRange,
  highRange: postdateSelector(state).highRange
})

const headCells = [
  { order: 0, id: 'status', labelId: 'view.portfolio.singleOptimizerTable.statusTitle', type: 'info', width: '5%' },
  { order: 1, id: 'origin', labelId: 'view.portfolio.singleOptimizerTable.od', width: '' },
  { order: 2, id: 'pos', labelId: 'view.portfolio.singleOptimizerTable.pos', width: '' },
  { order: 3, id: 'pathType', labelId: 'view.portfolio.singleOptimizerTable.pathType', width: '' },
  { order: 4, id: 'depDatePerisingle', labelId: 'view.portfolio.singleOptimizerTable.depDate', width: '' },
  { order: 5, id: 'dow', labelId: 'view.portfolio.singleOptimizerTable.dow', width: '' },
  { order: 6, id: 'tod', labelId: 'view.portfolio.singleOptimizerTable.tod', width: '' },
  { order: 7, id: 'class', labelId: 'view.portfolio.singleOptimizerTable.class', width: '' },
  { order: 8, id: 'singleVolume', labelId: 'view.portfolio.singleOptimizerTable.volume', type: 'number', toFixed: 0, width: '' },
  { order: 9, id: 'influence', labelId: 'view.portfolio.singleOptimizerTable.influence', type: 'number', toFixed: 2, width: '' },
  { order: 10, id: 'action', labelId: 'view.portfolio.singleOptimizerTable.action', type: 'action', width: '' }
]

function EnhancedTableHead(props) {
  const { classes } = props

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.order === 0 ? 'center' : 'left'}
            className={headCell.order === 0 ? classes.doneCell : ''}
            style={{ fontWeight: 'bold', width: headCell.width }}
          >
            {headCell.labelId ? <Translate id={headCell.labelId} /> : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired
}

const tablePaginationStyle = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}))

const TablePaginationActions = (props) => {
  const classes = tablePaginationStyle()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  table: {
    marginTop: theme.spacing(4),
    minWidth: 480
    // maxWidth: 650
  },
  doneCell: {
    background: 'rgba(0, 119, 143,0.1)'
  },
  selected: {
    background: '#c8d7de'
  },
  doneCheckbox: {
    color: '#bdbdbd'
  }
}))

const SingleOptimizationTable = (props) => {
  const { rows, singleIsLoading, ODs, postdate, lowRange, history } = props
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)

  const isOptimizationDone = (row) => {
    return row.status === 'Read'
  }

  const handleClick = (event, name, row) => {
    const newStatus = isOptimizationDone(row) ? '' : 'Read'
    store.dispatch(setSingleOptimizationStatus({ ...row, status: newStatus }))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // this is to set page back to 0 for new search
  React.useEffect(() => { setPage(0) }, [singleIsLoading])

  return (
    <div className={classes.root}>
      <TableTitle labelId='view.portfolio.singleOptimizerTable.title' />
      <PostdateSubTitle />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {singleIsLoading && (
              <TableRow>
                <TableCell colSpan={headCells.length}><LinearProgress color='primary' /></TableCell>
              </TableRow>
            )}
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <React.Fragment key={row.id}>
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      selected={isOptimizationDone(row)}
                    >
                      <TableCell padding='checkbox' className={isOptimizationDone(row) ? classes.selected : classes.doneCell} align='center'>
                        <Checkbox
                          className={classes.doneCheckbox}
                          color='primary'
                          checked={isOptimizationDone(row)}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.id, row)}
                        />
                      </TableCell>
                      <TableCell component='th' id={labelId} scope='row' align='left'>
                        {row.trip_Origin + '-' + row.trip_Destination}
                      </TableCell>
                      <TableCell align='left'>{row.pos}</TableCell>
                      <TableCell align='left'>{row.PathGroup}</TableCell>
                      <TableCell align='left'>{row.departureDateRangesFormatted}</TableCell>
                      <TableCell align='left'>{joinListWithFormat(formatDoW)(row.DoW)}</TableCell>
                      <TableCell align='left'>{joinListWithFormat(formatStringAsList(formatToD))(row.ToD)}</TableCell>
                      <TableCell align='left'>{joinListWithFormat(formatStringAsList(joinList))(row.Class)}</TableCell>
                      <TableCell align='left'>{formatDecimalNumber(row.volume, 0)}</TableCell>
                      <TableCell align='left'>{formatDecimalNumber(row.influence, 2)}</TableCell>
                      <TableCell onClick={() => onClickLoad(row, ODs, postdate, lowRange, history)} align='left'><ActionsOptimizationWithRouter row={row} /></TableCell>
                    </TableRow>
                  </React.Fragment>)
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  )
}

SingleOptimizationTable.propTypes = {
  rows: PropTypes.array,
  ODs: PropTypes.array.isRequired,
  postdate: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  singleIsLoading: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(withRouter(SingleOptimizationTable))
