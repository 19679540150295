import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TableHead, TableRow } from '@material-ui/core'

import DroppableComponent from '../DroppableComponent/DroppableComponent'

class EnhancedTableHead extends Component {
  render() {
    const { columnsOrdered, changeTableColumnOrder, TableHeadCell } = this.props
    const onDragEnd = ({ source, destination }) => destination ? changeTableColumnOrder(source.index, destination.index) : null

    return (
      <TableHead>
        <TableRow component={DroppableComponent('main-table-header', onDragEnd)}>
          {
            columnsOrdered.map((column, index) => <TableHeadCell key={column.id} index={index} column={column} />)
          }
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  columnsOrdered: PropTypes.array.isRequired,
  changeTableColumnOrder: PropTypes.func.isRequired,
  TableHeadCell: PropTypes.object.isRequired
}

export default EnhancedTableHead
