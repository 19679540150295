import { formatStringAsList, formatDoW, formatToD, formatDateDow, formatClass, toMinMaxDateRange, formatDateRange } from '../../../businessRules/date'
import { sortAlphabeticaly, sortDoW, distinct, joinListWithFormat } from '../../../businessRules/array'
import { evaluateHSEWithPendingInfluences, evaluateHSEWithLiveInfluences } from '../../../businessRules/rows'

export const columns = [
  { order: 0, id: 'departureDateFormatted', labelId: 'view.forecasting.table.departureDate' },
  { order: 1, id: 'dowFormatted', labelId: 'view.forecasting.table.DoW' },
  { order: 2, id: 'todFormatted', labelId: 'view.forecasting.table.ToD' },
  { order: 3, id: 'classFormatted', labelId: 'view.forecasting.table.Class' },
  { order: 4, id: 'LYCumBkd', labelId: 'view.forecasting.table.lastYearUnconstrainedBooking', type: 'number', toFixed: 0 },
  { order: 5, id: 'cumBkd', labelId: 'view.forecasting.table.UnconstrainedBooking', type: 'number', toFixed: 0 },
  { order: 6, id: 'LYRemBkd', labelId: 'view.forecasting.table.lastYearRemainingUnconstrainedBooking', type: 'number', toFixed: 0 },
  { order: 7, id: 'proPrediction', labelId: 'view.forecasting.table.HSEForecast', type: 'number', toFixed: 0 },
  { order: 8, id: 'proPredictionInfluenced', labelId: 'view.forecasting.table.InfluencedForecast', type: 'number', toFixed: 0 },
  { order: 9, id: 'proPredictionPendingInfluenced', labelId: 'view.forecasting.table.PendingInfluencedForecast', type: 'number', toFixed: 0, dynamicCalcul: evaluateHSEWithPendingInfluences },
  { order: 10, id: 'proPredictionLiveInfluenced', labelId: 'view.forecasting.table.LiveInfluencedForecast', type: 'number', toFixed: 0, dynamicCalcul: evaluateHSEWithLiveInfluences },
  { order: 11, id: 'rmPrediction', labelId: 'view.forecasting.table.AIForecast', type: 'number', toFixed: 0 },
  {
    order: 12,
    id: 'Influence',
    labelId: 'view.forecasting.table.ImprovementRatio',
    type: 'number',
    toFixed: 2,
    dynamicCalcul: (row, pendingInfluences, liveInfluence) => row.rmPrediction / evaluateHSEWithLiveInfluences(row, pendingInfluences, liveInfluence)
  }
]

const classOrder = {
  YB: 5,
  MUH: 4,
  QVW: 3,
  STL: 2,
  AKG: 1,
  G: 8,
  OEN: 9,
  JCD: 11,
  ZP: 10
}

const groupBy = values => values

export const filters = [
  {
    refKey: 'DepartureDate',
    key: 'DateRange',
    labelId: 'filters.dateRange',
    type: 'DateRange',
    format: formatDateDow,
    orderBy: (a, b) => a.valueOf() - b.valueOf(),
    groupBy: toMinMaxDateRange,
    distinct: values => Object.values(values.reduce((a, c) => { a[c.format()] = c; return a }, {})),
    formatList: joinListWithFormat(formatDateRange)
  },
  {
    refKey: 'DoW',
    key: 'DoW',
    labelId: 'filters.dow',
    format: formatDoW,
    orderBy: sortDoW,
    groupBy,
    distinct,
    formatList: joinListWithFormat(formatDoW)
  },
  {
    refKey: 'ToD',
    key: 'ToD',
    labelId: 'filters.tod',
    format: formatStringAsList(formatToD),
    orderBy: sortAlphabeticaly,
    groupBy,
    distinct,
    formatList: joinListWithFormat(formatStringAsList(formatToD))
  },
  {
    refKey: 'Class',
    key: 'Class',
    labelId: 'filters.class',
    format: formatStringAsList(formatClass),
    orderBy: (a, b) => (classOrder[a] && classOrder[b]) ? sortAlphabeticaly(classOrder[a], classOrder[b]) : sortAlphabeticaly(a, b),
    groupBy,
    distinct,
    formatList: joinListWithFormat(formatStringAsList(formatClass))
  }
]

export const FilterIndexX = { DepartureDate: 0, DoW: 1 }
export const FilterIndexY = { ToD: 0, Class: 1 }

export const filtersX = [filters[0], filters[1]]
export const filtersY = [filters[2], filters[3]]

export const DepartureDateId = 'DepartureDate'
export const defaultOrder = 'asc'
export const defaultOrderBy = 'DepartureDate'
export const defaultRowsPerPage = 10
