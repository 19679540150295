import { SetFareCalculation, SetShowHSE } from '../../actions/heatmap'

const initialState = {
  usingFare: false,
  showHSE: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SetFareCalculation:
      return Object.assign({}, state, { usingFare: action.usingFare })
    case SetShowHSE:
      return Object.assign({}, state, { showHSE: action.showHSE })
    default:
      return state
  }
}
