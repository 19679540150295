import React from 'react'
import PropTypes from 'prop-types'

import EnhancedTableHeader from '../component/table/tableHead'

import { connect } from 'react-redux'
import store from '../store'
import { changeTableColumnOrder } from '../store/actions/tableState/tableState'

export default function createTableHead(tableStateSelector, view, TableHeadCell) {
  const mapStateToProps = state => ({ columnsOrdered: tableStateSelector(state).columnsOrdered })
  const onChangeTableColumnOrder = (sourceIndex, destinationIndex) => {
    if (sourceIndex !== destinationIndex) {
      store.dispatch(changeTableColumnOrder(view)(sourceIndex, destinationIndex))
    }
  }
  const EnhancedTableHeadWrapper = ({ columnsOrdered }) =>
    <EnhancedTableHeader columnsOrdered={columnsOrdered} changeTableColumnOrder={onChangeTableColumnOrder} TableHeadCell={TableHeadCell} />

  EnhancedTableHeadWrapper.propTypes = { columnsOrdered: PropTypes.array.isRequired }

  return connect(mapStateToProps)(EnhancedTableHeadWrapper)
}
