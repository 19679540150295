import { RequestedOptimizations, ReceivedOptimizations, OptimizationUpdating, OptimizationUpdated } from '../../actions/optimization'
import { sortByImportance } from '../../../views/forecasting/optimization/settingOptimization'

const initialState = {
  optimizations: [],
  orderedOptimizations: [],
  isLoading: false,
  optimizationUpdating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RequestedOptimizations:
      return Object.assign({}, state, {
        optimizations: [],
        orderedOptimizations: [],
        isLoading: true
      })
    case ReceivedOptimizations: {
      const optimizations = action.optimizations

      return Object.assign({}, state, {
        optimizations,
        orderedOptimizations: [...optimizations].sort(sortByImportance),
        isLoading: false
      })
    }
    case OptimizationUpdating:
      return Object.assign({}, state, { isLoading: true })
    case OptimizationUpdated: {
      const updatedStatus = optimization => optimization.id === action.id ? ({ ...optimization, status: action.status }) : optimization

      return Object.assign({}, state, {
        optimizations: [...state.optimizations.map(updatedStatus)],
        orderedOptimizations: [...state.orderedOptimizations.map(updatedStatus)],
        isLoading: false
      })
    }
    default:
      return state
  }
}
