import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

const Layout = ({ children, ...props }) =>
  <Box className='layout' {...props}>
    {children}
  </Box>

Layout.propTypes = { children: PropTypes.node.isRequired }

export default Layout
