import React from 'react'
import PropTypes from 'prop-types'

import newTag, { topRight } from './tag'

import { withLocalize } from 'react-localize-redux'

const Tag = ({ children, show, translate }) => {
  const Beta = newTag(translate('tag.beta'), topRight)
  return !show ? <>{children}</> : <Beta>{children}</Beta>
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export const BetaTag = withLocalize(Tag)
