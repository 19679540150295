import moment from 'moment'
import { DateFormat, NotAvailable, DateDowFormat } from '../appSetting'

export const dateToMoment = value => moment(value).utc().startOf('day')
export const toMoment = value => moment(value, 'YYYYMMDD').utc().startOf('day')
export const toUnix = value => value ? value.unix() * 1000 : value
export const utcToMoment = value => moment.utc(value)

export const dateRangeDateToUnix = ({ startDate, endDate }) => ({ startDate: toUnix(startDate), endDate: toUnix(endDate) })
export const dateRangeUnixToMoment = ({ startDate, endDate }) => ({ startDate: utcToMoment(startDate), endDate: utcToMoment(endDate) })

export const formatDateRange = ({ startDate, endDate }) => formatDate(startDate) + ' / ' + formatDate(endDate)
export const formatEmptyDateRange = dateRange => dateRange ? formatDateRange(dateRange) : NotAvailable
export const toMinMaxDateRange = list => list.length > 0 ? [{ startDate: moment.min(list), endDate: moment.max(list) }] : []

export const findLast = ([first, ...rest]) => rest.length > 0 ? findLast(rest) : first

export const findMinDate = dates => moment.min(...dates)
export const findMaxDate = dates => moment.max(...dates)
export const mergefirstLast = dates => ({ startDate: findMinDate(dates.map(range => range.startDate)), endDate: findMaxDate(dates.map(range => range.endDate)) })

export const formatDateDow = value => value.format(DateDowFormat)
export const formatDate = date => date.format(DateFormat)
export const formatHour = ([a, b, c, d]) => `${a}${b}:${c}${d}`
export const formatToD = values => formatHour(values.slice(0, 4)) + '\u2011' + formatHour(values.slice(5, 9))
export const formatDoW = day => moment().day(day).format('ddd')
export const formatClass = values => values.join('-')
export const formatStringAsList = format => string => format([...(string ?? '')])

export const isDayContainIn = hseDates => day => hseDates.some(hseDate => day.isSame(hseDate, 'day'))
export const isInDateRange = ({ startDate, endDate }, date) => date.isSameOrAfter(startDate, 'days') && date.isSameOrBefore(endDate, 'days')
export const numberOfDayBetween = (startDate, endDate) => startDate.diff(endDate, 'days')
