import uuidv4 from 'uuid/v4'

export const GetItem = 'GetItem'
export const getItem = name => ({
  type: GetItem,
  correlationId: uuidv4(),
  name
})

export const SetItem = 'SetItem'
export const setItem = (name, data) => ({
  type: SetItem,
  correlationId: uuidv4(),
  name,
  data
})
