import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core'

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

class AlertDialog extends Component {
  handleClose(callback) {
    callback && callback()
  }

  render() {
    const { title, text, agree, disagree, open, onOk, onRefuse, loading, isConfirm, classes } = this.props

    return (
      <Dialog
        open={open ?? false}
        onClose={() => this.handleClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            isConfirm ? '' : <Button onClick={() => this.handleClose(onRefuse)} color='primary' disabled={loading}>{disagree ?? 'Cancel'}</Button>
          }
          <div className={classes.wrapper}>
            <Button onClick={() => this.handleClose(onOk)} color='primary' variant='contained' autoFocus disabled={loading}>
              {agree ?? 'Ok'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  agree: PropTypes.string,
  disagree: PropTypes.string,
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onRefuse: PropTypes.func,
  loading: PropTypes.bool,
  isConfirm: PropTypes.bool,
  classes: PropTypes.object
}

export default withStyles(styles)(AlertDialog)
