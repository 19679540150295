import React from 'react'

import { FilledInput } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const minWidth = 100

const inputStyleBase = theme => ({
  backgroundColor: theme.palette.background.paper,
  fontWeight: 600,
  minWidth: `${minWidth}px`
})

const newFilledInput = newInput => withStyles(theme => ({ input: newInput(theme), adornedEnd: { padding: 0 } }))(FilledInput)

const StyledFilledInput = newFilledInput(inputStyleBase)
const StyledFixedFilledInput = newFilledInput(theme => ({
  ...inputStyleBase(theme),
  maxWidth: `${minWidth + 30}px`
}))

export const CustomFilledInput = ({ ...props }) =>
  <StyledFilledInput disableUnderline variant='outlined' margin='dense' select='false' {...props} />

export const CustomFixedFilledInput = ({ ...props }) =>
  <StyledFixedFilledInput disableUnderline variant='outlined' margin='dense' select='false' {...props} />
