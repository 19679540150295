import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { viewSelectorEnum } from '../../appSetting'

import { connect } from 'react-redux'
import { viewsSelector } from '../../store/selectors'

import OptimizationView from './optimization'
import InfluenceView from './influence'
import ReviewView from './review'

import Layout from '../layout'
import Header from './header'

import { Option } from '../../util/conditionnalComponent'

import { Collapse } from '@material-ui/core'

const OptimizationViewOption = Option(OptimizationView)
const InfluenceViewOption = Option(InfluenceView)
const ReviewViewOption = Option(ReviewView)

const mapStateToProps = state => ({ currentView: viewsSelector(state).view })

class ForecastingView extends Component {
  render() {
    const { currentView } = this.props

    return (
      <>
        <Header />
        <Layout>
          <Collapse in={currentView === viewSelectorEnum.List}>
            <ReviewViewOption condition={currentView === viewSelectorEnum.List} />
          </Collapse>
          <Collapse in={currentView === viewSelectorEnum.Priority}>
            <OptimizationViewOption condition={currentView === viewSelectorEnum.Priority} />
          </Collapse>
          <Collapse in={currentView === viewSelectorEnum.PendingInfluence}>
            <InfluenceViewOption condition={currentView === viewSelectorEnum.PendingInfluence} />
          </Collapse>
        </Layout>
      </>
    )
  }
}

ForecastingView.propTypes = {
  currentView: PropTypes.oneOf(Object.values(viewSelectorEnum)).isRequired
}

export default connect(mapStateToProps)(ForecastingView)
