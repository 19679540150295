import { filtersX, filtersY } from '../../views/forecasting/review/settingsForecasting'

const XName = 'X'
const YName = 'Y'

const makeButton = ({ label, name }) => ({
  method: 'restyle',
  args: ['line.color', label],
  label,
  name
})

export const onButtonClicked = (setActiveXfilter, setActiveYfilter) => event => {
  if (event.menu.name === XName) setActiveXfilter(event.active)
  if (event.menu.name === YName) setActiveYfilter(event.active)
}

const makeButtons = (filters, translate) => filters.map(({ labelId, key }) => ({ name: key, label: translate(labelId) })).map(makeButton)

export const updatemenus = (theme, translate) => (activeXfilter, activeYfilter) => {
  const buttonsX = makeButtons(filtersX, translate)
  const buttonsY = makeButtons(filtersY, translate)

  return [{
    name: XName,
    active: activeXfilter,
    y: -0.15,
    x: 0.94,
    yanchor: 'middle',
    xanchor: 'center',
    direction: 'left',
    bgcolor: theme.palette.primary.main,
    font: {
      color: theme.palette.tiertiary.main
    },
    showactive: false,
    buttons: buttonsX
  }, {
    name: YName,
    active: activeYfilter,
    y: 0.97,
    x: -0.1,
    yanchor: 'middle',
    xanchor: 'center',
    direction: 'down',
    bgcolor: theme.palette.primary.main,
    font: {
      color: theme.palette.tiertiary.main
    },
    showactive: false,
    buttons: buttonsY
  }]
}
