import React from 'react'
import PropTypes from 'prop-types'

import { TableBody } from '@material-ui/core'

import { DataEmpty, DataInvalid, DataLoading } from './tableRowData'

import { Option } from '../../../util/conditionnalComponent'

const ConditionnalDataInvalid = Option(DataInvalid)
const ConditionnalDataLoading = Option(DataLoading)
const ConditionnalEmpty = Option(DataEmpty)

const EnhancedTableBody = ({ rows, isFetchFailed, isDataLoading, colSpan, DataValide }) => {
  const ConditionnalDataValide = Option(DataValide)

  return (
    <TableBody>
      <ConditionnalDataLoading condition={isDataLoading} colSpan={colSpan} />
      <ConditionnalDataInvalid condition={!isDataLoading && isFetchFailed} colSpan={colSpan} />
      <ConditionnalEmpty condition={!isDataLoading && rows.length <= 0 && !isFetchFailed} colSpan={colSpan} />
      <ConditionnalDataValide condition={!isDataLoading && rows.length > 0 && !isFetchFailed} colSpan={colSpan} rows={rows} />
    </TableBody>
  )
}

EnhancedTableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  isFetchFailed: PropTypes.bool.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  colSpan: PropTypes.number.isRequired,
  DataValide: PropTypes.object.isRequired
}

export default EnhancedTableBody
