import { ChangeView, ChangeReviewView, ChangingView, SetAppLoading } from '../../actions/views'
import { defaultViewSelector, defaultReviewView } from '../../../appSetting'

const initialState = {
  view: defaultViewSelector,
  ReviewView: defaultReviewView,
  isChangingView: true,
  isAppLoading: true
}

export default (state = initialState, action) => {
  const { type, view, isChangingView, ReviewView } = action

  switch (type) {
    case ChangeView:
      return Object.assign({}, state, { view })
    case ChangeReviewView:
      return Object.assign({}, state, { ReviewView })
    case ChangingView:
      return Object.assign({}, state, { isChangingView })
    case SetAppLoading:
      return Object.assign({}, state, { isAppLoading: action.isAppLoading, isChangingView: false })
    default:
      return state
  }
}
