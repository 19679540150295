import { dateRangeUnixToMoment, mergefirstLast, formatDateRange, formatDoW, formatToD, formatStringAsList } from '../../../businessRules/date'
import { joinListWithFormat, joinList, sortDoW } from '../../../businessRules/array'

export const toUIOpimization = ({ DepartureDate, ...optimization }) => {
  const firstLastDepartureDatesMoment = mergefirstLast(DepartureDate.map(dateRangeUnixToMoment))
  const dowOrdered = optimization.DoW.sort(sortDoW)
  const todOrdered = optimization.ToD.sort()
  const classOrdered = optimization.Class.sort()

  return ({
    firstLastDepartureDatesMoment: firstLastDepartureDatesMoment,
    departureDateRangesFormatted: formatDateRange(firstLastDepartureDatesMoment),
    dowFormatted: joinListWithFormat(formatDoW)(dowOrdered),
    todFormatted: joinListWithFormat(formatStringAsList(formatToD))(todOrdered),
    classFormatted: joinListWithFormat(formatStringAsList(joinList))(classOrdered),
    ...optimization
  })
}
