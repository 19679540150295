import { NotAvailable } from '../../../appSetting'
import { joinListWithFormat } from '../../../businessRules/array'

const isIdEqual = value => element => element.id === value

export const toFormattedRegion = regions => v => (regions.find(isIdEqual(v)) ?? { description: NotAvailable }).description
export const toFormattedRole = roles => v => (roles.find(isIdEqual(v)) ?? { role: NotAvailable }).role
export const toFormattedAccess = roles => v => (roles.find(isIdEqual(v)) ?? { description: NotAvailable }).description

export const toUIUser = (regions, roles, user_roles) => ({ ...user }) => {
  const formattedRegions = joinListWithFormat(toFormattedRegion(regions))(user.regions)
  const formattedRole = toFormattedRole(user_roles)(user.user_role)
  const formattedAccess = toFormattedAccess(roles)(user.role)

  return ({
    ...user,
    formattedRegions,
    formattedRole,
    formattedAccess
  })
}

export const toUser = ({ ...user }) => ({
  ...user
})
