import { utcToMoment, formatDoW, formatStringAsList, formatToD, formatDate } from '../../../businessRules/date'
import { joinList } from '../../../businessRules/array'

export const toUIDemandeForecast = ({ DepartureDate, ...demandeForecast }) => {
  const departureDate = utcToMoment(DepartureDate)
  const dow = departureDate.day()

  return ({
    DepartureDate: departureDate,
    departureDateFormatted: formatDate(departureDate),
    DoW: dow,
    proPredictionPendingInfluenced: demandeForecast.proPredictionInfluenced,
    proPredictionLiveInfluenced: demandeForecast.proPredictionInfluenced,
    Influence: demandeForecast.rmPrediction / demandeForecast.proPredictionInfluenced,
    dowFormatted: formatDoW(dow),
    todFormatted: formatStringAsList(formatToD)(demandeForecast.ToD),
    classFormatted: formatStringAsList(joinList)(demandeForecast.Class),
    ...demandeForecast
  })
}
