import { Client } from '@microsoft/microsoft-graph-client'
import { UserAgentApplication } from 'msal'

import { routes } from '../appSetting'
import { isNotUndefinedAndNotNull } from '../businessRules/compare'
import store from '../store'
import { newError } from '../store/actions/error'

const { REACT_APP_AD_TENANT, REACT_APP_AD_CLIENTID, REACT_APP_AD_API_SCOPE } = process.env

const BASE_ROUTE = window.BASE_ROUTE ?? ''
const host = window.location.host
const protocol = window.location.protocol

const base = `${protocol}//${host}${BASE_ROUTE}`

const msalConfig = {
  auth: {
    clientId: REACT_APP_AD_CLIENTID,
    authority: `https://login.microsoftonline.com/${REACT_APP_AD_TENANT}`,
    redirectUri: base + routes.loginRedirect,
    postLogoutRedirectUri: base + routes.logout
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}

const scopesToken = ['user.read']
const scopesApi = [REACT_APP_AD_API_SCOPE]

const dispatch = (error, correlationId) => store.dispatch(newError('authentication.error.getToken', correlationId)(error))

const msalApplication = new UserAgentApplication(msalConfig)
msalApplication.handleRedirectCallback((error, authResponse) => {
  console.log('handleRedirectCallback', error, authResponse)
})

const onError = (scopes, correlationId) => error => {
  if (error) {
    const { message } = error

    if (message.indexOf('interaction_required') !== -1) msalApplication.acquireTokenRedirect({ scopes })
    else dispatch(error, correlationId)

    return {}
  }

  return {}
}

const getToken = (scopes, correlationId) => msalApplication.acquireTokenSilent({ scopes }).catch(onError(scopes, correlationId))

export const getClient = async() => {
  const { accessToken } = await getToken(scopesToken)
  return Client.init({
    authProvider: done => done(null, accessToken)
  })
}

export const loginApiFetch = async(fetch, url, { headers, ...options }, correlationId) => {
  const { accessToken } = await getToken(scopesApi, correlationId)
  const Authorization = 'Bearer ' + accessToken

  return fetch(url, { headers: { ...headers, Authorization }, ...options })
}

export const getUserName = () => user().userName
export const getFullName = () => user().name

export const login = () => msalApplication.loginRedirect({ scopes: scopesToken })
export const logout = () => msalApplication.logout()

export const user = () => msalApplication.getAccount()
export const isLoggedIn = () => isNotUndefinedAndNotNull(user())
