import { isString } from './string'

export const orderArray = array => (sourceIndex, destinationIndex, columnsOrdered) => {
  let tempValue = array.find(entry => entry === columnsOrdered[sourceIndex].order)
  const newSrcIndex = array.indexOf(tempValue)

  tempValue = array.find(entry => entry === columnsOrdered[destinationIndex].order)
  const newDestIndex = array.indexOf(tempValue)

  const movingLeft = newSrcIndex > newDestIndex ? [array[newSrcIndex], array[newDestIndex]] : []
  const movingRight = newSrcIndex < newDestIndex ? [array[newDestIndex], array[newSrcIndex]] : []

  const lowerLimit = Math.min(newSrcIndex, newDestIndex)
  const upperLimit = Math.max(newSrcIndex, newDestIndex)

  const lowerRange = array.slice(0, lowerLimit)
  const middleRange = array.slice(lowerLimit + 1, upperLimit)
  const upperRange = array.slice(upperLimit + 1)

  return [...lowerRange, ...movingLeft, ...middleRange, ...movingRight, ...upperRange]
}

export const sortDoW = (a, b) => a === 0 ? 1 : (b === 0 ? -1 : a - b)

export const sortAlphabeticaly = (a, b) => {
  if (isString(a) && isString(b)) return a > b ? 1 : b > a ? -1 : 0
  if (isNaN(a) && isNaN(b)) return 0
  if (isNaN(a)) return -1
  if (isNaN(b)) return 1

  return a > b ? 1 : b > a ? -1 : 0
}

export const sortAlphabeticalyDesc = (a, b) => {
  if (isString(a) && isString(b)) return a > b ? -1 : b > a ? 1 : 0
  if (isNaN(a) && isNaN(b)) return 0
  if (isNaN(a)) return 1
  if (isNaN(b)) return -1

  return a > b ? -1 : b > a ? 1 : 0
}

export const sortBy = key => (a, b) => sortAlphabeticaly(a[key], b[key])
export const sortDescBy = key => (a, b) => sortAlphabeticalyDesc(a[key], b[key])

export const getSorter = order => order === 'desc' ? sortAlphabeticalyDesc : sortAlphabeticaly
export const customSortBy = sorter => orderBy => (a, b) => sorter(a[orderBy], b[orderBy])

export const existIn = array => value => array.indexOf(value) !== -1
export const isFiltered = (array, value) => array === undefined || array.length === 0 || array.indexOf(value) !== -1
export const empty = val => !!val

export const distinct = values => [...new Set(values)]

export const joinList = values => values.join(', ')
export const joinListWithFormat = format => values => joinList(values.map(format))

export function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

export const isArrayEmpty = values => !(values && values.length !== 0)
export const flatmap = values => mapTo => values.reduce((acc, x) => acc.concat(mapTo(x)), [])
