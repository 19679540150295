import { existIn } from '../businessRules/array'

function createHandle(list, setList) {
  return function handleClick(id) {
    const indexInList = list.indexOf(id)
    let newList = []

    if (indexInList === -1) {
      newList = newList.concat(list, id)
    } else if (indexInList === 0) {
      newList = newList.concat(list.slice(1))
    } else if (indexInList === list.length - 1) {
      newList = newList.concat(list.slice(0, -1))
    } else if (indexInList > 0) {
      newList = newList.concat(
        list.slice(0, indexInList),
        list.slice(indexInList + 1)
      )
    }

    setList(newList)
  }
}

export default function(list, setList) {
  return {
    handleClick: createHandle(list, setList),
    isChecked: existIn(list),
    toggleAll: (ids, isChecked) => isChecked ? setList(ids) : setList([]),
    justSome: rows => list.length > 0 && list.length < rows.length,
    all: rows => list.length === rows.length
  }
}
