import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { commercialInfluenceSelector, tableCommercialInfluencestateSelector, commercialInfluencesSelectorForTableBody } from '../../store/selectors'

import TableTitle from '../../component/table/tableTitle'
import PostdateSubTitle from '../postdateSubTitle'
import EnhancedTable from '../../component/table/table'
import { commercialInfluences as commercialInfluencesView, changeOrdering } from '../../store/actions/tableState/tableState'
import { ActionsWithRouter } from './tableActions'
import createTableHeadCell from '../../event/tableHeadCell'
import createTableHead from '../../event/tableHead'
import createTableBody from '../../event/tableBody'
import createTableFooter from '../../event/tableFooter'
import createDataValid from '../../component/table/tableBody/dataValid'
import createTableToolbar from '../../component/tableToolbar/tableToolbar'

import { columns } from './settingCommercialInfluence'

const EnhancedTableToolbar = createTableToolbar(tableCommercialInfluencestateSelector)

const onChangeOrdering = (newOrder, newOrderBy) => store.dispatch(changeOrdering(commercialInfluencesView)(newOrder, newOrderBy))

const TableHeadCell = createTableHeadCell(tableCommercialInfluencestateSelector, commercialInfluencesView, onChangeOrdering)
const EnhancedTableHead = createTableHead(tableCommercialInfluencestateSelector, commercialInfluencesView, TableHeadCell)
const DataValid = createDataValid(ActionsWithRouter)
const EnhancedTableBody = createTableBody(commercialInfluencesSelectorForTableBody, tableCommercialInfluencestateSelector, DataValid)
const EnhancedTableFooter = createTableFooter(tableCommercialInfluencestateSelector, commercialInfluencesView)

const mapStateToProps = state => ({ commercialInfluences: commercialInfluenceSelector(state).commercialInfluences })

const Table = ({ commercialInfluences }) => (
  <>
    <TableTitle labelId='view.commercialInfluence.subtitle' />
    <PostdateSubTitle />
    <EnhancedTableToolbar columns={columns} view={commercialInfluencesView} />
    <EnhancedTable>
      <EnhancedTableHead />
      <EnhancedTableBody rows={commercialInfluences} />
      <EnhancedTableFooter numberRows={commercialInfluences.length} />
    </EnhancedTable>
  </>
)

Table.propTypes = { commercialInfluences: PropTypes.array.isRequired }

export default connect(mapStateToProps)(Table)
