import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import ActionIconButton from '../../../component/ActionIconButton'
import { Search, Minus } from '../../../component/icons'

import store from '../../../store'
import { reloadinfluenceSaga } from '../../../store/saga'
import { deleteInfluence } from '../../../store/actions/Influences'
import { isDeleteDisabled } from '../../../businessRules/influence'
import { noPropagation } from '../../../businessRules/event'

import { withLocalize } from 'react-localize-redux'
import { userSettingsSelector } from '../../../store/selectors'
import { connect } from 'react-redux'
import { Option } from '../../../util/conditionnalComponent'

const clearInfluence = id => store.dispatch(deleteInfluence(id))
export const onClickLoad = row => store.dispatch(reloadinfluenceSaga(row))

const CActionIconButton = Option(ActionIconButton)

const mapStateToProps = state => {
  const { isCalibrate } = userSettingsSelector(state)
  return ({ isCalibrate })
}

const ActionsInfluence = ({ isCalibrate, row, isLoading, translate }) => (
  <Box key={row.id}>
    <ActionIconButton
      size='small'
      title={translate('view.influence.reload')}
      disabled={isLoading}
      onClick={noPropagation(() => onClickLoad(row))}
    >
      <Search />
    </ActionIconButton>
    <CActionIconButton
      condition={isCalibrate}
      size='small'
      title={translate('view.influence.delete')}
      disabled={isLoading || isDeleteDisabled(row)}
      onClick={noPropagation(() => clearInfluence(row.id))}
    >
      <Minus />
    </CActionIconButton>
  </Box>
)

ActionsInfluence.propTypes = {
  isCalibrate: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export const ActionsInfluenceWithRouter = connect(mapStateToProps)(withLocalize(ActionsInfluence))
