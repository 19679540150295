const colorscale = [
  [0, '#19238e'],
  [0.42, '#f9f9f9'],
  [0.59, '#f9f9f9'],
  [1, '#ff8388']
]

export const getData = (theme, translate) => (x, y, z, maxBooking) => [{
  x,
  y,
  z,
  len: 0,
  cmin: -1,
  cmax: 1,
  zmax: 1,
  zmid: 0,
  zmin: -1,
  type: 'heatmap',
  mode: 'markers',
  colorscale,
  autocolorscale: false,
  colorbar: {
    title: translate('heatmap.legendTitle', { absMax: Math.floor(maxBooking) }),
    tickmode: 'array',
    ticktext: ['', translate('heatmap.minimalValue'), translate('heatmap.zero'), translate('heatmap.maximalValue'), ''],
    tickvals: [-1, -0.9, 0, 0.9, 1],
    tickfont: {
      family: theme.typography.family,
      size: '',
      color: theme.typography.family
    },
    ypad: 0,
    outlinewidth: 0
  }
}]
