import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, Checkbox, ListItemText, Select } from '@material-ui/core'

import CustomFormControl from '../customFormControl/customFormControl'
import { CustomInputLabel, FilledInput } from '../input'

import { FullHeightDefaultButton } from '../buttons/defaultButton'
import { EmptyClassComponent } from '../empty/empty' // Need to be an empty class component as React Select component expect to have class component

import { withLocalize, Translate } from 'react-localize-redux'
import { toValue } from './'
import { isNotUndefinedAndNotNull } from '../../businessRules/compare'
import ArrowUpDown from '../ArrowUpDown'
import { sentence } from '../../businessRules/string'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const ALL = 'ALL'

function MultiSelect(props) {
  const {
    elements,
    error,
    required,
    titleId,
    labelId,
    updateValues,
    values,
    renderValue,
    isLoading,
    fullWidth,
    translate
  } = props

  const [isMenuOpen, setMenuState] = React.useState(false)
  const [internalValues, setInternalValues] = React.useState([])

  const title = sentence(titleId, labelId)(translate)

  const allValues = elements.map(toValue)
  const currentValues = isMenuOpen ? internalValues : values

  function handleChange(value) {
    const toggleAllChecked = () => isAllChecked() ? [] : allValues
    const array = value.some(v => v === ALL) ? toggleAllChecked() : value

    setInternalValues(array)
  }

  const applyOnOpen = () => {
    setInternalValues(values)
    setMenuState(true)
  }

  const applyOnClose = () => {
    updateValues(internalValues.length === 0 ? allValues : internalValues)
    setMenuState(false)
  }

  const filterBySearchValue = () => !isLoading

  const isChecked = ({ value }) => isNotUndefinedAndNotNull(value) && currentValues.indexOf(value) > -1
  const isAllChecked = () => elements.every(isChecked)

  function defaultMenuItem(translate) {
    return (
      <MenuItem title={translate('Select.default.all')} key='all-default' value={ALL}>
        <ListItemText primary={translate('Select.default.all')} />
        <Checkbox checked={isAllChecked()} />
      </MenuItem>
    )
  }

  return (
    <CustomFormControl error={error} fullWidth={fullWidth}>
      <CustomInputLabel required={required} id={'select-label-' + labelId}><Translate id={labelId} /></CustomInputLabel>
      <Select
        fullWidth={fullWidth}
        multiple
        title={title}
        labelId={'select-label-' + labelId}
        value={currentValues}
        onChange={({ target: { value } }) => handleChange(value)}
        input={<FilledInput />}
        renderValue={isAllChecked() ? () => translate('Select.default.all') : renderValue}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': translate(labelId) }}
        IconComponent={() => <EmptyClassComponent />}
        open={isMenuOpen}
        onOpen={applyOnOpen}
        onClose={applyOnClose}
        endAdornment={
          <FullHeightDefaultButton title={title} color='default' aria-label='directions' variant='contained' size='small' onClick={applyOnOpen}>
            <ArrowUpDown open={isMenuOpen} />
          </FullHeightDefaultButton>
        }
      >
        {
          elements.length > 1 ? defaultMenuItem(translate) : <EmptyClassComponent />
        }
        {
          elements.filter(filterBySearchValue).map(element => (
            <MenuItem title={element.formattedValue} key={element.key} value={element.value}>
              <ListItemText primary={element.formattedValue} />
              <Checkbox checked={isChecked(element)} />
            </MenuItem>
          ))
        }
      </Select>
    </CustomFormControl>
  )
}

MultiSelect.propTypes = {
  elements: PropTypes.array.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  titleId: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  updateValues: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  renderValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export default withLocalize(MultiSelect)
