import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const defaultStyle = theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.brand['05'],
    '&:hover': {
      fontWeight: theme.typography.fontWeightBold
    }
  }
})

export default withStyles(defaultStyle)(Button)
