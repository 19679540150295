import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import SectionBox from '../sectionBox'

const style = _theme => ({ root: { width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' } })

const StatBox = ({ children, classes }) =>
  <SectionBox textAlign='center' className={classes.root} p={1}>{children}</SectionBox>

StatBox.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(StatBox)
