import React from 'react'
import PropTypes from 'prop-types'

import { Box, Checkbox } from '@material-ui/core'

import { withLocalize } from 'react-localize-redux'
import { setOptimizationStatus } from '../../../store/actions/optimization'
import store from '../../../store'
import { noPropagation } from '../../../businessRules/event'

const InofOptimization = ({ row, translate }) => {
  const isDone = row.status === 'Read'
  const newStatus = isDone ? undefined : 'Read'

  const handleChange = () => store.dispatch(setOptimizationStatus({ ...row, status: newStatus }))

  return (
    <Box key={row.id}>
      <Checkbox
        checked={isDone}
        onClick={noPropagation(() => handleChange())}
      />
    </Box>
  )
}

InofOptimization.propTypes = {
  row: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export const InfoOptimizationLocalized = withLocalize(InofOptimization)
