import React from 'react'
import PropTypes from 'prop-types'

import { SingleSelect } from '../../../component/Select'

import { connect } from 'react-redux'
import store from '../../../store'
import { modifyUser } from '../../../store/actions/newUser'
import { configurationSelector } from '../../../store/selectors'

import { isEmpty } from '../../../businessRules/string'
import { toFormattedAccess } from '../../../store/actions/users/map'

const setUser = data => store.dispatch(modifyUser(data))

const mapStateToProps = state => {
  const { rolesSelectItem, roles } = configurationSelector(state)
  return ({ rolesSelectItem, defaultAccess: roles })
}

const Access = ({ role, isSubmitClicked, rolesSelectItem, defaultAccess }) =>
  <SingleSelect
    fullWidth
    error={isSubmitClicked && isEmpty(role)}
    elements={rolesSelectItem}
    required
    titleId='selectorTitle'
    labelId='view.users.access'
    updateValue={role => setUser({ role })}
    renderValue={toFormattedAccess(defaultAccess)}
    value={role}
  />

Access.propTypes = {
  rolesSelectItem: PropTypes.array.isRequired,
  defaultAccess: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  isSubmitClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Access)
