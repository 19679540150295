export const layout = (updatemenus, noDataMessage) => {
  const annotations = []

  if (noDataMessage) {
    annotations.push({
      text: noDataMessage,
      xref: 'paper',
      yref: 'paper',
      showarrow: false,
      font: {
        size: 28
      }
    })
  }

  return ({
    dragmode: 'select',
    showlegend: true,
    annotations,
    updatemenus,
    height: 600,
    margin: {
      t: 0,
      r: 100,
      l: 150,
      b: 120 // Must update createGridFullHeightWithPaddingBottom('120px')
    },
    xaxis: {
      type: 'category',
      ticks: ''
    },
    yaxis: {
      type: 'category',
      ticks: '',
      ticksuffix: ' '
    }
  })
}
