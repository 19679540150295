import React from 'react'
import PropTypes from 'prop-types'

import { Toolbar, Box } from '@material-ui/core'

import { connect } from 'react-redux'
import store from '../../store'

import { Option } from '../../util/conditionnalComponent'
import Chip from '../chip/chip'

import checkboxList from '../../util/checkboxList'
import { changeColumnHidden, views } from '../../store/actions/tableState/tableState'
import { existIn } from '../../businessRules/array'
import { withLocalize } from 'react-localize-redux'

const ConditionnalBox = Option(Box)

function CreateToolbar(tableStateSelector) {
  const mapStateToProps = state => ({ shownColumn: tableStateSelector(state).columnHidden })

  function EnhancedTableToolbar({ shownColumn, columns, view, translate }) {
    const columnCheckboxList = checkboxList(shownColumn, v => store.dispatch(changeColumnHidden(view)(v)))

    const not = f => p => !f(p)
    const isHidden = column => not(existIn(shownColumn))(column.id)

    return (
      <Toolbar disableGutters variant='dense'>
        <ConditionnalBox condition={columns.some(isHidden)} display='flex'>
          {
            columns.filter(isHidden).map(column =>
              <Box key={'hideColumn-chip-' + column.id} ml={1}>
                <Chip label={column.labelId ? translate(column.labelId) : column.label} onDelete={_e => columnCheckboxList.handleClick(column.id)} />
              </Box>
            )
          }
        </ConditionnalBox>
      </Toolbar>
    )
  }

  EnhancedTableToolbar.propTypes = {
    columns: PropTypes.array.isRequired,
    view: PropTypes.oneOf(views).isRequired,
    shownColumn: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired
  }

  return connect(mapStateToProps)(withLocalize(EnhancedTableToolbar))
}

export default CreateToolbar
