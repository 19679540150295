import { findStartEndDate } from '../algorithm/stateChange'
import { distinct, existIn, flatmap } from './array'
import { dateRangeUnixToMoment, isInDateRange, dateRangeDateToUnix } from './date'
import { isNotUndefinedAndNotNull } from './compare'

const classKey = 'Class'
const DoWKey = 'DoW'
const ToDKey = 'ToD'
const DateRangeKey = 'DateRange'
const POSKey = 'POS'
const CabinKey = 'Cabin'

const formatInitial = name => name.split(' ').map(c => c[0]).join('')

export const formatDescription = ({ Origin, Destination, POS }, name, service) => `${service} ${formatInitial(name)} AI ${Origin}${Destination} ${POS}`

const filterByValidFilter = (rows, key, filtersValue) => rows.map(row => row[key]).filter(existIn(filtersValue[key]))

export const formatDimensions = function a(filtersValue, selectors, rows, calculatedDemandeForecast, showHSE) {
  const filterDate = filtersValue[DateRangeKey] ?? [{}]
  const departure = filterDate[0] ?? {}
  const { startDate, endDate } = dateRangeUnixToMoment(departure)

  const DoW = distinct(filterByValidFilter(rows, DoWKey, filtersValue))
  const ToD = distinct(filterByValidFilter(rows, ToDKey, filtersValue))
  const Class = distinct(filterByValidFilter(rows, classKey, filtersValue))

  const byDepartureDate = row => isInDateRange({ startDate, endDate }, row.DepartureDate)
  const departureDates = showHSE ? [{ startDate, endDate }] : findStartEndDate(calculatedDemandeForecast.filter(byDepartureDate).map(({ isHSE, DepartureDate }) => ({ isHSE, DepartureDate })))

  return {
    trip_Origin: selectors.Origin,
    trip_Destination: selectors.Destination,
    pos: selectors[POSKey],
    cabin: selectors[CabinKey],
    dateRangeIndex: selectors.DateRangeIndex,
    dateRange: {
      startDate: selectors.DateRange.startDate,
      endDate: selectors.DateRange.endDate
    },
    DepartureDate: departureDates,
    DoW,
    ToD,
    Class,
    todDesc: distinct(flatmap(rows)(row => row.todDesc)),
    PathGroup: selectors.PathGroup
  }
}

export const defaultSelector = {
  Origin: undefined,
  Destination: undefined,
  POS: undefined,
  DateRange: {
    startDate: 0,
    endDate: 0
  },
  DateRangeIndex: 0
}

export const mapRowToSelectors = row => ({
  Origin: row.trip_Origin,
  Destination: row.trip_Destination,
  POS: row.pos,
  Cabin: row.Cabin,
  DateRange: row.dateRange,
  DateRangeIndex: row.dateRangeIndex,
  PathGroup: row.PathGroup
})
const checkDateRangeIndex = (termForDates) => {
  let indexOFDateRange = ''
    switch (termForDates) {
      case '0':
        indexOFDateRange = 'short'
        break
      case '1':
        indexOFDateRange = 'medium'
        break
      case '2':
        indexOFDateRange = 'long'
        break
      default:
        indexOFDateRange = 'short'
        break
    }
    return indexOFDateRange
  }
export const mapParamsToSelectors = (params, ranges, ODs, pathGroups) => {
  const Origin = ODs.some(OD => OD.origin === params.origin) ? params.origin : undefined
  const Destination = Origin && ODs.some(OD => OD.destination === params.destination) ? params.destination : undefined
  const POS = Origin && Destination && ODs.some(OD => OD.POS === params.pos) ? params.pos : undefined
  const Cabin = Origin && Destination && POS && ODs.some(OD => OD.cabin === params.cabin) ? params.cabin : undefined
  const PathGroup = pathGroups.some(pathGroup => pathGroup.value === params.pathGroup) ? params.pathGroup : undefined
  const DateRangeIndex = (params.dateRangeIndex >= 0 && params.dateRangeIndex < ranges.length) ? parseInt(params.dateRangeIndex) : defaultSelector.DateRangeIndex
  const Term = checkDateRangeIndex(params.dateRangeIndex)
  return ({
    Origin,
    Destination,
    POS,
    Cabin,
    Term,
    DateRange: dateRangeDateToUnix(ranges[DateRangeIndex] ?? defaultSelector.DateRange),
    DateRangeIndex,
    PathGroup
  })
}

export const mapSelectorsToCIParams = ({ Origin, Destination, POS, Cabin, DateRange, PathGroup }) =>
  ({ origin: Origin, destination: Destination, pos: POS, cabin: Cabin, pathType: PathGroup, startDate: DateRange.startDate, endDate: DateRange.endDate })

export const mapSelectorsToUrlParams = ({ Origin, Destination, POS, Cabin, PathGroup, DateRangeIndex }) =>
  `/${Origin}/${Destination}/${POS}/${Cabin}/${PathGroup}/${DateRangeIndex}`

export const containAllParams = ({ Origin, Destination, POS, Cabin, PathGroup, DateRangeIndex }) =>
  Origin && Destination && POS && Cabin && PathGroup && isNotUndefinedAndNotNull(DateRangeIndex)

export const filterBySelector = selector => od =>
  od.origin === selector.Origin &&
  od.destination === selector.Destination &&
  od.POS === selector.POS &&
  od.cabin === selector.Cabin
