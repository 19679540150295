const divFixed = sums => sums.totalB ? (sums.totalA / sums.totalB).toFixed(2) : undefined
const div = sums => sums.totalB ? (sums.totalA / sums.totalB) : undefined

const sum = (a, b) => rows => rows.map(row => ({ a: row[a], b: row[b] })).reduce((acc, cur) => ({
  totalA: acc.totalA + (cur.a ?? 0),
  totalB: acc.totalB + (cur.b ?? 0)
}), { totalA: 0, totalB: 0 })

const multiply = (a, b, sum) => ({ totalA: sum.totalA * a, totalB: sum.totalB * b })
const minus = sum => Math.abs(sum.totalA - sum.totalB)

const byHSE = row => !(row.LYRMAIPred === 0 && row.LYRemHSEBook === 0)

export const sumCumulativeBooking = rows => sum('cumBkd', 'LYCumBkd')(rows)
export const sumBookingRemainingLiveInfluenced = (rows, influence) => multiply(influence, 1, sum('proPredictionPendingInfluenced', 'LYRemBkd')(rows))
export const sumBookingRemaining = rows => sum('proPrediction', 'LYRemBkd')(rows)
export const sumBookingInfluencedRemaining = rows => sum('proPredictionInfluenced', 'LYRemBkd')(rows)
export const sumBookingRMAI = rows => sum('rmPrediction', 'LYRemBkd')(rows)
export const sumError = rows => ({ totalA: minus(sum('LYRemBkd', 'LYRMAIPred')(rows.filter(byHSE))), totalB: minus(sum('LYRemBkd', 'LYRemHSEBook')(rows.filter(byHSE))) })

export const yoyOf = sum => ({ sum, ratio: div(sum) })
export const yoyImprovement = sum => 1 - div(sum)

export const calculateLiveInfluence = rows => divFixed(sum('rmPrediction', 'proPredictionPendingInfluenced')(rows))
