import React from 'react'
import PropTypes from 'prop-types'

export const NetworkError = ({ errorName, errorMessage, errorStack }) => (
  <>
    {
      errorName ? <div><span><b>Error Name:</b> </span>{errorName}</div> : <div />
    }
    {
      errorMessage ? <div><span><b>Error Description:</b> </span>{errorMessage}</div> : <div />
    }
    {
      errorStack ? <div><div><b>Error stack:</b> </div>{errorStack}</div> : <div />
    }
  </>
)

NetworkError.propTypes = {
  errorName: PropTypes.string,
  errorMessage: PropTypes.string,
  errorStack: PropTypes.object
}

export default NetworkError
