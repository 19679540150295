import {
  ReceivedPortfolioSingle,
  ReceivedPortfolioOD,
  RequestedPortfolioSingle,
  RequestedPortfolioOD,
  SingleOptimizationUpdating,
  SingleOptimizationUpdated,
  UpdatePortfolioSelectors,
  ODUpdated, ODupdating, RequestedPortfolioSingleAndOD,
  ReceivedPortfolioSingleAndOD
} from '../../actions/portfolio'

const initialState = {
  portfoliosOD: [],
  portfoliosSingle: [],
  odIsLoading: false,
  singleIsLoading: false,
  haveSelectors: false,
  selectors: {
    DateRange: { startDate: 0, endDate: 0 },
    userId: undefined,
    email: undefined,
    regionIds: [],
    regionName: [],
    cabinIds: [],
    cabins: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UpdatePortfolioSelectors:
      return Object.assign({}, state, {
        haveSelectors: true,
        selectors: Object.assign({}, state.selectors, { ...action.selectors })
      })
    case RequestedPortfolioOD:
      return Object.assign({}, state, {
        portfoliosOD: [],
        odIsLoading: true
      })
    case RequestedPortfolioSingle:
      return Object.assign({}, state, {
        portfoliosSingle: [],
        singleIsLoading: true
      })
    case RequestedPortfolioSingleAndOD: {
      return Object.assign({}, state, {
        portfoliosSingle: [],
        singleIsLoading: true,
        portfoliosOD: [],
        odIsLoading: true
      })
    }
    case ReceivedPortfolioSingleAndOD: {
      const portfolioODs = action.portfolios.portfolioODs ? action.portfolios.portfolioODs : []
      const portfolioSingles = action.portfolios.portfolioSingles ? action.portfolios.portfolioSingles : []
      return Object.assign({}, state, {
        portfoliosOD: portfolioODs,
        odIsLoading: false,
        portfoliosSingle: portfolioSingles.map(optimization => ({ ...optimization, isUpdating: false })),
        singleIsLoading: false
      })
    }
    case ReceivedPortfolioOD: {
      const portfolios = action.portfolios
      return Object.assign({}, state, {
        portfoliosOD: portfolios,
        odIsLoading: false
      })
    }
    case ReceivedPortfolioSingle: {
      const portfolios = action.portfolios
      return Object.assign({}, state, {
        portfoliosSingle: portfolios.map(optimization => ({ ...optimization, isUpdating: false })),
        singleIsLoading: false
      })
    }
    case SingleOptimizationUpdating: {
      const rowIsUpdating = (optimization) => optimization.id === action.id ? { ...optimization, isUpdating: true } : optimization

      return Object.assign({}, state, {
        portfoliosSingle: [...state.portfoliosSingle.map(rowIsUpdating)]
      })
    }
    case SingleOptimizationUpdated: {
      const updatedStatus = (optimization) => optimization.id === action.id ? { ...optimization, status: action.status, isUpdating: false } : optimization

      return Object.assign({}, state, {
        portfoliosSingle: [...state.portfoliosSingle.map(updatedStatus)]
      })
    }
    case ODupdating: {
      const rowIsUpdating = (optimization) => optimization.id === action.id ? { ...optimization, isUpdating: true } : optimization

      return Object.assign({}, state, {
        portfoliosOD: [...state.portfoliosOD.map(rowIsUpdating)]
      })
    }
    case ODUpdated: {
      const updatedStatus = (optimization) => optimization.id === action.id ? { ...optimization, status: action.status, isUpdating: false } : optimization

      return Object.assign({}, state, {
        portfoliosOD: [...state.portfoliosOD.map(updatedStatus)]
      })
    }
    default:
      return state
  }
}
