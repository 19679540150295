import React from 'react'
import PropTypes from 'prop-types'

import { Box, withStyles } from '@material-ui/core'
import { Info } from '../icons'
import Empty from '../empty/empty'

import { Translate } from 'react-localize-redux'

const CustomBox = withStyles(theme => ({ root: { color: theme.palette.brand['03'], fill: theme.palette.brand['03'] } }))(Box)

const Indicator = ({ show, labelId, ml }) =>
  show ? (
    <CustomBox ml={ml} fontWeight='fontWeightBold' display='flex' alignItems='center'>
      <Info />&nbsp;<Translate id={labelId} />
    </CustomBox>
  ) : <Empty />

Indicator.propTypes = {
  show: PropTypes.bool.isRequired,
  labelId: PropTypes.string.isRequired,
  ml: PropTypes.number
}

export default Indicator
