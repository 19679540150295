import React from 'react'
import PropTypes from 'prop-types'
import { Typography, IconButton } from '@material-ui/core'

function SmallIconButtonComponent({ children, value, ...props }) {
  return (
    <IconButton size='small' {...props}>
      {children ?? value}
    </IconButton>
  )
}

SmallIconButtonComponent.propTypes = {
  children: PropTypes.element,
  value: PropTypes.element
}

function SmallNumberButtonComponent({ children, value, classes, ...props }) {
  const typographyClassName = classes && classes.textNumber

  return (
    <IconButton size='small' {...props}>
      <Typography className={typographyClassName} variant='caption'>{children ?? value}</Typography>
    </IconButton>
  )
}

SmallNumberButtonComponent.propTypes = {
  children: PropTypes.number,
  value: PropTypes.number,
  classes: PropTypes.object
}

export const SmallIconButton = SmallIconButtonComponent
export const SmallNumberButton = SmallNumberButtonComponent
