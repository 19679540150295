import {
  dateRangeUnixToMoment,
  dateRangeDateToUnix,
  formatDateRange,
  formatEmptyDateRange,
  formatDoW,
  formatStringAsList,
  formatToD
} from '../../../businessRules/date'

import { joinList, joinListWithFormat, sortDoW } from '../../../businessRules/array'

export const toUICommercialInfluence = ({ flightDateRange, effectiveDateRange, decayRange, ...commercialInfluence }) => {
  const flightDateRangeMoment = dateRangeUnixToMoment(flightDateRange)
  const effectiveDateRangeMoment = dateRangeUnixToMoment(effectiveDateRange)
  const decayRangeMoment = decayRange ? dateRangeUnixToMoment(decayRange) : undefined
  const dowOrdered = commercialInfluence.dow.sort(sortDoW)
  const todOrdered = commercialInfluence.tod.sort()
  const pathTypeOrdered = commercialInfluence.pathType.sort()

  return ({
    flightDateRange: flightDateRangeMoment,
    effectiveDateRange: effectiveDateRangeMoment,
    decayRange: decayRangeMoment,
    flightDateRangeFormatted: formatDateRange(flightDateRangeMoment),
    cabinFormatted: joinList(commercialInfluence.cabin),
    seatClassFormatted: joinList(commercialInfluence.seatClass),
    effectiveDateRangeFormatted: formatDateRange(effectiveDateRangeMoment),
    decayRangeFormatted: formatEmptyDateRange(decayRangeMoment),
    dowOrderedFormatted: joinListWithFormat(formatDoW)(dowOrdered),
    todOrderedFormatted: joinListWithFormat(formatStringAsList(formatToD))(todOrdered),
    pathTypeFormatted: joinList(pathTypeOrdered),
    ...commercialInfluence
  })
}

export const toCommercialInfluence = ({ flightDateRange, effectiveDateRange, decayRange, ...commercialInfluence }) => ({
  flightDateRange: dateRangeDateToUnix(flightDateRange),
  effectiveDateRange: dateRangeDateToUnix(effectiveDateRange),
  decayRange: decayRange ? dateRangeDateToUnix(decayRange) : undefined,
  ...commercialInfluence
})
