import { showStatus } from '../../../businessRules/influence'

export const columns = [
  { order: 1, id: 'departureDateRangesFormatted', labelId: 'view.influence.table.DepartureDateRange' },
  { order: 2, id: 'dowFormatted', labelId: 'view.influence.table.DoW' },
  { order: 3, id: 'todFormatted', labelId: 'view.influence.table.ToD' },
  { order: 4, id: 'classFormatted', labelId: 'view.influence.table.Class' },
  { order: 5, id: 'influence', labelId: 'view.influence.table.Influence' },
  { order: 6, id: 'description', labelId: 'view.influence.table.Description' },
  { order: 7, id: 'status', labelId: 'view.influence.table.Status', type: 'translate', translate: showStatus },
  { order: 8, id: 'Action', labelId: 'view.influence.table.Action', type: 'action' }
]
