import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DefaultInfluence } from '../../../appSetting'
import { calculateLiveInfluence } from '../../../businessRules/statistic'

import { connect } from 'react-redux'
import store from '../../../store'
import { sendInfluence, changeInfluenceDimension, changeInfluenceValue, changeInfluenceString, updateAlertOpenFlag } from '../../../store/actions/Influences'
import { influenceSelector, rowsSelector, userSettingsSelector } from '../../../store/selectors'

import { Box, Typography, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import DefaultButton from '../../../component/buttons/defaultButton'
import { NumberInput, StringInput } from '../../../component/input'
import { LoadingButton } from '../../../component/buttons/loadingButton'
import SectionBox from '../../../component/sectionBox'
import ButtonLink from '../../../component/buttonLink'

import { Translate, withLocalize } from 'react-localize-redux'

const DefaultButtonLoading = LoadingButton(DefaultButton)

const mapStateToProps = state => {
  const { liveInfluence, isLoading, isOpenAlertFlag } = influenceSelector(state)
  const { rows } = rowsSelector(state)
  const { isCalibrate } = userSettingsSelector(state)

  return ({ rows, liveInfluence, isInfluenceLoading: isLoading, isCalibrate, isOpenAlertFlag })
}

const min = 0.01
const step = min

class InfluenceToolBar extends Component {
  render() {
    const { rows, liveInfluence, isInfluenceLoading, isCalibrate, isOpenAlertFlag, translate } = this.props

    const updateInfluenceValue = influence => store.dispatch(changeInfluenceValue(influence))
    const updateInfluenceString = influenceString => store.dispatch(changeInfluenceString(influenceString))
    const changeInfluenceDescription = description => store.dispatch(changeInfluenceDimension({ description }))
    const setAlertOpenFlag = flag => store.dispatch(updateAlertOpenFlag({ flag }))
    const setHintInfluence = () => updateInfluenceValue(parseFloat(calculateLiveInfluence(rows)))
    const saveInfluence = () => store.dispatch(sendInfluence(liveInfluence, setAlertOpenFlag))
    const resetInfluence = () => updateInfluenceValue(DefaultInfluence)

    const isDisabled = liveInfluence.influence === DefaultInfluence

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setAlertOpenFlag(false)
    }

    return (
      <SectionBox display='flex' p={2} flexDirection='column'>
        <Box mb={2}>
          <Typography variant='h2'><Translate id='influences.sectionTitle' /></Typography>
          <ButtonLink labelId='influences.reset' onClick={resetInfluence} disabled={isDisabled} />
        </Box>
        <Box mb={1}>
          <NumberInput
            labelId='toolbar.influences.inputTitle'
            value={liveInfluence.influenceString}
            onChange={updateInfluenceString}
            defaultValue={DefaultInfluence}
            step={step}
            min={min}
          />
        </Box>
        <Box mb={1}>
          <StringInput
            labelId='toolbar.influences.inputDescription'
            value={liveInfluence.description}
            onChange={changeInfluenceDescription}
            defaultValue=''
          />
        </Box>
        <Box display='flex' mt={1}>
          <Box mr={1 / 2} width='100%'>
            <DefaultButtonLoading
              fullWidth
              title={translate('influences.titleHint')}
              isLoading={isInfluenceLoading}
              color='secondary'
              variant='contained'
              onClick={setHintInfluence}
            >
              <Translate id='influences.hint' />
            </DefaultButtonLoading>
          </Box>
          <Box ml={1 / 2} width='100%'>
            <DefaultButtonLoading
              fullWidth
              title={translate('influences.titleApply')}
              isLoading={isInfluenceLoading}
              color='primary'
              variant='contained'
              onClick={saveInfluence}
              disabled={isDisabled || !isCalibrate}
            >
              <Translate id='influences.buttonApply' />
            </DefaultButtonLoading>
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={isOpenAlertFlag.flag}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
            <Translate id='influences.successMsg' />
          </Alert>
        </Snackbar>
      </SectionBox>
    )
  }
}

InfluenceToolBar.propTypes = {
  isCalibrate: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  liveInfluence: PropTypes.object.isRequired,
  isInfluenceLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  isOpenAlertFlag: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withLocalize(InfluenceToolBar))
