import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@material-ui/core'
import ActionIconButton from '../../../component/ActionIconButton'
import { Search } from '../../../component/icons'

import store from '../../../store'
import { noPropagation } from '../../../businessRules/event'

import { withLocalize } from 'react-localize-redux'

import { withRouter } from 'react-router-dom'
import { userSettingsSelector, postdateSelector } from '../../../store/selectors'
import { routes } from '../../../appSetting'
import { mapSelectorsToUrlParams, filterBySelector } from '../../../businessRules/dimensions'
// import { mapSelectorsToUrlParams } from '../../../businessRules/dimensions'
import { dateRangeDateToUnix } from '../../../businessRules/date'
import { applySelectors } from '../../../store/saga/filters'
import { loadOptimizationSaga } from '../../../store/saga'

const mapStateToProps = state => ({
  ODs: userSettingsSelector(state).ODs,
  postdate: postdateSelector(state).postdate,
  lowRange: postdateSelector(state).lowRange
})
const checkDateRangeIndex = (termForDates) => {
  let indexOFDateRange = 0
    switch (termForDates) {
      case 'short':
        indexOFDateRange = 0
        break
      case 'medium':
        indexOFDateRange = 1
        break
      case 'long':
        indexOFDateRange = 2
        break
      default:
        indexOFDateRange = 0
        break
    }
    return indexOFDateRange
  }
export const onClickLoad = (row, ODs, postdate, lowRange, history) => {
  const newSelector = {
    Origin: row.trip_Origin,
    Destination: row.trip_Destination,
    POS: row.pos,
    DateRange: dateRangeDateToUnix(row.firstLastDepartureDatesMoment),
    DateRangeIndex: checkDateRangeIndex(row.Term),
    Cabin: row.cabin,
    PathGroup: row.PathGroup,
    Term: row.Term,
    Influence: row.influence
  }
  console.log(newSelector)
  const { service } = ODs.filter(filterBySelector(newSelector))[0] ?? {}
  store.dispatch(applySelectors(newSelector, service, postdate, false))
  store.dispatch(loadOptimizationSaga(row))
  history.push(routes.forecasting.path + mapSelectorsToUrlParams(newSelector))
}

const ActionsOptimization = ({ row, ODs, postdate, lowRange, history, translate }) => {
  return (
    <Box key={row.id}>
      <ActionIconButton
        size='small'
        title={translate('view.optimization.loadButton')}
        onClick={noPropagation(() => onClickLoad(row, ODs, postdate, lowRange, history))}
      >
        <Search />
      </ActionIconButton>
    </Box>
  )
}

ActionsOptimization.propTypes = {
  row: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  ODs: PropTypes.array.isRequired,
  postdate: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export const ActionsOptimizationWithRouter = connect(mapStateToProps)(withRouter(withLocalize(ActionsOptimization)))
