import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { getUserCommercialInfluences } from '../../store/actions/commercialInfluence'
import { openCommercialInfluence } from '../../store/actions/newCommercialInfluence'
import { newCommercialInfluenceSelector, userSettingsSelector } from '../../store/selectors'
import { getUserName } from '../../authentication/authentication'

import PageHeader from '../../component/PageHeader/pageHeader'
import Layout from '../layout'
import AddSection from './addSection'
import ButtonLink from '../../component/buttonLink'
import CommercialInfluenceTable from './table'

import { Collapse, Fade } from '@material-ui/core'
import Title from '../../component/title/title'
import { Add, sizes } from '../../component/icons'
import { Option } from '../../util/conditionnalComponent'

const CFade = Option(Fade)

const mapStateToProps = state => {
  const { isNewCommercialInfluenceOpen } = newCommercialInfluenceSelector(state)
  const { isCalibrate } = userSettingsSelector(state)
  return ({ isNewCommercialInfluenceOpen, isCalibrate })
}

class CommercialInfluenceView extends Component {
  componentDidMount() {
    store.dispatch(getUserCommercialInfluences(getUserName()))
  }

  render() {
    const { isNewCommercialInfluenceOpen, isCalibrate } = this.props
    const open = () => store.dispatch(openCommercialInfluence())

    return (
      <>
        <PageHeader>
          <Layout>
            <Title labelId='view.commercialInfluence.title'>
              <CFade condition={isCalibrate} in={!isNewCommercialInfluenceOpen}>
                {/* Bug in React need to have div */}
                <div>
                  <ButtonLink labelId='view.commercialInfluence.createNewButton' onClick={open} endIcon={<Add size={sizes.small} />} />
                </div>
              </CFade>
            </Title>
            <Collapse in={isNewCommercialInfluenceOpen}>
              <AddSection />
            </Collapse>
          </Layout>
        </PageHeader>
        <Layout>
          <CommercialInfluenceTable />
        </Layout>
      </>
    )
  }
}

CommercialInfluenceView.propTypes = { isNewCommercialInfluenceOpen: PropTypes.bool.isRequired, isCalibrate: PropTypes.bool.isRequired }

export default connect(mapStateToProps)(CommercialInfluenceView)
