import { fetch } from '../fetch'
import { resultHandler } from '../'
import { newError } from '../error'

import { api } from '../../../appSetting'

export const getUserSetting = userId => dispatch => {
  dispatch(requestedUserSettings(userId))

  const fetcher = fetch(`${api.userSetting}/${userId}`)
  const onError = newError('userSetting.error.get', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(receivedUserSettings, onError))
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const RequestedUserSettings = 'RequestedUserSettings'
export const requestedUserSettings = userId => ({ type: RequestedUserSettings, userId })

export const ReceivedUserSettings = 'ReceivedUserSettings'
export const receivedUserSettings = data => ({ type: ReceivedUserSettings, data })

export const ReceivedUserInfo = 'ReceivedUserInfo'
export const receivedUserInfo = user => ({ type: ReceivedUserInfo, user })
