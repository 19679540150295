import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { Translate } from 'react-localize-redux'

const WelcomeMessage = ({ name }) =>
  <Box fontWeight='fontWeightBold' fontSize='60px' color='#ebebeb'>
    <Translate id='welcomeMessage.welcome' data={{ name }} />
  </Box>

WelcomeMessage.propTypes = { name: PropTypes.string.isRequired }

export default WelcomeMessage
