import { Component } from 'react'

export default () => null

class Empty extends Component {
  render() {
    return null
  }
}

export const EmptyClassComponent = Empty
