import React from 'react'
import PropTypes from 'prop-types'

import { columns } from './settingOptimization'

import { connect } from 'react-redux'
import store from '../../../store'
import { optimizationSelectorForTableBody, tableOptimizationStateSelector, optimizationSelector } from '../../../store/selectors'

import TableTitle from '../../../component/table/tableTitle'
import PostdateSubTitle from '../../postdateSubTitle'
import createTableToolbar from '../../../component/tableToolbar/tableToolbar'
import EnhancedTable from '../../../component/table/table'
import createTableHead from '../../../event/tableHead'
import createTableHeadCell from '../../../event/tableHeadCell'
import createTableBody from '../../../event/tableBody'
import { ActionsOptimizationWithRouter, onClickLoad } from './tableActions'
import createDataValid from '../../../component/table/tableBody/dataValid'
import createTableFooter from '../../../event/tableFooter'
import { InfoOptimizationLocalized } from './tableIInfo'

import { optimizations as optimizationsView, changeOrdering } from '../../../store/actions/tableState/tableState'
import OptimizationInProgressIndicator from './optimizationInProgressIndicator'

const onChangeOrdering = (newOrder, newOrderBy) => store.dispatch(changeOrdering(optimizationsView)(newOrder, newOrderBy))

const EnhancedTableToolbar = createTableToolbar(tableOptimizationStateSelector)
const TableHeadCell = createTableHeadCell(tableOptimizationStateSelector, optimizationsView, onChangeOrdering)
const EnhancedTableHead = createTableHead(tableOptimizationStateSelector, optimizationsView, TableHeadCell)
const DataValid = createDataValid(ActionsOptimizationWithRouter, InfoOptimizationLocalized, onClickLoad)
const EnhancedTableBody = createTableBody(optimizationSelectorForTableBody, tableOptimizationStateSelector, DataValid)
const EnhancedTableFooter = createTableFooter(tableOptimizationStateSelector, optimizationsView)

const mapStateToProps = state => ({ optimizations: optimizationSelector(state).orderedOptimizations })

const OptimizationTable = ({ optimizations }) => (
  <>
    <TableTitle labelId='view.optimization.sectionTitle'>
      <OptimizationInProgressIndicator ml={2} labelId='view.optimization.optimizationRunning' />
    </TableTitle>
    <PostdateSubTitle />
    <EnhancedTableToolbar columns={columns} view={optimizationsView} />
    <EnhancedTable>
      <EnhancedTableHead />
      <EnhancedTableBody rows={optimizations} />
      <EnhancedTableFooter numberRows={optimizations.length} />
    </EnhancedTable>
  </>
)

OptimizationTable.propTypes = { optimizations: PropTypes.array.isRequired }

export default connect(mapStateToProps)(OptimizationTable)
