import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'

const DraggableComponent = (id, index) => {
  function DraggableElement(props) {
    return (
      <Draggable draggableId={id} index={index}>
        {
          provided => (
            <th
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              {...props}
            >
              {props.children}
            </th>
          )
        }
      </Draggable>
    )
  }

  DraggableElement.propTypes = {
    children: PropTypes.node
  }

  return DraggableElement
}

export default DraggableComponent
