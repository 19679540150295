import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

const Ratio = ({ numerator, denominator }) => <Box display='inline-block'>{numerator} / {denominator}</Box>

Ratio.propTypes = {
  numerator: PropTypes.string.isRequired,
  denominator: PropTypes.string.isRequired
}

export default Ratio
