import { fetch, fetchPost, fetchDelete } from '../fetch'
import { api } from '../../../appSetting'
import { calculateRows } from '../rows'
import { formatDimensions } from '../../../businessRules/dimensions'
import { rowsSelector, filterSelector, demandeForecastSelector, heatmapSelector, postdateSelector } from '../../selectors'
import { toUIInfluence, toInfluence } from './map'
import { resultHandler } from '..'

export const Wait = () => new Promise((resolve, reject) => setTimeout(function() { resolve() }, 1500))

const influenceBaseUrl = api.influence
const influencesBaseUrl = api.pendinginfluences

export const getInfluences = ({ Origin, Destination, POS, DateRange: { startDate, endDate }, Cabin, PathGroup }, postdate) => async dispatch => {
  dispatch(requestedPendingInfluences())

  const onError = errorInfluences('Error while getting influences')

  return dispatch(fetch(`${influencesBaseUrl}/${Origin}/${Destination}/${POS}/${startDate}/${endDate}/${Cabin}/${PathGroup}/${postdate.startDate}/${postdate.endDate}`, 0))
    .then(resultHandler(res => receivedPendingInfluences(res.map(toUIInfluence)), onError))
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const sendInfluence = (influence, setAlertOpenFlag) => async(dispatch, getState) => {
  const { postdate } = postdateSelector(getState())
  dispatch(sendingInfluences(influence))

  const sendAndWait = Promise.all([dispatch(fetchPost(`${influenceBaseUrl}/${postdate}`, toInfluence(influence))), Wait()]).then(values => values[0])
  const onError = errorInfluences('Error while sending influence')

  return sendAndWait
    .then(resultHandler(response => influenceSent({ ...toUIInfluence(influence), id: response.id, status: response.status }), onError))
    .then(dispatch)
    .then(() => dispatch(calculateRows()))
    .then(() => setAlertOpenFlag(true))
    .catch(error => dispatch(onError(error)))
}

export const deleteInfluence = id => async dispatch => {
  dispatch(deletingInfluence())

  const onError = errorInfluences('Error while deleting influence')

  return dispatch(fetchDelete(influenceBaseUrl, id))
    .then(resultHandler(() => influenceDeleted(id), onError))
    .then(dispatch)
    .then(() => dispatch(calculateRows()))
    .catch(error => dispatch(onError(error)))
}

export const formatLiveInfluence = () => (dispatch, getState) => {
  const state = getState()

  const rowsResult = rowsSelector(state)
  const filterSelectorResult = filterSelector(state)
  const demandeForecastResult = demandeForecastSelector(state)
  const heatmapResult = heatmapSelector(state)

  const newFiltersValue = filterSelectorResult.filters.present
  const newCalculatedDemandeForecast = demandeForecastResult.calculatedDemandeForecast
  const newShowHSE = heatmapResult.showHSE
  const newSelectors = filterSelectorResult.selectors
  const newRows = rowsResult.rows

  dispatch(changeInfluenceDimension({ ...formatDimensions(newFiltersValue, newSelectors, newRows, newCalculatedDemandeForecast, newShowHSE) }))
}

export const ReceivedPendingInfluences = 'ReceivedPendingInfluences'
export const receivedPendingInfluences = influences => ({ type: ReceivedPendingInfluences, influences })

export const RequestedPendingInfluences = 'RequestedPendingInfluences'
export const requestedPendingInfluences = () => ({ type: RequestedPendingInfluences })

export const SendingInfluences = 'SendingInfluences'
export const sendingInfluences = () => ({ type: SendingInfluences })

export const DeletingInfluence = 'DeletingInfluence'
export const deletingInfluence = () => ({ type: DeletingInfluence })

export const InfluenceSent = 'InfluenceSent'
export const influenceSent = data => ({ type: InfluenceSent, data })

export const InfluenceDeleted = 'InfluenceDeleted'
export const influenceDeleted = id => ({ type: InfluenceDeleted, id })

export const SetBaseDescription = 'SetBaseDescription'
export const setBaseDescription = description => ({ type: SetBaseDescription, description })

export const ChangeInfluenceDimension = 'ChangeInfluenceDimension'
export const changeInfluenceDimension = data => ({ type: ChangeInfluenceDimension, data })

export const ChangeInfluenceValue = 'ChangeInfluenceValue'
export const changeInfluenceValue = influence => ({ type: ChangeInfluenceValue, influence })

export const ChangeInfluenceString = 'ChangeInfluenceString'
export const changeInfluenceString = influenceString => ({ type: ChangeInfluenceString, influenceString })

export const LoadInfluence = 'loadInfluence'
export const loadInfluence = id => ({ type: LoadInfluence, id })

export const SendInfluencesDone = 'SendInfluencesDone'
export const sendInfluencesDone = () => ({ type: SendInfluencesDone })

export const ErrorInfluences = 'ErrorInfluences'
export const errorInfluences = message => error => ({ type: ErrorInfluences, error, message })

export const UpdateAlertOpenFlag = 'UpdateAlertOpenFlag'
export const updateAlertOpenFlag = flag => ({ type: UpdateAlertOpenFlag, flag })
