import React from 'react'
import PropTypes from 'prop-types'

import { TableRow } from '@material-ui/core'
import CustomTableCell from '../customTableCell'
import DataType from './datatype'

const toTableCell = (Actions, Info, DataType) => column => {
  const TableCellContainer = ({ children }) => <CustomTableCell align='left'>{children}</CustomTableCell>
  TableCellContainer.propTypes = { children: PropTypes.node.isRequired }

  const TableCellActions = props => <TableCellContainer><Actions {...props} /></TableCellContainer>
  const TableCellInfo = props => <TableCellContainer><Info {...props} /></TableCellContainer>
  const TableCellData = props => <TableCellContainer><DataType column={column} {...props} /></TableCellContainer>

  let TableCell
  switch (column.type) {
    case 'action':
      TableCell = TableCellActions
      break
    case 'info':
      TableCell = TableCellInfo
      break
    default:
      TableCell = TableCellData
  }

  return { column, TableCell }
}

export default function createDataValid(Actions, Info, onClick, DataEdit) {
  const dataTypeRow = DataEdit ?? DataType
  const DataValide = ({ rows, page, rowsPerPage, columnsOrdered }) => {
    const start = page * rowsPerPage

    return rows.slice(start, start + rowsPerPage).map((row, _index) => (
      <TableRow
        hover
        onClick={() => onClick && onClick(row)}
        tabIndex={-1}
        key={row.id}
      >
        {
          columnsOrdered.map(toTableCell(Actions, Info, dataTypeRow)).map(({ column, TableCell }) => <TableCell key={column.id} row={row} />)
        }
      </TableRow>
    ))
  }

  DataValide.propTypes = {
    rows: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    columnsOrdered: PropTypes.array.isRequired
  }

  return DataValide
}
