import { columns } from '../../../views/forecasting/review/settingsForecasting'
import { filterRows } from '../../../businessRules/rows'
import { filterSelector, demandeForecastSelector, tableMainStateSelector, influenceSelector, heatmapSelector } from '../../selectors'
import { stableSort, customSortBy, getSorter } from '../../../businessRules/array'

export const UpdateRows = 'UpdateRows'
export const updateRows = ({ rows, calculatedDemandeForecast2 }) => ({ type: UpdateRows, rows, calculatedDemandeForecast2, isCalculating: false })

export const CalculatingRows = 'CalculatingRows'
export const calculatingRows = () => ({ type: CalculatingRows })

const byHSE = showHSE => row => showHSE || (!showHSE && !row.isHSE)

export const calculateRows = () => (dispatch, getState) => {
  const state = getState()

  const filterSelectorResult = filterSelector(state)
  const demandeForecastResult = demandeForecastSelector(state)
  const tableStateResult = tableMainStateSelector(state)
  const influenceSelectorResult = influenceSelector(state)
  const heatmapResult = heatmapSelector(state)

  const newOrder = tableStateResult.order
  const newOrderBy = tableStateResult.orderBy
  const newFiltersValue = filterSelectorResult.filters.present
  const newCalculatedDemandeForecast = demandeForecastResult.calculatedDemandeForecast
  const newPendingInfluences = influenceSelectorResult.pendingInfluences
  const newShowHSE = heatmapResult.showHSE

  dispatch(calculatingRows())

  const calculateDynamicValue = row => {
    columns.forEach(column => {
      if (column.id === 'proPredictionPendingInfluenced' && column.dynamicCalcul) row[column.id] = column.dynamicCalcul(row, newPendingInfluences, {})
    })
    return row
  }

  const calculatedDemandeForecast2 = newCalculatedDemandeForecast.filter(byHSE(newShowHSE)).map(calculateDynamicValue)

  const rows = stableSort(calculatedDemandeForecast2, customSortBy(getSorter(newOrder))(newOrderBy)).filter(filterRows(newFiltersValue))

  return dispatch(updateRows({ rows, calculatedDemandeForecast2 }))
}
