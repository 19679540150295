import { fetch } from '../fetch'

import { resultHandlers } from '../'

import { isUndefinedOrNull } from '../../../businessRules/compare'
import { executeAll, saga } from '../../../businessRules/saga'
import { newError } from '../error'
import { newPostDate } from '../newCommercialInfluence'

import { api } from '../../../appSetting'

export const getPostdate = postdate => dispatch => {
  dispatch(requestedPostdate(postdate))

  if (isUndefinedOrNull(postdate)) {
    const fetcher = fetch(api.postdate)
    const onError = newError('postdate.error.message', fetcher.correlationId)

    return dispatch(fetcher)
      .then(resultHandlers(res => [receivedPostdate(res), newPostDate(res)], onError))
      .then(executeAll(dispatch))
      .catch(error => dispatch(onError(error)))
  } else {
    const optimizationInProgress = false
    return saga([receivedPostdate({ postdate, optimizationInProgress }), newPostDate({ postdate, optimizationInProgress })])(dispatch)
  }
}

export const RequestedPostdate = 'RequestedPostdate'
export const requestedPostdate = postdate => ({ type: RequestedPostdate, postdate })

export const ReceivedPostdate = 'ReceivedPostdate'
export const receivedPostdate = ({ postdate, optimizationInProgress }) => ({ type: ReceivedPostdate, postdate, optimizationInProgress })
