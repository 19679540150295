export const saga = events => async dispatch => {
  for (const event of events) {
    if (event instanceof Array) {
      await Promise.all(event.map(dispatch))
    } else {
      await dispatch(event)
    }
  }
}

export const executeAll = dispatch => async events => { for (const event of events) await dispatch(event) }
