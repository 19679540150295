import { createFilterTypeDateRange } from './filterTypeDateRange'
import { createFilterTypeList } from './filterTypeList'

const DateRange = {
  component: createFilterTypeDateRange
}

const List = {
  component: createFilterTypeList
}

const selection = type => type === 'DateRange' ? DateRange : List

export const FilterComponent = filter => selection(filter.type).component(filter)
