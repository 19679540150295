import { saga } from '../../businessRules/saga'

import { changingView, changeView } from '../actions/views'
import { calculateRows } from '../actions/rows'
import { viewSelectorEnum, DefaultInfluence } from '../../appSetting'
import { loadInfluence, changeInfluenceValue, formatLiveInfluence, getInfluences, setBaseDescription, changeInfluenceDimension } from '../actions/Influences'
import { setShowHSE, setFareCalculation } from '../actions/heatmap'
import { mergefirstLast, dateRangeUnixToMoment } from '../../businessRules/date'
import { updateSelectors, startNewFilterValues, applySelectorToFilter, startNewFilterValue, newFilter } from '../actions/filters'
import { changePage, main } from '../actions/tableState/tableState'
import { getDemandeForecast } from '../actions/DemandeForecast'
import { getOptimizations } from '../actions/optimization'
import { getCommercialInfluences } from '../actions/commercialInfluence'
import { formatDescription, mapSelectorsToCIParams } from '../../businessRules/dimensions'
import { getFullName } from '../../authentication/authentication'
import { recalculatingForcasting } from './filters'

const setFilters = ({ dow, classes, tod, dateRange }) => startNewFilterValue([newFilter('DoW', dow), newFilter('Class', classes), newFilter('ToD', tod), newFilter('DateRange', dateRange)])

export const reloadinfluenceSaga = row => saga([
  changingView(true),
  setShowHSE(row.DepartureDate.length === 1),
  loadInfluence(row.id),
  setFilters({ dow: row.DoW, classes: row.Class, tod: row.ToD, dateRange: [mergefirstLast(row.DepartureDate)] }),
  changePage(main)(0),
  changeView(viewSelectorEnum.List),
  calculateRows(),
  changingView(false)
])

export const loadOptimizationSaga = row => saga([
  changingView(true),
  [
    // setOptimizationStatus({ ...row, status: 'Read' }),
    setShowHSE(false),
    setFareCalculation(true),
    changeInfluenceValue(row.influence),
    setFilters({ dow: row.DoW, classes: row.Class, tod: row.ToD, dateRange: [row.firstLastDepartureDatesMoment] }),
    changePage(main)(0)
  ],
  calculateRows(),
  formatLiveInfluence(),
  changeView(viewSelectorEnum.List),
  changingView(false)
])

export const loadSingleOptimizationSaga = (row, newSelector, service, postdate) => (() => {
  const description = formatDescription(newSelector, getFullName(), service)

  return saga([
    updateSelectors({ ...newSelector, DateRange: dateRangeUnixToMoment(newSelector.DateRange) }),
    applySelectorToFilter(),
    changePage(main)(0),
    changingView(true),
    [
      getDemandeForecast(newSelector, postdate),
      getOptimizations(newSelector, postdate),
      getInfluences(newSelector, newSelector.DateRange),
      getCommercialInfluences(mapSelectorsToCIParams(newSelector))
    ],
    startNewFilterValues(),
    recalculatingForcasting(),
    setBaseDescription(description),
    changeInfluenceDimension({ description }),
    changeInfluenceValue(DefaultInfluence),
    [
      setShowHSE(false),
      setFareCalculation(true),
      changeInfluenceValue(row.influence),
      setFilters({ dow: row.DoW, classes: row.Class, tod: row.ToD, dateRange: [row.firstLastDepartureDatesMoment] }),
      changePage(main)(0)
    ],
    calculateRows(),
    formatLiveInfluence(),
    changeView(viewSelectorEnum.List),
    changingView(false)
  ])
})()

export const setShowHSESaga = showHSE => saga([setShowHSE(showHSE), calculateRows(), formatLiveInfluence()])
