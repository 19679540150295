import { fetch, fetchDelete, fetchPost } from '../fetch'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { resultHandler, resultHandlers } from '..'
import { toUICommercialInfluence, toCommercialInfluence } from './map'

export const getAllCommercialInfluences = () => dispatch => {
  dispatch(requestedCommercialInfluences({ type: 'Get ALL' }))

  const fetcher = fetch(`${api.commercialInfluence}`, {})
  const onError = newError('view.commercialInfluence.error.get', fetcher.correlationId)
  const toResult = resultHandler(commercialInfluences => receivedCommercialInfluences(commercialInfluences.map(toUICommercialInfluence)), onError)

  return dispatch(fetcher)
    .then(toResult)
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const getUserCommercialInfluences = id => dispatch => {
  dispatch(requestedCommercialInfluences({ type: 'Get Users commercial influence', id }))

  const fetcher = fetch(`${api.commercialInfluenceUser}/${id}`, {})
  const onError = newError('view.commercialInfluence.error.get', fetcher.correlationId)
  const toResult = resultHandler(commercialInfluences => receivedCommercialInfluences(commercialInfluences.map(toUICommercialInfluence)), onError)

  return dispatch(fetcher)
    .then(toResult)
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const getCommercialInfluences = ({ origin, destination, pos, cabin, pathType, startDate, endDate }) => dispatch => {
  dispatch(requestedCommercialInfluences({ origin, destination, pos, cabin, pathType, startDate, endDate }))

  const fetcher = fetch(`${api.commercialInfluence}/${origin}/${destination}/${pos}/${cabin}/${pathType}/${startDate}/${endDate}`, {})
  const onError = newError('view.commercialInfluence.error.get', fetcher.correlationId)
  const toResult = resultHandlers(commercialInfluences => receivedCommercialInfluences(commercialInfluences.map(toUICommercialInfluence)), onError)

  return dispatch(fetcher)
    .then(toResult)
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const RequestedCommercialInfluences = 'RequestedCommercialInfluences'
export const requestedCommercialInfluences = params => ({ type: RequestedCommercialInfluences, params })

export const ReceivedCommercialInfluences = 'ReceivedCommercialInfluences'
export const receivedCommercialInfluences = commercialInfluences => ({ type: ReceivedCommercialInfluences, commercialInfluences })

export const deleteCommercialInfluence = ({ id, partitionKey }) => dispatch => {
  dispatch(deletingCommercialInfluence({ id, partitionKey }))

  const fetcher = fetchDelete(`${api.commercialInfluence}`, id)
  const onError = newError('view.commercialInfluence.error.delete', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(() => deletedCommercialInfluence({ id }), onError))
    .then(results => dispatch(results))
    .catch(error => dispatch(onError(error)))
}

export const DeletingCommercialInfluence = 'DeletingCommercialInfluences'
export const deletingCommercialInfluence = commercialInfluence => ({ type: DeletingCommercialInfluence, commercialInfluence })

export const DeletedCommercialInfluence = 'DeletedCommercialInfluences'
export const deletedCommercialInfluence = commercialInfluence => ({ type: DeletedCommercialInfluence, commercialInfluence })

export const addCommercialInfluence = commercialInfluence => dispatch => {
  dispatch(addingCommercialInfluence(commercialInfluence))

  const fetcher = fetchPost(`${api.commercialInfluence}`, toCommercialInfluence(commercialInfluence))
  const onError = newError('view.commercialInfluence.error.add', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(({ id }) => addedCommercialInfluence({ ...toUICommercialInfluence(commercialInfluence), id }), onError))
    .then(results => dispatch(results))
    .catch(error => dispatch(onError(error)))
}

export const AddingCommercialInfluence = 'AddingCommercialInfluence'
export const addingCommercialInfluence = commercialInfluence => ({ type: AddingCommercialInfluence, commercialInfluence })

export const AddedCommercialInfluence = 'AddedCommercialInfluence'
export const addedCommercialInfluence = commercialInfluence => ({ type: AddedCommercialInfluence, commercialInfluence })
