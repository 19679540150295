export const OpenCommercialInfluence = 'OpenCommercialInfluence'
export const openCommercialInfluence = () => ({ type: OpenCommercialInfluence })

export const CommercialInfluenceDone = 'DoneCommercialInfluence'
export const commercialInfluenceDone = () => ({ type: CommercialInfluenceDone })

export const ModifyCommercialInfluence = 'ModifyCommercialInfluence'
export const modifyCommercialInfluence = data => ({ type: ModifyCommercialInfluence, data })

export const NewConfiguration = 'NewConfiguration'
export const newConfiguration = ({ classGroup, dayOfWeek, timeOfDay, pathType }) =>
  ({ type: NewConfiguration, classGroup, dow: dayOfWeek, tod: timeOfDay, pathType })

export const NewPostDate = 'NewPostDate'
export const newPostDate = data => ({ type: NewPostDate, data })
