import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

const style = _theme => ({
  form: {
    flexDirection: 'row',
    border: 'lightgrey 1px solid'
  }
})

const CustomFormControl = ({ classes, children, ...props }) => (
  <FormControl className={classes.form} variant='filled' {...props}>
    {children}
  </FormControl>
)

CustomFormControl.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.array
}

export default withStyles(style)(CustomFormControl)
