import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import Ratio from '../number/ratio'

import { Translate } from 'react-localize-redux'
import { formatRoundedNumber, formatDecimalNumber } from '../../businessRules/number'
import { Subtitle1 } from '../title/subtitle'

const fontSizeText = '40px'

const StatBoxContent = ({ value, textId }) => (
  <Box>
    <Box fontWeight='fontWeightBold'><Translate id={textId} /></Box>
    <Box fontWeight='fontWeightBold' fontSize={fontSizeText} lineHeight={fontSizeText}>
      {formatDecimalNumber(Math.abs(value.ratio))}
    </Box>
    <Subtitle1 fontWeight='fontWeightBold'>
      <Ratio numerator={formatRoundedNumber(value.sum.totalA)} denominator={formatRoundedNumber(value.sum.totalB)} />
    </Subtitle1>
  </Box>
)

StatBoxContent.propTypes = {
  value: PropTypes.object,
  textId: PropTypes.string.isRequired
}

export default StatBoxContent
