import { fetchPost } from '../fetch'
import { api } from '../../../appSetting'
import { newError } from '../error'
import { toUIDemandeForecast } from './map'
import { resultHandler } from '..'

export const getDemandeForecast = (selectedODPos, postdate) => dispatch => {
  dispatch(requestedDemandeForecast(selectedODPos, postdate))

  const fetcher = fetchPost(`${api.forecasting}/${postdate}`, { selectedODPos })
  const onError = newError('view.forecasting.error.get', fetcher.correlationId)

  return dispatch(fetcher)
    .then(resultHandler(res => receivedDemandeForecast(res.map(toUIDemandeForecast)), onError))
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const RequestedDemandeForcast = 'RequestedDemandeForcast'
export const requestedDemandeForecast = (selectedODPos, postdate) => ({ type: RequestedDemandeForcast, selectedODPos, postdate })

export const ReceivedDemandeForecast = 'ReceivedDemandeForecast'
export const receivedDemandeForecast = data => ({ type: ReceivedDemandeForecast, data })

export const RecalculatedForecasting = 'RecalculatedForecasting'
export const recalculatedForecasting = data => ({ type: RecalculatedForecasting, data })

export const UpdateToDFilter = 'UpdateToDFilter'
export const updateToDFilter = data => ({ type: UpdateToDFilter, data })
