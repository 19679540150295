import { GetItem, SetItem } from './actions/localStorage'

const appLocalStorage = localStorage

const getItem = name => JSON.parse(appLocalStorage.getItem(name)) ?? {}
const setItem = (name, data) => appLocalStorage.setItem(name, JSON.stringify(data))

export default store => next => async action => {
  const { name, data, type } = action ?? {}

  switch (type) {
    case GetItem:
      return getItem(name)
    case SetItem:
      return setItem(name, data)
    default:
      return next(action)
  }
}
