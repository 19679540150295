import { connect } from 'react-redux'
import { postdateSelector, optimizationSelector } from '../../../store/selectors'

import Indicator from '../../../component/Indicator'

const mapStateToProps = state => ({
  show: postdateSelector(state).optimizationInProgress && optimizationSelector(state).optimizations.length === 0
})

export default connect(mapStateToProps)(Indicator)
