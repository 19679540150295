import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActions from './tablePaginationAction'

import { TableFooter, TableRow } from '@material-ui/core'

const style = {
  root: {
    borderBottom: 0
  },
  toolbar: {
    padding: 0,
    height: 'unset',
    minHeight: 'unset'
  },
  caption: {
    order: 1
  },
  spacer: {
    order: 1
  },
  selectRoot: {
    order: 1
  }
}

function EnhanceTableFooter(props) {
  const { numberRows, page, rowsPerPage, classes, onChangeRowsPerPage, onChangePage } = props

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          variant='footer'
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={({ target: { value } }) => onChangeRowsPerPage(+value)}
          labelRowsPerPage='Show'
          labelDisplayedRows={({ from, to, count }) => `Showing ${from} - ${to} of ${count} results`}
          ActionsComponent={TablePaginationActions}
          count={numberRows}
          page={page}
          onChangePage={(_event, page) => onChangePage(page)}
          classes={classes}
        />
      </TableRow>
    </TableFooter>
  )
}

EnhanceTableFooter.propTypes = {
  numberRows: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  classes: PropTypes.object,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired
}

export default withStyles(style)(EnhanceTableFooter)
