import React from 'react'
import PropTypes from 'prop-types'

import { SingleSelect } from '../../../component/Select'

import { connect } from 'react-redux'
import store from '../../../store'
import { modifyUser } from '../../../store/actions/newUser'
import { configurationSelector } from '../../../store/selectors'

import { isEmpty } from '../../../businessRules/string'
import { toFormattedRole } from '../../../store/actions/users/map'

const setUser = data => store.dispatch(modifyUser(data))

const mapStateToProps = state => {
  const { userRolesSelectItem, user_roles } = configurationSelector(state)
  return ({ userRolesSelectItem, defaultRoles: user_roles })
}

const Role = ({ role, isSubmitClicked, userRolesSelectItem, defaultRoles }) =>
  <SingleSelect
    fullWidth
    error={isSubmitClicked && isEmpty(role)}
    elements={userRolesSelectItem}
    required
    titleId='selectorTitle'
    labelId='view.users.roles'
    updateValue={user_role => setUser({ user_role })}
    renderValue={toFormattedRole(defaultRoles)}
    value={role}
  />

Role.propTypes = {
  userRolesSelectItem: PropTypes.array.isRequired,
  defaultRoles: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  isSubmitClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Role)
