import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as AngleCollapseIcon } from '../../icons/aircanada/angle-collapse.svg'
import { ReactComponent as AngleExpandIcon } from '../../icons/aircanada/angle-expand.svg'
import { ReactComponent as ChecklistIcon } from '../../icons/aircanada/Checklist.svg'
import { ReactComponent as HeatmapIcon } from '../../icons/aircanada/heatmap.svg'
import { ReactComponent as OptimizerIcon } from '../../icons/aircanada/optimizer.svg'
import { ReactComponent as AddIcon } from '../../icons/aircanada/add.svg'
import { ReactComponent as MinusIcon } from '../../icons/aircanada/minus.svg'
import { ReactComponent as SearchIcon } from '../../icons/aircanada/search.svg'
import { ReactComponent as InfoIcon } from '../../icons/aircanada/info.svg'
import { ReactComponent as UndoIcon } from '../../icons/aircanada/Undo.svg'
import LogoIcon from '../../icons/aircanada/logo.png'

export const sizes = {
  small: 16,
  normal: 24,
  big: 32,
  doubleBig: 64
}

const defaultSize = sizes.normal

const propTypesNewIcon = {
  size: PropTypes.oneOf([sizes.small, sizes.normal, sizes.big, sizes.doubleBig])
}

const newIcon = Component => {
  const newIconComponent = ({ size }) => <Component width={size ?? defaultSize} height={size ?? defaultSize} />
  newIconComponent.propTypes = propTypesNewIcon
  return newIconComponent
}

const propTypesNewPngIcon = {
  size: PropTypes.oneOf([sizes.small, sizes.normal, sizes.big, sizes.doubleBig]),
  text: PropTypes.string,
  className: PropTypes.string
}

const newPngIcon = icon => {
  const newIconComponent = ({ size, text, className }) => <img src={icon} className={className} alt={text} title={text} width={size ?? defaultSize} height={size ?? defaultSize} />
  newIconComponent.propTypes = propTypesNewPngIcon
  return newIconComponent
}

const miror = Component => props => <Component style={{ transform: 'scaleX(-1)' }} {...props} />

export const AngleCollapse = newIcon(AngleCollapseIcon)
export const AngleExpand = newIcon(AngleExpandIcon)
export const Checklist = newIcon(ChecklistIcon)
export const Heatmap = newIcon(HeatmapIcon)
export const Optimizer = newIcon(OptimizerIcon)
export const Add = newIcon(AddIcon)
export const Minus = newIcon(MinusIcon)
export const Search = newIcon(SearchIcon)
export const Info = newIcon(InfoIcon)
export const Undo = newIcon(UndoIcon)
export const Redo = newIcon(miror(UndoIcon))
export const Logo = newPngIcon(LogoIcon)
