import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import ActionIconButton from '../../../component/ActionIconButton'
import { Search } from '../../../component/icons'

import store from '../../../store'
import { loadOptimizationSaga } from '../../../store/saga'
import { noPropagation } from '../../../businessRules/event'

import { withLocalize } from 'react-localize-redux'

export const onClickLoad = row => store.dispatch(loadOptimizationSaga(row))

const ActionsOptimization = ({ row, isLoading, translate }) => (
  <Box key={row.id}>
    <ActionIconButton
      size='small'
      disabled={isLoading}
      title={translate('view.optimization.loadButton')}
      onClick={noPropagation(() => onClickLoad(row))}
    >
      <Search />
    </ActionIconButton>
  </Box>
)

ActionsOptimization.propTypes = {
  row: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export const ActionsOptimizationWithRouter = withLocalize(ActionsOptimization)
