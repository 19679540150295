import React from 'react'
import PropTypes from 'prop-types'

import { MultiSelect } from '../../../component/Select'

import { connect } from 'react-redux'
import store from '../../../store'
import { modifyUser } from '../../../store/actions/newUser'
import { configurationSelector } from '../../../store/selectors'

import { isArrayEmpty, joinListWithFormat } from '../../../businessRules/array'
import { toFormattedRegion } from '../../../store/actions/users/map'

const setUser = data => store.dispatch(modifyUser(data))

const mapStateToProps = state => {
  const { regionsSelectItem, regions } = configurationSelector(state)
  return ({ regionsSelectItem, defaultRegions: regions })
}

const Regions = ({ regions, isSubmitClicked, regionsSelectItem, defaultRegions }) =>
  <MultiSelect
    fullWidth
    error={isSubmitClicked && isArrayEmpty(regions)}
    required
    elements={regionsSelectItem}
    titleId='selectorTitle'
    labelId='view.users.regions'
    updateValues={regions => setUser({ regions })}
    values={regions}
    renderValue={joinListWithFormat(toFormattedRegion(defaultRegions))}
  />

Regions.propTypes = {
  defaultRegions: PropTypes.array.isRequired,
  regionsSelectItem: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  isSubmitClicked: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(Regions)
