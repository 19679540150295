import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { updateFilterSaga } from '../../store/saga/filters'
import { filterSelector, demandeForecastSelector } from '../../store/selectors'

import { DateRange } from '../input'

const storeDispatch = (id, value) => store.dispatch(updateFilterSaga([{ id, value }]))

const mapStateToProps = state => {
  const demandeForecastResult = demandeForecastSelector(state)
  const filterResult = filterSelector(state)

  return {
    hseDates: demandeForecastResult.hseDates,
    currentDateRange: filterResult.selectors.DateRange,
    departureDate: filterResult.filters.present.DateRange
  }
}

export const createFilterTypeDateRange = filter => {
  const changeDateRange = ({ startDate, endDate }) => storeDispatch(filter.key, [{ startDate, endDate }])

  const FilterTypeDateRange = ({ hseDates, currentDateRange, departureDate }) =>
    <DateRange
      labelId='filters.dateRangeFilter'
      titleId='filters.filterTitle'
      changeDateRange={changeDateRange}
      limitDateRange={currentDateRange}
      dateRangeValue={departureDate[0] ?? {}}
      hseDates={hseDates}
      numberOfMonths={5}
    />

  FilterTypeDateRange.propTypes = {
    hseDates: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentDateRange: PropTypes.object.isRequired,
    departureDate: PropTypes.array.isRequired
  }

  return connect(mapStateToProps)(FilterTypeDateRange)
}
