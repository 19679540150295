import _ from 'lodash'
import { sum } from './number'

const DefaultValue = { value: 0 }

const byKey = current => ({ key }) => key === current

const formatKeyDimension = (x, y) => `${x}-${y}`
const formatKey = (xFilter, yFilter) => row => formatKeyDimension(row[xFilter], row[yFilter])

export const getDimensions = (rows, groupingRow) => (xFilter, yFilter) =>
  _.chain(rows)
    .groupBy(formatKey(xFilter, yFilter))
    .map((values, key) => ({ key, value: values.map(groupingRow).reduce(sum) }))
    .value()

export const getAbsoluteMax = (rows, groupingRow) => (xFilter, yFilter) =>
  absoluteMax(_.chain(rows)
    .groupBy(formatKey(xFilter, yFilter))
    .values()
    .value()
    .map(groupe => groupe.map(groupingRow).reduce(sum)))

const maxValue = (a, b) => Math.max(Math.abs(a), Math.abs(b))
const toFloorIndex = (value, length) => Math.floor(value * length)
const indexMinMax = (minBound, maxBound, length) => ({ indexMin: toFloorIndex(minBound, length), indexMax: toFloorIndex(maxBound, length) })

const absoluteMax = dimensions => {
  const orderedDimensions = dimensions.sort((a, b) => a - b)

  const { indexMax, indexMin } = indexMinMax(0.05, 0.95, orderedDimensions.length)

  return maxValue(orderedDimensions[indexMax], orderedDimensions[indexMin])
}

const normalizedValue = (dimensions, absoluteMax) => key => (dimensions.filter(byKey(key))[0] ?? DefaultValue).value / absoluteMax

export const getZValues = (dimensions, absoluteMax) => (xValues, yValues) =>
  yValues.map(y => xValues.map(x => normalizedValue(dimensions, absoluteMax)(formatKeyDimension(x, y))))
