import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const style = _theme => ({ root: { borderRadius: '3px' } })

const CustomChip = props => (<Chip color='secondary' size='small' deleteIcon={<Close fontSize='small' />} {...props} />)

export default withStyles(style)(CustomChip)
