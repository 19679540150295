import React from 'react'
import PropTypes from 'prop-types'

import { DateFormat } from '../../appSetting'

import { Box } from '@material-ui/core'
import CustomFormControl from '../customFormControl/customFormControl'
import CustomInputLabel from './inputLabel'
import DefaultButton from '../buttons/defaultButton'
import { CalendarToday } from '@material-ui/icons'

import { isInDateRange, isDayContainIn } from '../../businessRules/date'
import { isUndefinedOrNull } from '../../businessRules/compare'
import { sentence } from '../../businessRules/string'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import { START_DATE, END_DATE } from 'react-dates/constants'

import { Translate, withLocalize } from 'react-localize-redux'

const HSEinfo = () =>
  <Box display='flex' alignItems='center' padding={1}>
    <Box width='39px' height='38px' className='CalendarDay__default CalendarDay__highlighted_calendar' mr={1} />
    <Translate id='dateRange.info' />
  </Box>

function DateRange(props) {
  const { labelId, titleId, numberOfMonths, changeDateRange, limitDateRange, dateRangeValue, hseDates, translate } = props

  const [closing, setClosing] = React.useState(false)
  const [closed, setClosed] = React.useState(false)
  const [focusedInput, setfocusedInput] = React.useState(null)
  const [dates, setDates] = React.useState(dateRangeValue)

  const onClose = _range => setClosed(true)

  React.useEffect(() => {
    if (closed && closing) {
      setClosing(false)
      changeDateRange(dates)
    }
  }, [closed, closing, dates])

  React.useEffect(() => {
    setDates(dateRangeValue)
  }, [dateRangeValue])

  return (
    <CustomFormControl fullWidth>
      <CustomInputLabel shrink><Translate id={labelId} /></CustomInputLabel>
      <DateRangePicker
        calendarInfoPosition='top'
        renderCalendarInfo={isUndefinedOrNull(hseDates) ? undefined : HSEinfo}
        isDayHighlighted={isUndefinedOrNull(hseDates) ? undefined : isDayContainIn(hseDates)}
        startDate={dates.startDate}
        startDateId='startDateId'
        endDate={dates.endDate}
        endDateId='endDateId'
        onDatesChange={({ startDate, endDate }) => {
          if (focusedInput === START_DATE && !!startDate) setDates({ startDate, endDate: dates.endDate })
          if (focusedInput === END_DATE && !!endDate) setDates({ startDate: dates.startDate, endDate })
          setClosing(true)
        }}
        onClose={onClose}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setfocusedInput(focusedInput)}
        minDate={limitDateRange.startDate}
        maxDate={limitDateRange.endDate}
        isOutsideRange={day => !isInDateRange(limitDateRange, day)}
        noBorder
        displayFormat={DateFormat}
        numberOfMonths={numberOfMonths}
        customArrowIcon={<Translate id='dateRange.to' />}
      />
      <DefaultButton size='small' title={sentence(titleId, labelId)(translate)} variant='contained' onClick={() => setfocusedInput(START_DATE)}>
        <CalendarToday />
      </DefaultButton>
    </CustomFormControl>
  )
}

DateRange.propTypes = {
  labelId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  numberOfMonths: PropTypes.number.isRequired,
  changeDateRange: PropTypes.func.isRequired,
  limitDateRange: PropTypes.object.isRequired,
  dateRangeValue: PropTypes.object.isRequired,
  hseDates: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func.isRequired
}

export default withLocalize(DateRange)
