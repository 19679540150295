import React from 'react'
import PropTypes from 'prop-types'

import { Subtitle2 } from './subtitle'

import { Translate } from 'react-localize-redux'

const CustomSubTitle = ({ labelId, onClick, children }) => <Subtitle2 onClick={onClick}><Translate id={labelId} /> {children}</Subtitle2>

CustomSubTitle.propTypes = {
  labelId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default CustomSubTitle
