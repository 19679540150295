import React from 'react'
import PropTypes from 'prop-types'

import { MultiSelect, newSelectItem } from '../Select'

import { connect } from 'react-redux'
import store from '../../store'
import { updateFilterSaga } from '../../store/saga/filters'
import { newFilter } from '../../store/actions/filters'
import { filterSelector } from '../../store/selectors'

import { joinListWithFormat } from '../../businessRules/array'

const storeDispatch = filterValue => store.dispatch(updateFilterSaga([filterValue]))

export const createFilterTypeList = filter => {
  function FilterTypeList({ isLoading }) {
    const elements = filter.elements.map(value => newSelectItem(value, value, filter.format(value)))

    const mapStateToProps = state => ({
      values: filterSelector(state).filters.present[filter.key]
    })

    const MultiSelectConnected = connect(mapStateToProps)(MultiSelect)

    return (
      <MultiSelectConnected
        fullWidth
        elements={elements}
        titleId='filters.filterTitle'
        labelId={filter.labelId}
        updateValues={values => storeDispatch(newFilter(filter.key, values))}
        renderValue={joinListWithFormat(filter.format)}
        isLoading={isLoading}
      />
    )
  }

  FilterTypeList.propTypes = { isLoading: PropTypes.bool }

  return FilterTypeList
}
