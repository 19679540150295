import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import ActionIconButton from '../../component/ActionIconButton'
import { Minus } from '../../component/icons'

import store from '../../store'
import { deleteCommercialInfluence } from '../../store/actions/commercialInfluence'

import { withLocalize } from 'react-localize-redux'
import { Option } from '../../util/conditionnalComponent'
import { userSettingsSelector } from '../../store/selectors'
import { connect } from 'react-redux'

const clear = (id, partitionKey) => store.dispatch(deleteCommercialInfluence({ id, partitionKey }))

const CActionIconButton = Option(ActionIconButton)

const mapStateToProps = state => {
  const { isCalibrate } = userSettingsSelector(state)
  return ({ isCalibrate })
}

const Actions = ({ isCalibrate, row, isLoading, translate }) => (
  <Box key={row.id}>
    <CActionIconButton
      condition={isCalibrate}
      size='small'
      title={translate('view.influence.delete')}
      disabled={isLoading}
      onClick={() => clear(row.id, row.partitionKey)}
    >
      <Minus />
    </CActionIconButton>
  </Box>
)

Actions.propTypes = {
  isCalibrate: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

export const ActionsWithRouter = connect(mapStateToProps)(withLocalize(Actions))
