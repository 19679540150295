import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Popover } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import style from './style'

class ButtonAndPopover extends Component {
  state = {
    anchorEl: null
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { Button, popoverContent, haveFixSize, classes } = this.props

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
      <>
        <Button open={open} aria-describedby={id} onClick={e => this.handleClick(e)} variant='contained' />
        <Popover
          id={id}
          classes={{ paper: haveFixSize ? classes.popover : null }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => this.handleClose()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          {popoverContent}
        </Popover>
      </>
    )
  }
}

ButtonAndPopover.propTypes = {
  Button: PropTypes.func.isRequired,
  popoverContent: PropTypes.element.isRequired,
  haveFixSize: PropTypes.bool,
  classes: PropTypes.object
}

export default withStyles(style)(ButtonAndPopover)
