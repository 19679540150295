import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Box, withStyles } from '@material-ui/core'

import {
  sumCumulativeBooking,
  sumBookingRemaining,
  sumBookingRMAI,
  sumBookingInfluencedRemaining,
  sumError,
  yoyOf,
  yoyImprovement
} from '../../businessRules/statistic'

import StatBox from './statBox'
import ProgressBar from '../progressBar'
import GridFullHeight from '../gridViewLayout/gridFullHeight'
import LiveInfluenceForecast from './liveInfluenceForecast'

import { connect } from 'react-redux'
import { rowsSelector, userSettingsSelector } from '../../store/selectors'
import StatBoxContent from './content'
import { Translate } from 'react-localize-redux'
import Spacer from '../spacer/spacer'
import { BetaTag } from '../tag'
import Empty from '../empty/empty'

const xs = 12

const FlexItemGrid = withStyles(_theme => ({ root: { display: 'flex' } }))(Grid)
const mapStateToProps = state => ({ rows: rowsSelector(state).rows, showConfidenceScore: userSettingsSelector(state).showConfidenceScore })

const GridTop = withStyles(theme => ({ root: { borderTop: '1px solid ' + theme.palette.borderElement, paddingTop: '7px' } }))(Grid)

const Statistic = ({ rows, showConfidenceScore }) => {
  const errorValue = yoyImprovement(sumError(rows))

  return (
    <Box pb={2} height='100%'>
      <GridFullHeight container spacing={1}>
        <FlexItemGrid item xs={xs}>
          <StatBox>
            <StatBoxContent value={yoyOf(sumCumulativeBooking(rows))} textId='statistic.booking' />
          </StatBox>
        </FlexItemGrid>
        <FlexItemGrid item xs={xs}>
          <StatBox>
            <StatBoxContent value={yoyOf(sumBookingRemaining(rows))} textId='statistic.hseforecast' />
          </StatBox>
        </FlexItemGrid>
        <FlexItemGrid item xs={xs}>
          <StatBox>
            <StatBoxContent value={yoyOf(sumBookingInfluencedRemaining(rows))} textId='statistic.influencedforecast' />
          </StatBox>
        </FlexItemGrid>
        <FlexItemGrid item xs={xs}>
          <BetaTag>
            <StatBox>
              <Grid container>
                <Grid item xs={12}>
                  <StatBoxContent value={yoyOf(sumBookingRMAI(rows))} textId='statistic.aiforecast' />
                </Grid>
                {showConfidenceScore ? (
                  <GridTop item xs={12}>
                    <Box fontWeight='fontWeightBold' fontSize={12}>
                      <Translate id={isNaN(errorValue) ? 'statistic.confidenceScoreDisabled' : 'statistic.confidenceScore'} />
                    </Box>
                    <Spacer />
                    <ProgressBar value={errorValue} />
                  </GridTop>
                ) : <Empty />}
              </Grid>
            </StatBox>
          </BetaTag>
        </FlexItemGrid>
        <FlexItemGrid item xs={xs}>
          <StatBox>
            <LiveInfluenceForecast rows={rows} />
          </StatBox>
        </FlexItemGrid>
      </GridFullHeight>
    </Box>
  )
}

Statistic.propTypes = { rows: PropTypes.array.isRequired, showConfidenceScore: PropTypes.bool.isRequired }

export default connect(mapStateToProps)(Statistic)
