import { Fetch, FetchPost, FetchPut, FetchDelete } from './actions/fetch'
import { loginApiFetch } from '../authentication/authentication'

export default fetchImplementation => store => next => async action => {
  const { url, params, data, type } = action ?? {}

  const keys = Object.keys(params ?? {}).map(p => `${p}=${params[p]}`).join('&')

  const headers = {
    'Content-Type': 'application/json',
    'X-Correlation-ID': action.correlationId,
    'X-Requested-With': 'XMLHttpRequest'
  }

  switch (type) {
    case Fetch:
      return loginApiFetch(
        fetchImplementation,
        `${url}?${keys}`,
        { headers },
        action.correlationId
      )
    case FetchPost:
      return loginApiFetch(
        fetchImplementation,
        url, {
          method: 'post',
          body: JSON.stringify(data),
          headers
        },
        action.correlationId
      )
    case FetchPut:
      return loginApiFetch(
        fetchImplementation,
        url, {
          method: 'put',
          body: JSON.stringify(data),
          headers
        },
        action.correlationId
      )
    case FetchDelete:
      return loginApiFetch(
        fetchImplementation,
        url, {
          method: 'delete',
          body: JSON.stringify(data),
          headers
        },
        action.correlationId
      )
    default:
      return next(action)
  }
}
