import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

const MiddleContainer = ({ children }) =>
  <Grid container>
    <Grid item xs={false} lg={2} />
    <Grid item xs={12} lg={8}>
      {children}
    </Grid>
    <Grid item xs={false} lg={2} />
  </Grid>

MiddleContainer.propTypes = { children: PropTypes.node.isRequired }

export default MiddleContainer
