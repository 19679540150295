import { withStyles } from '@material-ui/core/styles'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

export const DefaultToggleButton = withStyles(theme => ({
  root: {
    border: 'none',
    borderRadius: 0,
    color: theme.palette.grey,
    fill: theme.icon.base,
    '&:hover': {
      fill: theme.icon.hover
    }
  },
  selected: {
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.bgColor['color-white'] + ' !important',
    boxShadow: '2px -3px 5px 1px rgba(0, 0, 0, 0.04)',
    color: theme.palette.secondary.main + ' !important',
    fill: theme.icon.hover
  }
}))(ToggleButton)

export const FilterToggleButton = withStyles(theme => ({
  root: {
    border: 'none',
    borderRadius: 0,
    height: '35px',
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.buttons.main,
    color: theme.palette.buttons.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.buttons.dark,
      color: theme.palette.buttons.contrastText
    }
  },
  selected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark + '!important',
      color: theme.palette.primary.contrastText + '!important'
    }
  }
}))(ToggleButton)

export const FilterToggleButtonGroup = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))(ToggleButtonGroup)

export const DefaultToggleButtonGroup = withStyles(_theme => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    display: 'inherit',
    justifyContent: 'inherit'
  }
}))(ToggleButtonGroup)
