import React from 'react'
import PropTypes from 'prop-types'

import { viewSelectorEnum } from '../../appSetting'

import { Checklist, sizes, Heatmap, Optimizer } from '../icons'
import { DefaultToggleButton, DefaultToggleButtonGroup } from '../buttons/defaultToggle'
import { Box } from '@material-ui/core'

import { connect } from 'react-redux'
import store from '../../store'
import { changeView } from '../../store/actions/views'
import { viewsSelector } from '../../store/selectors'

import { withLocalize, Translate } from 'react-localize-redux'

const mapStateToProps = state => ({ currentView: viewsSelector(state).view })

const iconSize = sizes.big

const setView = view => store.dispatch(changeView(view ?? viewSelectorEnum.None))

const ViewSelector = ({ currentView, translate }) => (
  <Box display='flex' alignItems='flex-end'>
    <DefaultToggleButtonGroup value={currentView} exclusive onChange={(_event, view) => setView(view)}>
      <DefaultToggleButton title={translate('viewSelector.optimizerTitle')} value={viewSelectorEnum.Priority}>
        <Translate id='viewSelector.optimizer' />&nbsp;<Optimizer size={iconSize} />
      </DefaultToggleButton>
      <DefaultToggleButton title={translate('viewSelector.forecastingTitle')} value={viewSelectorEnum.List}>
        <Translate id='viewSelector.forecasting' />&nbsp;<Heatmap size={iconSize} />
      </DefaultToggleButton>
      <DefaultToggleButton title={translate('viewSelector.pendingInfluenceTitle')} value={viewSelectorEnum.PendingInfluence}>
        <Translate id='viewSelector.pendingInfluence' />&nbsp;<Checklist size={iconSize} />
      </DefaultToggleButton>
    </DefaultToggleButtonGroup>
  </Box>
)

ViewSelector.propTypes = {
  currentView: PropTypes.oneOf(Object.values(viewSelectorEnum)).isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withLocalize(ViewSelector))
