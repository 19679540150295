import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const styleButton = _theme => ({
  root: {
    minWidth: '0',
    height: 'auto',
    padding: 0
  }
})

export const StyledButton = withStyles(styleButton)(Button)
