import { resultHandler } from '..'
import { fetch } from '../fetch'
import { newError } from '../error'

import { api } from '../../../appSetting'

export const OpenUser = 'OpenUser'
export const openUser = () => ({ type: OpenUser })

export const UserDone = 'DoneUser'
export const userDone = () => ({ type: UserDone })

export const ModifyUser = 'ModifyUser'
export const modifyUser = data => ({ type: ModifyUser, data })

export const getNewUsers = () => dispatch => {
  dispatch(requestedNewUsers())

  const fetcher = fetch(`${api.newUsers}`, {})
  const onError = newError('view.users.error.get', fetcher.correlationId)
  const toResult = resultHandler(users => receivedNewUsers(users), onError)

  return dispatch(fetcher)
    .then(toResult)
    .then(dispatch)
    .catch(error => dispatch(onError(error)))
}

export const RequestedNewUsers = 'RequestedNewUsers'
export const requestedNewUsers = params => ({ type: RequestedNewUsers, params })

export const ReceivedNewUsers = 'ReceivedNewUsers'
export const receivedNewUsers = users => ({ type: ReceivedNewUsers, users })
