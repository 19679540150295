import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, Typography, ListItemIcon } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import { Translate, withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router'

import { routes } from '../../appSetting'

const ItemLogin = ({ translate, history }) => (
  <MenuItem title={translate('project.login')} onClick={() => history.push(routes.welcome)}>
    <ListItemIcon title={translate('project.login')}>
      <ExitToApp fontSize='small' />
    </ListItemIcon>
    <Typography variant='inherit' noWrap><Translate id='project.login' /></Typography>
  </MenuItem>
)

ItemLogin.propTypes = {
  translate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(withLocalize(ItemLogin))
