import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import store from '../../store'
import { getUsers } from '../../store/actions/users'
import { openUser, getNewUsers } from '../../store/actions/newUser'
import { newUserSelector, userSettingsSelector } from '../../store/selectors'

import PageHeader from '../../component/PageHeader/pageHeader'
import Layout from '../layout'
import AddSection from './addSection'
import ButtonLink from '../../component/buttonLink'
import { Add, sizes } from '../../component/icons'
import Table from './table'

import { Collapse, Fade } from '@material-ui/core'
import Title from '../../component/title/title'
import { Option } from '../../util/conditionnalComponent'

const CFade = Option(Fade)

const mapStateToProps = state => ({
  isNewUserOpen: newUserSelector(state).isNewUserOpen,
  isAdmin: userSettingsSelector(state).isAdmin
})

class UsersView extends Component {
  componentDidMount() {
    store.dispatch(getUsers())
    store.dispatch(getNewUsers())
  }

  render() {
    const { isNewUserOpen, isAdmin } = this.props
    const open = () => store.dispatch(openUser())

    return (
      <>
        <PageHeader>
          <Layout>
            <Title labelId='view.users.title'>
              <CFade condition={isAdmin} in={!isNewUserOpen}>
                {/* Bug in React need to have div or CBox */}
                <div>
                  <ButtonLink labelId='view.users.createNewButton' onClick={open} endIcon={<Add size={sizes.small} />} />
                </div>
              </CFade>
            </Title>
            <Collapse in={isNewUserOpen}>
              <AddSection />
            </Collapse>
          </Layout>
        </PageHeader>
        <Layout>
          <Table />
        </Layout>
      </>
    )
  }
}

UsersView.propTypes = { isNewUserOpen: PropTypes.bool.isRequired, isAdmin: PropTypes.bool.isRequired }

export default connect(mapStateToProps)(UsersView)
