import { IconButton, withStyles } from '@material-ui/core'

const style = theme => ({
  root: {
    fill: theme.icon.base,
    '&:hover': {
      fill: theme.icon.hover
    }
  }
})

export default withStyles(style)(IconButton)
