import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import ActionIconButton from '../../component/ActionIconButton'
import { Minus } from '../../component/icons'

import { EditOutlined, CancelOutlined } from '@material-ui/icons'

import store from '../../store'
import { deleteUser } from '../../store/actions/users'
import { modifyUser } from '../../store/actions/newUser'

import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { newUserSelector } from '../../store/selectors'
import { modifyUserSaga, cancelUserSaga } from '../../store/saga/newUser'
import Empty from '../../component/empty/empty'

const clear = id => store.dispatch(deleteUser({ id }))
const edit = row => store.dispatch(modifyUser(row))
const edited = row => store.dispatch(modifyUserSaga(row))
const cancel = () => store.dispatch(cancelUserSaga())

const editing = (row, user) => row.email === user.email

const Actions = ({ user, row, isLoading, translate }) => (
  <Box key={row.id}>
    <ActionIconButton
      size='small'
      title={translate('view.users.edit')}
      disabled={isLoading}
      onClick={() => editing(row, user) ? edited(user) : edit(row)}
    >
      <EditOutlined />
    </ActionIconButton>
    {editing(row, user) ? (
      <ActionIconButton
        size='small'
        title={translate('view.users.cancel')}
        disabled={isLoading}
        onClick={cancel}
      >
        <CancelOutlined />
      </ActionIconButton>
    ) : <Empty />}
    <ActionIconButton
      size='small'
      title={translate('view.users.delete')}
      disabled={isLoading}
      onClick={() => clear(row.id)}
    >
      <Minus />
    </ActionIconButton>
  </Box>
)

Actions.propTypes = {
  user: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({ user: newUserSelector(state).user })

export const ActionsWithRouter = connect(mapStateToProps)(withLocalize(Actions))
