import React from 'react'
import PropTypes from 'prop-types'

import { columns } from './settingPendingInfluence'

import TableTitle from '../../../component/table/tableTitle'
import PostdateSubTitle from '../../postdateSubTitle'
import createTableToolbar from '../../../component/tableToolbar/tableToolbar'
import EnhancedTable from '../../../component/table/table'
import createTableHead from '../../../event/tableHead'
import createTableHeadCell from '../../../event/tableHeadCell'
import createTableBody from '../../../event/tableBody'
import createDataValid from '../../../component/table/tableBody/dataValid'
import { ActionsInfluenceWithRouter, onClickLoad } from './tableActions'
import createTableFooter from '../../../event/tableFooter'

import { connect } from 'react-redux'
import store from '../../../store'
import { pendingInfluences, changeOrdering } from '../../../store/actions/tableState/tableState'
import { tablePendingInfluencesStateSelector, pendingInfluencesSelectorForTableBody, influenceSelector } from '../../../store/selectors'

const onChangeOrdering = (newOrder, newOrderBy) => store.dispatch(changeOrdering(pendingInfluences)(newOrder, newOrderBy))

const EnhancedTableToolbar = createTableToolbar(tablePendingInfluencesStateSelector)
const TableHeadCell = createTableHeadCell(tablePendingInfluencesStateSelector, pendingInfluences, onChangeOrdering)
const EnhancedTableHead = createTableHead(tablePendingInfluencesStateSelector, pendingInfluences, TableHeadCell)
const DataValid = createDataValid(ActionsInfluenceWithRouter, undefined, onClickLoad)
const EnhancedTableBody = createTableBody(pendingInfluencesSelectorForTableBody, tablePendingInfluencesStateSelector, DataValid)
const EnhancedTableFooter = createTableFooter(tablePendingInfluencesStateSelector, pendingInfluences)

const mapStateToProps = state => ({ pendingInfluenceRows: influenceSelector(state).pendingInfluences })

const TableInfluences = ({ pendingInfluenceRows }) => (
  <>
    <TableTitle labelId='view.influence.sectionTitle' />
    <PostdateSubTitle />
    <EnhancedTableToolbar columns={columns} view={pendingInfluences} />
    <EnhancedTable>
      <EnhancedTableHead />
      <EnhancedTableBody rows={pendingInfluenceRows} />
      <EnhancedTableFooter numberRows={pendingInfluenceRows.length} />
    </EnhancedTable>
  </>
)

TableInfluences.propTypes = { pendingInfluenceRows: PropTypes.array.isRequired }

export default connect(mapStateToProps)(TableInfluences)
