import { NewError } from '../../actions/error'

const initialState = {
  hasError: false,
  errors: [],
  error: {
    customErrorText: undefined,
    statusText: undefined,
    status: undefined,
    url: undefined,
    correlationId: undefined
  }
}

const toError = (messageId, correlationId, { statusText, status, url, errorName, errorMessage, errorStack }) => ({
  messageId,
  correlationId,
  statusText,
  status,
  url,
  errorName,
  errorMessage,
  errorStack
})

export default (state = initialState, action) => {
  switch (action.type) {
    case NewError: {
      const { messageId, correlationId, error } = action

      return Object.assign({}, state, {
        hasError: true,
        errors: [...state.errors, toError(messageId, correlationId, error)],
        error: toError(messageId, correlationId, error)
      })
    }
    default:
      return state
  }
}
