import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { withLocalize, Translate } from 'react-localize-redux'
import { Button } from '@material-ui/core'

const selectLanguage = (languages, activeLanguage) => languages.filter(language => language.code !== activeLanguage.code)[0].code

const NoWidthButton = withStyles(_theme => ({ text: { minWidth: '34px' } }))(Button)

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage, translate }) =>
  <NoWidthButton title={translate('project.language')} variant='text' onClick={() => setActiveLanguage(selectLanguage(languages, activeLanguage))}>
    <Translate id='project.language' />
  </NoWidthButton>

LanguageToggle.propTypes = {
  languages: PropTypes.array.isRequired,
  activeLanguage: PropTypes.object,
  setActiveLanguage: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(LanguageToggle)
