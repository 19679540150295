import { saga } from '../../../businessRules/saga'

import { DefaultInfluence } from '../../../appSetting'

import { updateFilterValue, undoFilter, redoFilter, updateSelectors, startNewFilterValues, applySelectorToFilter } from '../../actions/filters'
import { calculateRows } from '../../actions/rows'
import { getInfluences, setBaseDescription, changeInfluenceValue, formatLiveInfluence, changeInfluenceDimension } from '../../actions/Influences'
import { formatDescription, mapSelectorsToCIParams } from '../../../businessRules/dimensions'
import { getDemandeForecast, recalculatedForecasting } from '../../actions/DemandeForecast'
import { getOptimizations } from '../../actions/optimization'
import { getCommercialInfluences } from '../../actions/commercialInfluence'
import { demandeForecastSelector, commercialInfluenceSelector, postdateSelector } from '../../selectors'
import { executeCommercialBias } from '../../../businessRules/rows'
import { changePage, main } from '../../actions/tableState/tableState'
import { getFullName } from '../../../authentication/authentication'
import { dateRangeUnixToMoment } from '../../../businessRules/date'

export const updateFilterSaga = newFilterValue => saga([updateFilterValue(newFilterValue), changePage(main)(0), calculateRows(), formatLiveInfluence()])
export const undoFilterSaga = () => saga([undoFilter(), changePage(main)(0), changeInfluenceValue(DefaultInfluence), calculateRows(), formatLiveInfluence()])
export const redoFilterSaga = () => saga([redoFilter(), changePage(main)(0), changeInfluenceValue(DefaultInfluence), calculateRows(), formatLiveInfluence()])
export const clearFilterSaga = () => saga([startNewFilterValues(), changePage(main)(0), changeInfluenceValue(DefaultInfluence), calculateRows(), formatLiveInfluence()])

export const applySelectors = (newSelector, service, postdate, startNewFilters = true) => (() => {
  const description = formatDescription(newSelector, getFullName(), service)
  let services = [
    updateSelectors({ ...newSelector, Term: newSelector.Term, DateRange: dateRangeUnixToMoment(newSelector.DateRange) }),
    applySelectorToFilter(),
    changePage(main)(0),
    [
      getDemandeForecast(newSelector, postdate),
      getOptimizations(newSelector, postdate),
      getInfluences(newSelector, newSelector.DateRange),
      getCommercialInfluences(mapSelectorsToCIParams(newSelector))
    ],
    startNewFilterValues(),
    recalculatingForcasting(),
    setBaseDescription(description),
    changeInfluenceDimension({ description }),
    changeInfluenceValue(DefaultInfluence),
    calculateRows(),
    formatLiveInfluence()
  ]
  // stop new filters for single od tables filters
if (startNewFilters === false) {
  services = [
    updateSelectors({ ...newSelector, Term: newSelector.Term, DateRange: dateRangeUnixToMoment(newSelector.DateRange) }),
    applySelectorToFilter(),
    changePage(main)(0),
    [
      getDemandeForecast(newSelector, postdate),
      getOptimizations(newSelector, postdate),
      getInfluences(newSelector, newSelector.DateRange),
      getCommercialInfluences(mapSelectorsToCIParams(newSelector))
    ],
    recalculatingForcasting(),
    setBaseDescription(description),
    changeInfluenceDimension({ description }),
    changeInfluenceValue(newSelector.Influence),
    calculateRows(),
    formatLiveInfluence()
  ]
}
  return saga(services)
})()

export const applyPartialSelectors = newSelector => saga([updateSelectors(newSelector)])

export const recalculatingForcasting = () => (dispatch, getState) => {
  const state = getState()
  const { demandeForecast } = demandeForecastSelector(state)
  const { commercialInfluences } = commercialInfluenceSelector(state)
  const { postdateMoment } = postdateSelector(state)
  return dispatch(recalculatedForecasting(demandeForecast.map(executeCommercialBias(commercialInfluences, postdateMoment))))
}
