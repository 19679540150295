import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { login, isLoggedIn } from './authentication'

class LoginComponent extends Component {
  async componentDidMount() {
    if (!isLoggedIn()) await login()
  }

  render() {
    const { children } = this.props
    return <>{isLoggedIn() ? children : null}</>
  }
}

LoginComponent.propTypes = { children: PropTypes.node.isRequired }

export default LoginComponent
export const withLoginApi = Component => ({ children, ...props }) => <LoginComponent><Component {...props}>{children}</Component></LoginComponent>
