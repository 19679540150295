import { receivedUserInfo } from '../UserSettings'
import { getClient } from '../../../authentication/authentication'

import { newError } from '../error'
import uuid from 'uuid/v4'

const graphApiUrl = {
  getPhotoUrl: '/me/photos/48x48/$value',
  getInfoUser: '/me'
}

const toUrl = URL.createObjectURL
const toGivenName = ({ givenName }) => givenName

const getFromMSGraphApi = url => client => client.api(url).get()

const INVALID_MAILBOX = 'MailboxNotEnabledForRESTAPI'
const RESOURCE_NOT_FOUND = 'ResourceNotFound'
const ITEM_NOT_FOUND = 'ErrorItemNotFound'

const showWarning = (message, error) => console.warn(message, error)
const catchThis = onError => error => {
  const { code } = error

  switch (code) {
    case INVALID_MAILBOX:
      return showWarning(`Invalid type of user: ${INVALID_MAILBOX}`, error)
    case RESOURCE_NOT_FOUND:
      return showWarning(`Invalid type of user: ${RESOURCE_NOT_FOUND}`, error)
    case ITEM_NOT_FOUND:
      return showWarning(`Invalid type of user: ${ITEM_NOT_FOUND}`, error)
    default:
      return onError(error)
  }
}

export const getPhotoUrl = () => async dispatch => {
  const photoResponse = await getClient()
    .then(getFromMSGraphApi(graphApiUrl.getPhotoUrl))
    .catch(() => {})
  let result
  if (photoResponse) {
    const pictureUrl = toUrl(photoResponse)
    result = receivedUserInfo({ pictureUrl })
  }
  return result ? dispatch(result) : undefined
}

export const getFirstName = () => async dispatch => {
  const onError = newError('msGraphApi.error.getFirstName', uuid())
  const toReducer = givenName => receivedUserInfo({ givenName })
  const result = await getClient().then(getFromMSGraphApi(graphApiUrl.getInfoUser)).then(toGivenName).then(toReducer).catch(catchThis(onError))

  return result ? dispatch(result) : undefined
}
