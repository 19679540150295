import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, LinearProgress } from '@material-ui/core'
import { formatDecimalNumber } from '../../businessRules/number'

const createCustomLinearProgress = color => withStyles(theme => ({
  root: {
    borderRadius: '7px',
    height: 14
  },
  colorPrimary: {
    backgroundColor: theme.palette.backgroundelement
  },
  barColorPrimary: {
    backgroundColor: color
  }
}))(LinearProgress)

const minMax = (min, max) => ({ min, max })
const reNormalize = (oldRange, newRange) => {
  const ratio = (newRange.max - newRange.min) / (oldRange.max - oldRange.min)
  return value => ratio * (value * 100 - oldRange.max) + newRange.max
}

const big = reNormalize(minMax(10, 100), minMax(75, 100))
const medium = reNormalize(minMax(-10, 10), minMax(25, 75))
const small = reNormalize(minMax(-100, -10), minMax(0, 25))

const ValueProgressBar = ({ value }) => {
  const color = value >= 0 ? '#5FB6A3' : (value >= -0.1 ? '#F7DF00' : '#D8292E')

  const MultiColorProgressBar = createCustomLinearProgress(color)
  const normalizedValue = value > 0.1 ? big : value < -0.1 ? small : medium
  const title = `${formatDecimalNumber((value * 100), 1)} %`

  return <MultiColorProgressBar variant='determinate' value={normalizedValue(value)} title={title} />
}

ValueProgressBar.propTypes = { value: PropTypes.number.isRequired }

const RedProgressBar = createCustomLinearProgress('#D8292E')
const NullProgressBar = () => <RedProgressBar variant='determinate' value={0} title='???' />

const ProgressBar = ({ value }) => isNaN(value) ? <NullProgressBar /> : <ValueProgressBar value={value} />

ProgressBar.propTypes = { value: PropTypes.number.isRequired }

export default ProgressBar
