import multiSelect from './multiSelect'
import singleSelect from './singleSelect'

export const emptySelectIem = () => ({ key: '', value: '', formattedValue: '' })
export const newSelectItem = (key, value, formattedValue) => ({ key, value, formattedValue })
export const newSelectItemByName = (key, value) => newSelectItem(key, value, value)
export const newSelectItemByKey = key => newSelectItem(key, key, key)
export const toValue = element => element.value

export const MultiSelect = multiSelect
export const SingleSelect = singleSelect
