import {
  RequestedCommercialInfluences,
  ReceivedCommercialInfluences,
  AddingCommercialInfluence,
  AddedCommercialInfluence,
  DeletingCommercialInfluence,
  DeletedCommercialInfluence
} from '../../actions/commercialInfluence'

const initialState = {
  commercialInfluences: [],
  isLoading: false
}

const newState = (commercialInfluences, isLoading) => ({ commercialInfluences, isLoading })

export default (state = initialState, action) => {
  const { type, commercialInfluences, commercialInfluence } = action
  const assignState = newState => Object.assign({}, state, newState)

  switch (type) {
    case RequestedCommercialInfluences:
      return assignState(newState([], true))
    case ReceivedCommercialInfluences:
      return assignState(newState(commercialInfluences, false))
    case AddingCommercialInfluence:
      return assignState(newState(state.commercialInfluences, true))
    case AddedCommercialInfluence:
      return assignState(newState([...state.commercialInfluences, commercialInfluence], false))
    case DeletingCommercialInfluence:
      return assignState(newState(state.commercialInfluences, true))
    case DeletedCommercialInfluence:
      return assignState(newState([...state.commercialInfluences.filter(({ id }) => id !== commercialInfluence.id)], false))
    default:
      return state
  }
}
