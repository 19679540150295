import { sortAlphabeticaly } from '../businessRules/array'

export const findStartEndDate = rows => {
  const array = (rows ?? []).sort((a, b) => sortAlphabeticaly(a.DepartureDate, b.DepartureDate))
  const datas = [{ isHSE: true }, ...array, { isHSE: true }]
  const dates = []
  const currentDate = { startDate: 0, endDate: 0 }

  for (let index = 1; index < datas.length; index++) {
    const current = datas[index]
    const previous = datas[index - 1]

    const isCurrentDown = !current.isHSE
    const isPreviousDown = !previous.isHSE

    if (isCurrentDown && !isPreviousDown) {
      currentDate.startDate = current.DepartureDate
    } else if (!isCurrentDown && isPreviousDown) {
      currentDate.endDate = previous.DepartureDate
      dates.push({ ...currentDate })
    }
  }

  return dates
}
