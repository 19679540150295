import {
  OpenUser,
  UserDone,
  ModifyUser,
  ReceivedNewUsers
} from '../../actions/newUser'

const initialState = {
  isNewUserOpen: false,
  user: {
    email: '',
    role: '',
    regions: [],
    user_role: ''
  },
  newUsers: []
}

export default (state = initialState, action) => {
  const { type, data, users } = action
  const assignState = newState => Object.assign({}, state, newState)

  switch (type) {
    case OpenUser:
      return assignState({ isNewUserOpen: true })
    case UserDone:
      return assignState({ user: initialState.user, isNewUserOpen: false })
    case ModifyUser:
      return assignState({ user: Object.assign({}, state.user, { ...data }) })
    case ReceivedNewUsers:
      return assignState({ newUsers: [...users] })
    default:
      return state
  }
}
