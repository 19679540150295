import React from 'react'
import PropTypes from 'prop-types'

import { sumBookingRemainingLiveInfluenced, yoyOf } from '../../businessRules/statistic'

import StatBoxContent from './content'

import { connect } from 'react-redux'
import { influenceSelector } from '../../store/selectors'

const mapStateToProps = state => ({ influence: influenceSelector(state).liveInfluence.influence })

const LiveInfluenceForecast = ({ rows, influence }) =>
  <StatBoxContent value={yoyOf(sumBookingRemainingLiveInfluenced(rows, influence ?? 1))} textId='statistic.liveinfluencedforecast' />

LiveInfluenceForecast.propTypes = {
  rows: PropTypes.array.isRequired,
  influence: PropTypes.number.isRequired
}

export default connect(mapStateToProps)(LiveInfluenceForecast)
