import React from 'react'
import PropTypes from 'prop-types'

import { createNewFullPaddedButton } from '../buttons/defaultButton'
import { LoadingButton } from '../buttons/loadingButton'

import { connect } from 'react-redux'
import store from '../../store'
import { applySelectors } from '../../store/saga/filters'
import { userSettingsSelector, demandeForecastSelector, filterSelector, postdateSelector } from '../../store/selectors'

import { routes } from '../../appSetting'
import { Translate, withLocalize } from 'react-localize-redux'
import { mapSelectorsToUrlParams, filterBySelector } from '../../businessRules/dimensions'
import { withRouter } from 'react-router-dom'
import { dateRangeDateToUnix, toUnix } from '../../businessRules/date'

const FullHeightDefaultButtonLoading = LoadingButton(createNewFullPaddedButton({ t: 0.75, r: 6, b: 0.75, l: 6 }, '190px'))

const mapStateToProps = state => {
  const { ODs } = userSettingsSelector(state)
  const { isLoading } = demandeForecastSelector(state)
  const { selectors } = filterSelector(state)
  const { postdate, lowRange } = postdateSelector(state)

  return ({
    ODs,
    selectors,
    isLoading,
    postdate,
    lowRange
  })
}

function SubmitButton({ onClick, onSucess, isValid, ODs, selectors, isLoading, postdate, lowRange, history, translate }) {
  const redirect = newSelector => () => history.push(routes.forecasting.path + mapSelectorsToUrlParams(newSelector))

  const onSubmit = () => {
    if (isValid) {
      const newSelector = { ...selectors }
      const { startDate, endDate } = dateRangeDateToUnix(newSelector.DateRange)
      newSelector.DateRange = { startDate: startDate || toUnix(lowRange.startDate), endDate: endDate || toUnix(lowRange.endDate) } // if undefined or null or 0 than default value
      const { service } = ODs.filter(filterBySelector(newSelector))[0] ?? {}

      store.dispatch(applySelectors(newSelector, service, postdate)).then(redirect(newSelector)).then(onSucess).catch(e => console.error(e))
    }

    onClick()
  }

  return (
    <FullHeightDefaultButtonLoading title={translate('tableFilter.submitTitle')} isLoading={isLoading} color='primary' variant='contained' onClick={onSubmit}>
      <Translate id='tableFilter.submit' />
    </FullHeightDefaultButtonLoading>
  )
}

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onSucess: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  ODs: PropTypes.array.isRequired,
  selectors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  postdate: PropTypes.number.isRequired,
  lowRange: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withRouter(withLocalize(SubmitButton)))
