import React from 'react'
import PropTypes from 'prop-types'

import ButtonAndPopover from '../buttonAndPopOver/buttonAndPopover'
import ProfilButton from './profilButton'
import ItemLogout from './ItemLogout'
import ItemLogin from './ItemLogin'

import { connect } from 'react-redux'
import { userSettingsSelector } from '../../store/selectors'
import { isLoggedIn } from '../../authentication/authentication'

const mapStateToProps = state => {
  const { pictureUrl } = userSettingsSelector(state)
  return ({ pictureUrl })
}

const Profil = ({ pictureUrl }) =>
  <ButtonAndPopover
    Button={props => (<ProfilButton url={pictureUrl} {...props} />)}
    popoverContent={isLoggedIn() ? <ItemLogout /> : <ItemLogin />}
  />

Profil.propTypes = {
  pictureUrl: PropTypes.string.isRequired
}

export default connect(mapStateToProps)(Profil)
