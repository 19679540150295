import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'

const style = theme => ({
  tableSelectorBg: {
    backgroundColor: theme.palette.bgColor['color-neutral-95']
  }
})

export const pageHeaderTopBottomPadding = 3

const PageHeader = ({ children, classes, ...props }) => (
  <Box className={classes.tableSelectorBg} pt={pageHeaderTopBottomPadding} pb={pageHeaderTopBottomPadding} {...props}>
    {children}
  </Box>
)

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(PageHeader)
