import React from 'react'
import PropTypes from 'prop-types'

import { withStyles, FormControlLabel, Switch } from '@material-ui/core'
import { withLocalize } from 'react-localize-redux'

const CustomFormControlLabel = withStyles(_theme => ({
  root: {
    margin: 0,
    width: '100%',
    justifyContent: 'space-between'
  }
}))(FormControlLabel)

const SwitchComponent = ({ checked, onChange, labelId, titleId, translate }) =>
  <CustomFormControlLabel
    control={
      <Switch
        color='primary'
        title={translate(titleId)}
        checked={checked}
        onChange={onChange}
        value='checkedA'
      />
    }
    labelPlacement='start'
    label={translate(labelId)}
  />

SwitchComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(SwitchComponent)
